import { LoginButton } from "@telegram-auth/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL } from "../../config";
import { actions } from "../../logic/User";
import { useAppDispatch, useAppSelector } from "../../redux-store";
import { buildUrl } from "../../utils/build-url";

function LoginPage() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { refcode } = useParams();

  const { currentUser } = useAppSelector(state => state['user']);
  const { authStatus } = useAppSelector(state => state['user'])
  const { nftAvatarUrl } = useAppSelector(state => state['nft']);
  const { getUserNftAvatarStatus } = useAppSelector(state => state['nft']);
  const { updateInviterCodeStatus } = useAppSelector(state => state['user']);

  const [ inviterCode, setInviterCode ] = useState(refcode || '');
  const handveInviterCodeInput = (v: React.ChangeEvent<HTMLInputElement>) => {
    setInviterCode(v.target.value)
  }

  const handleInviteCodeSendButtonClick = async () => {
    if (currentUser) {
      await dispatch(actions.connectUserToReferral(inviterCode));
      dispatch(actions.getAuthenticatedUser());
    }
    else toast.error('Ошибка, что-то пошло не так :(')
  }
 
  useEffect(() => {
    if (authStatus !== 'initial' && authStatus !== 'loading' && getUserNftAvatarStatus !== 'initial' && getUserNftAvatarStatus !== 'loading' ) {
      if (currentUser?.inviterCode && !nftAvatarUrl) {
        navigate('/createnft');
      } else if (currentUser?.inviterCode && nftAvatarUrl) { 
        navigate('/');
        toast(`🚀 Добро пожаловать, ${currentUser.telegram}!`)
      }
    }

  }, [currentUser?.inviterCode, nftAvatarUrl, authStatus, getUserNftAvatarStatus]);

  const loginTGContainer = useRef<HTMLDivElement>(null);

  const PC_STYLE_SCREEN_WIDTH = 1920;
  const LOGIN_BUTTON_SIZE = 500;
  const TG_BUTTON_INITIAL_SIZE = 250;

  const setButtonScale = (time: number) => {
    setTimeout(() => {
        if (loginTGContainer.current && window.screen.width > 600) {
        const iframe = loginTGContainer.current.querySelector('iframe');
        const buttonsAspectRatio = LOGIN_BUTTON_SIZE / TG_BUTTON_INITIAL_SIZE;
        const currentWindowScale = PC_STYLE_SCREEN_WIDTH / window.innerWidth;
        const scaleNumForTGBtn = buttonsAspectRatio / currentWindowScale;
        if (iframe) iframe.style.transform = `scale(${scaleNumForTGBtn})`;
      }
    }, time);
  }
  setButtonScale(250);
  setButtonScale(1000);
  setButtonScale(5000);

  return(
    <section className="login">
    
    <picture className="login__pic">
      <source media="(max-width: 600px)" srcSet="img/login-mob.png" />
      <img src="img/login.png" alt="T-PLANET" />
    </picture>

    <div className="login__wrapper">

      <img src="img/tplanet.svg" className="login__logo" alt="T-PLANETS" />

      <p className="login__info">
        NFT- игра с МЛМ маркетингом, создаваемая с применением искусственного интеллекта (AI).
        <br />
        Развивайте свой остров, создавайте корабли, ищите союзников и атакуйте соседей, чтобы заработать токены и NFT. 
      </p>
  
      { !currentUser &&  (
        <>
          <div className="login-telegram-container" ref={loginTGContainer}>
            <LoginButton
            botUsername={'tplanets_games_bot'}
            buttonSize="large"
            cornerRadius={5}
            showAvatar={true}
            lang="en"         
            onAuthCallback={async (data) => {
              const baseUrl = `${BASE_URL}/auth/telegram?`;
              const url = buildUrl(data, baseUrl);
              await fetch(url, { method: 'GET', credentials: 'include' });
              dispatch(actions.getAuthenticatedUser());
            }}
            />
          </div>

          <p className="login__infoSmall">Нажав на кнопку “Log in with Telegram” Вы соглашаетесь с условиями публичной оферты и с условиями Политики обработки персональных данных.</p>
        </>
        ) 
      }

      {/* <button className="login__button-login">Login with Telegram</button> */}

      { currentUser && !currentUser.inviterCode && (
        <div className="login__promo-login-wrapper">
          <input type="text" value={inviterCode} placeholder="Введите промо-код" onInput={handveInviterCodeInput} />

          <button
          className="login__button-login"
          disabled={inviterCode || updateInviterCodeStatus === 'loading' ? false : true}
          onClick={handleInviteCodeSendButtonClick}
          >
            { updateInviterCodeStatus === 'loading' ? <span>Сохранение...</span> : <span>Отправить</span> }
          </button>

        </div>
        )
      }

    </div>

    {/* <!--соц ссылки--> */}
    <ul className="login__soc social-list social-list--vertical">
      <li className="social-list__item">
        <a href="https://t.me/tplanets_games" target="_blank" className="social-list__link" rel="noreferrer" >
          <svg width="23" height="23" className="svg-telegram" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2111 22.0812C12.9357 21.7983 11.9656 20.0753 11.0553 18.2522C10.145 16.4292 9.21586 14.6695 8.99048 14.3418C8.73358 13.9683 7.28043 13.0802 5.09531 11.9611C1.1644 9.94804 0.636181 9.51609 0.742541 8.40166C0.839722 7.38339 1.23818 7.17272 6.77169 5.21392C9.30267 4.31798 13.385 2.87325 15.8436 2.00341C18.3022 1.13357 20.5864 0.421875 20.9197 0.421875C21.6154 0.421875 22.2692 1.04771 22.2692 1.71382C22.2692 1.96456 21.4491 4.57958 20.4468 7.52498C19.4445 10.4704 18.294 13.8575 17.8902 15.0519C16.5252 19.0893 15.4815 21.7935 15.1329 22.1955C14.6549 22.7467 13.8101 22.6964 13.2111 22.0812Z"/>
          </svg>
        </a>
      </li>
      <li className="social-list__item disabled">
        <a href="##" target="_blank" className="social-list__link">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="svg-instagram" viewBox="0 0 24 24">
            <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
          </svg>
        </a>
      </li>
      <li className="social-list__item disabled">
        <a href="##" target="_blank" className="social-list__link">
          <svg width="32" height="23" className="svg-discord" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.0424 2.87504C27.0424 2.87504 23.8117 0.296824 19.997 0L19.6517 0.701032C23.1026 1.5628 24.6832 2.79704 26.338 4.31256C23.4873 2.82878 20.6736 1.43752 15.7695 1.43752C10.8655 1.43752 8.04949 2.82878 5.20139 4.31256C6.85153 2.79704 8.73569 1.42705 11.8853 0.701032L11.5424 0C7.5374 0.386648 4.49698 2.87504 4.49698 2.87504C4.49698 2.87504 0.888207 8.21146 0.269531 18.6874C3.90578 22.9666 9.42852 23 9.42852 23L10.5828 21.4301C8.62215 20.7348 6.40862 19.4915 4.49665 17.2503C6.77705 19.0093 10.221 20.8439 15.7695 20.8439C21.3181 20.8439 24.7594 19.0116 27.0424 17.2503C25.1281 19.4915 22.9146 20.7348 20.9563 21.4301L22.1105 23C22.1105 23 27.631 22.9666 31.2695 18.6874C30.6485 8.21146 27.0424 2.87504 27.0424 2.87504ZM11.1899 15.8121C9.82706 15.8121 8.72411 14.5258 8.72411 12.937C8.72411 11.3496 9.82739 10.0623 11.1899 10.0623C12.5527 10.0623 13.656 11.3496 13.656 12.937C13.656 14.5262 12.5527 15.8121 11.1899 15.8121ZM20.3492 15.8121C18.9864 15.8121 17.8831 14.5258 17.8831 12.937C17.8831 11.3496 18.9864 10.0623 20.3492 10.0623C21.7117 10.0623 22.815 11.3496 22.815 12.937C22.815 14.5262 21.7093 15.8121 20.3492 15.8121Z"/>
          </svg>								
        </a>
      </li>
      <li className="social-list__item disabled">
        <a href="##" target="_blank" className="social-list__link">
          <svg width="29" height="23" className="svg-twitter" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.2695 2.72285C27.2396 3.18445 26.1318 3.49666 24.9699 3.63609C26.1562 2.91771 27.0666 1.78102 27.4962 0.424362C26.3862 1.08992 25.1563 1.57274 23.8483 1.83299C22.8005 0.704962 21.3074 0 19.6542 0C16.4823 0 13.9097 2.6003 13.9097 5.8064C13.9097 6.26151 13.9606 6.70449 14.0592 7.12972C9.28498 6.88766 5.05187 4.57618 2.21837 1.0622C1.72398 1.91959 1.44037 2.91771 1.44037 3.98164C1.44037 5.99563 2.45528 7.77362 3.99584 8.81461C3.05462 8.78473 2.16824 8.52362 1.39411 8.08757C1.39368 8.11225 1.39368 8.13693 1.39368 8.16118C1.39368 10.9745 3.37464 13.3211 6.00208 13.8541C5.52055 13.9875 5.01203 14.0581 4.48894 14.0581C4.11793 14.0581 3.7585 14.0221 3.40806 13.9546C4.13893 16.2609 6.25998 17.9397 8.77432 17.9869C6.80792 19.5445 4.33128 20.4724 1.63916 20.4724C1.17647 20.4724 0.718076 20.4452 0.269531 20.391C2.81043 22.0391 5.83072 23 9.07463 23C19.6409 23 25.4193 14.1525 25.4193 6.47889C25.4193 6.2273 25.4137 5.97658 25.4026 5.72802C26.5259 4.90961 27.4997 3.88725 28.2695 2.72285Z"/>
          </svg>								
        </a>
      </li>
    </ul>

    {/* <!--права--> */}
    <div className="login__rights">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.25 4.25H8.75C9.14782 4.25 9.52936 4.40804 9.81066 4.68934C10.092 4.97064 10.25 5.35218 10.25 5.75V6.5H8.75V5.75H7.25V10.25H8.75V9.5H10.25V10.25C10.25 10.6478 10.092 11.0294 9.81066 11.3107C9.52936 11.592 9.14782 11.75 8.75 11.75H7.25C6.85218 11.75 6.47064 11.592 6.18934 11.3107C5.90804 11.0294 5.75 10.6478 5.75 10.25V5.75C5.75 5.35218 5.90804 4.97064 6.18934 4.68934C6.47064 4.40804 6.85218 4.25 7.25 4.25ZM8 0.5C8.98491 0.5 9.96018 0.693993 10.8701 1.0709C11.7801 1.44781 12.6069 2.00026 13.3033 2.6967C13.9997 3.39314 14.5522 4.21993 14.9291 5.12987C15.306 6.03982 15.5 7.01509 15.5 8C15.5 9.98912 14.7098 11.8968 13.3033 13.3033C11.8968 14.7098 9.98912 15.5 8 15.5C7.01509 15.5 6.03982 15.306 5.12987 14.9291C4.21993 14.5522 3.39314 13.9997 2.6967 13.3033C1.29018 11.8968 0.5 9.98912 0.5 8C0.5 6.01088 1.29018 4.10322 2.6967 2.6967C4.10322 1.29018 6.01088 0.5 8 0.5ZM8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8C2 9.5913 2.63214 11.1174 3.75736 12.2426C4.88258 13.3679 6.4087 14 8 14C9.5913 14 11.1174 13.3679 12.2426 12.2426C13.3679 11.1174 14 9.5913 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2Z" fill="#38C7D0"/>
      </svg>
      <span>2023 All rights reserved.</span>        
    </div>

  </section>
  )
}

export default LoginPage;