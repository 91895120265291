import { useEffect, useRef, useState } from "react";
import { actions as nftActions } from "../../../logic/Nft";
import { actions as mainActions } from "../../../logic/Main";
import { actions as userActions } from "../../../logic/User";
import { useAppDispatch, useAppSelector } from "../../../redux-store";
import SimpleLoader from "../components/SimpleLoader";
import { Spaceship } from "../../../logic/types/response/GetUserSpaceships";
import { toast } from "react-toastify";
import { actions as walletActions } from "../../../logic/Wallet";
import { BASE_URL } from "../../../config";
import setDocumentBodyFixed from "../../../utils/set-document-body-fixed";
import setDocumentBodyInitial from "../../../utils/set-document-body-initial";

function StoreScreen() {

  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector(state => state['user']);
  const userRace = currentUser?.race?.name;

  useEffect(() => {
    if (currentUser && !currentUser.race) {
      dispatch(userActions.getAuthenticatedUser())
    }
  }, [currentUser]);

  const { userBalance } = useAppSelector(state => state['wallet']);
  const { spaceshipsForShop } = useAppSelector(state => state['nft']);
  const { getSpaceshipsForShopStatus } = useAppSelector(state => state['nft']);
  const { buySpaceshipStatus } = useAppSelector(state => state['nft']);
  const { spaseshipsForMatriсes } = useAppSelector(state => state['nft']);
  const { getSpaceshipsForMatriсesStatus } = useAppSelector(state => state['nft']);

  /* запрос кораблей для покупки */
  useEffect(() => {
    if (spaceshipsForShop.length < 1) {
      dispatch(nftActions.getSpaceshipsForShop())
    }
  }, [spaceshipsForShop]);

  useEffect(() => {
    if (currentUser) dispatch(nftActions.getSpaceshipsForMatriсes());
  }, [currentUser]);

  /* данные для покупки корабля */
  const [isBuyProcessLoading, setIsBuyProcessLoading] = useState(false);
  const [shipsBuyPending, setShipsBuyPending] = useState<{id: string, status: string}[]>([]);
  const [shipsBuysCount, setShipsBuysCount] = useState(0);
  const [buyType, setbuyType] = useState<'one' | 'many' | 'none'>('none');
  const MAX_TRY_NUMBER = 12;
  const TIME_STEP = 5000;

  /* открытие попапа поздравления с покупкой корабля */
  const [lastPurchasedSpaceship, setLastPurchasedSpaceship] = useState<{img: string, name: string} | undefined>(undefined);
  const [isBuyPopupOpened, setIsBuyPopupOpened] = useState(false);

  useEffect(() => {
    if (isBuyPopupOpened) setDocumentBodyFixed();
    else setDocumentBodyInitial();
  }, [isBuyPopupOpened]);

  /* закрытие попапа поздравления с покупкой корабля */
  const closeBuyPopup = () => {
    setIsBuyPopupOpened(false);
    dispatch(nftActions.resetBuySpaceshipStatus());
  }

  /* закрытие попапа поздравления с покупкой корабля */
  
  /* клик-покупка корабля */
  const handleBuySpaceshipsClick = async (ships: Spaceship[], isPopupCongrat: boolean) => {
    if (isPopupCongrat) {
      setbuyType('one');
    }
    else setbuyType('many');
    const types = ships.map((item) => item.type);
    try {
      const buyingShipsId: any = await dispatch(nftActions.buySpaceships(types));
      const arrForState = buyingShipsId.payload?.userSpaceshipIds?.map((item: string) => {return {id: item, status: 'pending'}})
      setShipsBuyPending(arrForState);
    } catch (error: any) {
      toast.error(error);
    }
  }

  /* сброс покупки в изначальное состояние */
  const resetShipsBuy = () => {
    setShipsBuyPending([]);
    setShipsBuysCount(0);
    setbuyType('none');
    setIsBuyProcessLoading(false);
  }

  /* логика покупки корабля */
  useEffect(() => {
    if (shipsBuysCount >= MAX_TRY_NUMBER) { // превышение попыток покупки
      resetShipsBuy();
      toast.error('Время ожидания покупки превышено. Попробуйте позже');
    }
    if (shipsBuyPending.length > 0 && shipsBuysCount < MAX_TRY_NUMBER) { //условие входа в логику
      setIsBuyProcessLoading(true); //включаем лоадер
      const inBuyProcess = shipsBuyPending.filter((ship) => ship.status === 'pending'); //оставляем только pending корабли
      if (inBuyProcess.length > 0) { //если есть корабли со статусом pending
        const timer = setTimeout(() => {
          const checkSpaceship = async () => {
            try {
              const currentUserSpaceships: any = await dispatch(nftActions.getSpaceshipsForMatriсes());//запрашиваем корабли игрока
              let currentShipsBuyPending = [...inBuyProcess];//копируем массив для будущих изменений

              inBuyProcess.forEach((ship, index) => { //ищем покупаемый корабль среди кораблей юзера
                const currentShip = currentUserSpaceships.payload.find((uShip: any) => uShip.id === ship.id); // ищем текущий корабль итерации в пришедших по id
                if (currentShip) {
                  currentShipsBuyPending[index].status = 'fulfilled';//если найден - меняем статус на fulfilled
                  if (buyType === 'many') toast.success(`${currentShip.spaceship.type} успешно приобретён!`);//тост с поздравлением покуки для многих кораблей
                  if (buyType === 'one') setLastPurchasedSpaceship({img: `${currentShip.nft?.url}`, name: currentShip.spaceship.type})//сохранение в стейт данных для поздравлений покупки одного корабля 
                } 
              });

              setShipsBuyPending(currentShipsBuyPending);//обновляем статусы кораблей
            } catch (error: any) {
              toast.error(error);
              resetShipsBuy();
            }
          }
          checkSpaceship(); //запускаем асинхронную функцию
          setShipsBuysCount(shipsBuysCount + 1); //прибавляем попытки
      }, TIME_STEP);
      return () => clearTimeout(timer);
      } 
      
      else { //если все корабли fulfilled - всё купилось
        if (buyType === 'many') toast.success('Все покупки успешно завершены'); //поздр. для нескольких кораблей
        if (buyType === 'one') setIsBuyPopupOpened(true); //поздр. для одного корабля
        resetShipsBuy(); //покупку ставим в исходное состояние
        dispatch(walletActions.getUserBalance()); //запрашиваем баланс
      }
      
    } else {
      setShipsBuysCount(0); //обнуляем попытки
    }
  }, [shipsBuyPending]);

  /* переход на экран Гильдии */
  const goToGuildScreen = () => {
    closeBuyPopup();
    dispatch(mainActions.setCurrentScreen('guild'));
  }

  /* массив доступных кораблей для покупки */
  const availableToBuySpaceships = spaceshipsForShop.filter((ship) => !spaseshipsForMatriсes.some((someShip) => ship.type === someShip?.spaceship?.type ));
 
  /* сумма цен доступных кораблей */
  const accPrice = availableToBuySpaceships.reduce((prev, current) => prev + current.price, 0);

  /* отсортированные корабли по цене */
  let sortedSpaceshipsForShop: Spaceship[] = [];
  if (spaceshipsForShop.length > 0) sortedSpaceshipsForShop = [...spaceshipsForShop].sort((a,b) => a.price - b.price);

  /* holocard */
  const holocardTimer = useRef<NodeJS.Timeout | undefined>(undefined);

  const handleHolocardMousemove = (evt: any) => {
      // normalise touch/mouse
      let pos = [evt.nativeEvent.offsetX, evt.nativeEvent.offsetY];
      evt.preventDefault();
      if ( evt.nativeEvent.type === "touchmove" ) {
        pos = [ evt.nativeEvent.touches[0].clientX, evt.nativeEvent.touches[0].clientY ];
      }
      let $card = evt.currentTarget;
      let $cardBefore = $card.querySelector('.holocardBefore');
      let $cardAfter = $card.querySelector('.holocardAfter');
      // math for mouse position
      let l = pos[0];
      let t = pos[1];
      let h = $card.getBoundingClientRect().height;
      let w = $card.getBoundingClientRect().width;
      let px = Math.abs(Math.floor(100 / w * l)-100);
      let py = Math.abs(Math.floor(100 / h * t)-100);
      let pa = (50-px)+(50-py);
      // math for gradient / background positions
      let lp = (50+(px - 50)/1.5);
      let tp = (50+(py - 50)/1.5);
      let px_spark = (50+(px - 50)/7);
      let py_spark = (50+(py - 50)/7);
      let p_opc = 20+(Math.abs(pa)*1.5);
      let ty = ((tp - 50)/2) * -1;
      let tx = ((lp - 50)/1.5) * .5;
      // css to apply for active card
      let grad_pos = `background-position: ${lp}% ${tp}%;`;
      let sprk_pos = `background-position: ${px_spark}% ${py_spark}%;`;
      let opc = `opacity: ${p_opc/100};`;
      let tf = `transform: rotateX(${ty}deg) rotateY(${tx}deg)`;
      // need to use a <style> tag for psuedo elements
      let bs = `${grad_pos}`;
      let as = `${sprk_pos} ${opc}`;
      // set / apply css class and style
      $card.classList.remove("active");
      $card.classList.remove("animated");
      $card.setAttribute( "style", tf );
      $cardBefore.setAttribute( "style", bs );
      $cardAfter.setAttribute( "style", as );
      if ( evt.type === "touchmove" ) {
        return false; 
      }
      clearTimeout(holocardTimer.current);
    }
  
    const handleHolocardMouseout = (evt: any) => {
      var $card = evt.currentTarget;
      var $cardBefore = $card.querySelector('.holocardBefore');
      var $cardAfter = $card.querySelector('.holocardAfter');
      $cardBefore.removeAttribute("style");
      $cardAfter.removeAttribute("style");
      $card.removeAttribute("style");
      holocardTimer.current = setTimeout(function() {
        $card.classList.add("animated");
      },2500);
    }

  return(
    <section className="store section-bg">

    <h1>STORE:</h1>

    { sortedSpaceshipsForShop.length > 0 && (
      <>
        <ul className="store__list">

          { sortedSpaceshipsForShop.map((ship: Spaceship) => (
            <li className={`store__item ${spaseshipsForMatriсes.some((shipForMatricies) => shipForMatricies.spaceship.type === ship.type) ? 'gray' : ''}`} key={ship.id}>
              <div className="store__item-wrapper">
                {/* заголовок корабля */}
                <h2 className="store__item-title">
                  <img src="img/ship1.svg" alt="ship" />
                  { ship.type }
                </h2>

                {/* вывод описания нужной расы */}
                {userRace === ship.infoHuman.race.name && (<p className="custom-scroll">{ ship.infoHuman.description }</p>)}
                {userRace === ship.infoCyborg.race.name && (<p className="custom-scroll">{ ship.infoCyborg.description }</p>)}
                {userRace === ship.infoLizard.race.name && (<p className="custom-scroll">{ ship.infoLizard.description }</p>)}

                {/* кнопка купить корабль */}
                <button 
                // onClick={() => { handleBuySpaceshipClick(ship, true) }}
                onClick={() => { handleBuySpaceshipsClick([ship], true) }}
                disabled={isBuyProcessLoading || spaseshipsForMatriсes.some((shipForMatricies) => shipForMatricies.spaceship.type === ship.type)}
                >
                  { spaseshipsForMatriсes.some((shipForMatricies) => shipForMatricies.spaceship.type === ship.type) ? 'УЖЕ ПРИОБРЕТЁН' : 'КУПИТЬ' }
                </button>

                {/* цена */}
                <span className="store__item-price">price: { ship.price } <img className="store__item-price-icon" src="img/ton.svg" alt="" /></span>
              </div>

              {/* вывод картинки нужной расы */}
              {userRace === ship.infoHuman.race.name &&
                (<div
                className={`store__item-holocard holocard 
                ${ship.type === 'Истребитель' ? 'uncommon' : ''}
                ${ship.type === 'Штурмовой' ? 'rare' : ''}
                ${ship.type === 'Штурмовой' ? 'rare' : ''}
                ${ship.type === 'Крейсер' ? 'epic' : ''}
                ${ship.type === 'Дредноут' ? 'legendary' : ''}
                ${ship.type === 'Разрушитель' ? 'artifact' : ''}
                `}
                onMouseMove={(evt) => {handleHolocardMousemove(evt)}} onMouseOut={(evt) => {handleHolocardMouseout(evt)}}
                >
                  <div className="holocard__bg"></div>
                  <div className="holocardBefore"></div>
                  <img src={`${BASE_URL}${ship.infoHuman.image.url}`} alt={ship.type} />           
                  <div className="holocardAfter"></div>
                </div>)
              }
              {userRace === ship.infoCyborg.race.name &&
                (<div
                  className={`store__item-holocard holocard 
                  ${ship.type === 'Истребитель' ? 'uncommon' : ''}
                  ${ship.type === 'Штурмовой' ? 'rare' : ''}
                  ${ship.type === 'Штурмовой' ? 'rare' : ''}
                  ${ship.type === 'Крейсер' ? 'epic' : ''}
                  ${ship.type === 'Дредноут' ? 'legendary' : ''}
                  ${ship.type === 'Разрушитель' ? 'artifact' : ''}
                  `}
                  onMouseMove={(evt) => {handleHolocardMousemove(evt)}} onMouseOut={(evt) => {handleHolocardMouseout(evt)}}
                  >
                    <div className="holocard__bg"></div>
                    <div className="holocardBefore"></div>
                    <img src={`${BASE_URL}${ship.infoCyborg.image.url}`} alt={ship.type} />           
                    <div className="holocardAfter"></div>
                  </div>)
              }
              {userRace === ship.infoLizard.race.name &&
                (<div
                  className={`store__item-holocard holocard 
                  ${ship.type === 'Истребитель' ? 'uncommon' : ''}
                  ${ship.type === 'Штурмовой' ? 'rare' : ''}
                  ${ship.type === 'Штурмовой' ? 'rare' : ''}
                  ${ship.type === 'Крейсер' ? 'epic' : ''}
                  ${ship.type === 'Дредноут' ? 'legendary' : ''}
                  ${ship.type === 'Разрушитель' ? 'artifact' : ''}
                  `}
                  onMouseMove={(evt) => {handleHolocardMousemove(evt)}} onMouseOut={(evt) => {handleHolocardMouseout(evt)}}
                  >
                    <div className="holocard__bg"></div>
                    <div className="holocardBefore"></div>
                    <img src={`${BASE_URL}${ship.infoLizard.image.url}`} alt={ship.type} />           
                    <div className="holocardAfter"></div>
                  </div>)
              }
            </li>
            ))
          }
        </ul>

        {/* кнопка купить все корабли */}
        {availableToBuySpaceships.length > 0 && (
          <button
          // onClick={handleBuyAllShipsClick}
          onClick={() => { handleBuySpaceshipsClick(availableToBuySpaceships, false) }}
          disabled={+userBalance < accPrice}
          className='store__buyAll'
          >
            {accPrice && <span className="store__item-price">price: { accPrice } <img className="store__item-price-icon" src="img/ton.svg" alt="" /></span> }
            Купить все корабли
          </button>
        )}
        {(+userBalance < accPrice) && <p className="store__noTon">недостаточно TON</p>}
      </>
    ) }

    {/* loader */}
    { (buySpaceshipStatus === 'loading' || getSpaceshipsForShopStatus === 'loading' || isBuyProcessLoading || getSpaceshipsForMatriсesStatus === 'loading') && (
      <SimpleLoader addClass={'store__loader'} />
    ) }

    {/* показ ошибки загрузки кораблей для шопа */}
    { sortedSpaceshipsForShop.length === 0 && getSpaceshipsForShopStatus === 'failed' && (
      <div className="store__failed">
        <p>Не удалось установить связь с депортаментом продаж космических кораблей, пожалуйста обновите страницу.</p>
        <button className="button-common" onClick={() => window.location.reload()}>обновить</button>
      </div>
    ) }

    {/* поздравление с получением Nft корабля */}
    { isBuyPopupOpened && (
    <div className="store__congrat-popup-container">
      <div className="store__congrat-popup congrat-popup congrat-popup--store">
        <div
        className={`congrat-popupCard holocard 
        ${lastPurchasedSpaceship?.name === 'Истребитель' ? 'uncommon' : ''}
        ${lastPurchasedSpaceship?.name === 'Штурмовой' ? 'rare' : ''}
        ${lastPurchasedSpaceship?.name === 'Штурмовой' ? 'rare' : ''}
        ${lastPurchasedSpaceship?.name === 'Крейсер' ? 'epic' : ''}
        ${lastPurchasedSpaceship?.name === 'Дредноут' ? 'legendary' : ''}
        ${lastPurchasedSpaceship?.name === 'Разрушитель' ? 'artifact' : ''}
        `}
        onMouseMove={(evt) => {handleHolocardMousemove(evt)}} onMouseOut={(evt) => {handleHolocardMouseout(evt)}}
        >
          <div className="holocard__bg"></div>
          <div className="holocardBefore"></div>
          <img src={`${lastPurchasedSpaceship?.img}`} alt={lastPurchasedSpaceship?.name} />           
          <div className="holocardAfter"></div>
        </div>
        <div className="congrat-popup__text">
          <p><span className="congrat-popup__title-font">{lastPurchasedSpaceship?.name}</span> успешно приобретён!</p>
          <button className="button-common button-common--100" onClick={goToGuildScreen}><span>Перейти в Гильдию</span></button>
          <button className="button-common button-common--100" onClick={closeBuyPopup}><span>Ok</span></button>
        </div>
      </div>
    </div>
    ) }

  </section>
  )
}

export default StoreScreen;