import { useAppDispatch, useAppSelector } from "../../redux-store";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

import Header from "./navigation/Header";
import MobileHeader from "./navigation/MobileHeader";
import Navigation from "./navigation/Navigation";
import InventoryScreen from "./screens/InventoryScreen";
import GuildScreen from "./screens/GuildScreen";
import StoreScreen from "./screens/StoreScreen";
import SupportScreen from "./screens/HomeScreen";
import WalletScreen from "./screens/WalletScreen";
import Game from "./screens/GameScreen";
import { actions } from "../../logic/Main";
import DataScreen from "./screens/DataScreen";
import TeamScreen from "./screens/TeamScreen";

function PersonalPage() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { currentScreen } = useAppSelector(state => state['personal']);
  const { currentUser } = useAppSelector(state => state['user']);
  const { authStatus } = useAppSelector(state => state['user']);
  
  const [isMobileHeader, setIsMobileHeader] = useState(true);

  useEffect(() => {
    if (authStatus !== 'initial' && authStatus !== 'loading') {
      if (!currentUser || (currentUser && !currentUser.inviterCode)) navigate('/login');
    }
  }, [currentUser, authStatus])

  useEffect(() => {
    const lastCurrentScreen: any  = localStorage.getItem('lastCurrentScreen');
    if (lastCurrentScreen) dispatch(actions.setCurrentScreen(lastCurrentScreen));
  }, []);

  // 3D stars
  useEffect(() => {
    const threeJs = document.createElement("script")
    const appJs = document.createElement("script");

    threeJs.id = "threeJs"
    appJs.id = "appJs"

    threeJs.addEventListener('load', () => {
      const threeJs = document.getElementById("threeJs")
      if (threeJs) document.body.appendChild(appJs);
    })

    threeJs.src = "js/three.min.js";
    appJs.src = "js/script.js";

    document.body.appendChild(threeJs)

    return () => {
      const threeJs = document.getElementById("threeJs")
      const appJs = document.getElementById("appJs")
      if (appJs) document.body.removeChild(appJs)
      if (threeJs) document.body.removeChild(threeJs)
    }
  }, [])

  return(
    <div className="mainwrapper">

      {/* <!--ЛЕВАЯ КОЛОНКА НАВИГАЦИИ--> */}
      {currentScreen !== 'game' && <Navigation />}

      {/* <!--ОСНОВНОЙ ЭКРАН СПРАВА--> */}
      <div className="main">

        {/* <!--Шапка--> */}
        {currentScreen !== 'game' && <Header /> }

        {/* <!--Шапка для мобилки--> */}
        {currentScreen !== 'game' && isMobileHeader && <MobileHeader />}

        {/* <!--Контент--> */}

        <div className={`main__content custom-scroll ${currentScreen === 'game' ? 'nopadding' : ''}`}>

          <canvas className='webgl active'></canvas>

          {currentScreen === 'guild' && <GuildScreen />}
          {currentScreen === 'inventory' && <InventoryScreen setIsMobileHeader={setIsMobileHeader} />}
          {currentScreen === 'store' && <StoreScreen />}
          {currentScreen === 'home' && <SupportScreen />}
          {currentScreen === 'wallet' && <WalletScreen />}
          {currentScreen === 'game' && <Game />}
          {currentScreen === 'data' && <DataScreen />}
          {currentScreen === 'team' && <TeamScreen />}

        </div>

      </div>
    </div>
  )
}

export default PersonalPage;