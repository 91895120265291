import { GqlBody } from "../logic/types/request/RequestTypes";

import { GetAuthenticatedUserResponse } from "../logic/types/response/GetAuthenticatedUserResponse";
import { LogoutUserResponse } from "../logic/types/response/LogoutUserResponse";
import { MiningInfo, MiningInfoData } from "../logic/types/response/MiningInfo";
import { MiningRate, MiningRatesData } from "../logic/types/response/MiningRatesData";
import { TokenBalanceData } from "../logic/types/response/TokenBalanceData";
import { UpdateUserPromoResponse } from "../logic/types/response/UpdateUserPromoResponse";

import { User } from "../logic/types/types";

import { ApiKeystone } from "./api_keystone";
import { Gql } from "./api_queries";

export class UserRepository {
  private static keystone = ApiKeystone;

  // logout
  static async logoutUser(): Promise<boolean> {
    const query = Gql.logoutUser()
    const body: GqlBody = { operationName: "LogoutUser", variables: {}, query: query, }
    const res: LogoutUserResponse = await this.keystone.queryGraphql(body)
    return res.data.endSession
  }

  // get authenticated user
  static async getAuthenticatedUser(): Promise<User | null> {
    const query = Gql.getAuthenticatedUser()
    const body: GqlBody = { operationName: "GetAuthenticatedUser", variables: {}, query: query, }
    const res: GetAuthenticatedUserResponse = await this.keystone.queryGraphql(body)
    return res.data.authenticatedItem
  }

  // get update user inviter code
  static async connectUserToReferral(inviterCode: string): Promise<any> {
    const query = Gql.connectUserToReferral()
    const body: GqlBody = { operationName: "connectUserToReferral", query: query, variables: { inviterCode } }
    const res: any = await this.keystone.queryGraphql(body)
    return res.data.connectUserToReferral.isConnected;
  }

  // get update user promo
  static async updateUserPromo({ promo }: { promo: string }): Promise<boolean> {
    const query = Gql.updateUserPromo()
    const body: GqlBody = { operationName: "UpdateUserPromo", query: query, variables: { promocode: promo } }
    const res: UpdateUserPromoResponse = await this.keystone.queryGraphql(body)
    return res.data.setPromo.isSuccess || false
  }

  // get update user promo
  static async isSubscribedToChannel(): Promise<boolean> {
    const query = Gql.isSubscribedToChannel()
    const body: GqlBody = { operationName: "IsSubscribedToChannel", query: query }
    const res: {data: {isSubscribedToChannel: boolean}} = await this.keystone.queryGraphql(body)
    return res.data.isSubscribedToChannel || false
  }

  // get mining rates
  static async getMiningRates(): Promise<MiningRate[]> {
    const query = Gql.getMiningRates()
    const body: GqlBody = { operationName: "MiningRates", query: query, variables: { "orderBy": [{"createdAt": "desc"}] }};
    const res: MiningRatesData = await this.keystone.queryGraphql(body)
    return res.data.miningRates ?? [];
  }

  // get mining INFO
  static async getMiningInfo(): Promise<MiningInfo> {
    const query = Gql.getMiningInfo()
    const body: GqlBody = { operationName: "GetMiningInfo", query: query};
    const res: MiningInfoData = await this.keystone.queryGraphql(body)
    return res.data.getMiningInfo;
  }

  // get token balance
  static async getTokenBalance(): Promise<string> {
    const query = Gql.getTokenBalance()
    const body: GqlBody = { operationName: "TokenBalance", query: query }
    const res: TokenBalanceData = await this.keystone.queryGraphql(body)
    return res.data.getTokenBalance ?? '0';
  }
}