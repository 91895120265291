type WalletModalSuccessType = {
  currentWalletWindow: "none" | "deposit-qr" | "transfer-adress" | "message" | "ton" | "message-transfer",
  windowForOpenModal: string,
  setCurrentWalletWindow: (value: React.SetStateAction<"none" | "deposit-qr" | "transfer-adress" | "message" | "ton" | "message-transfer">) => void,
  title: string,
  message: string
}

function WalletModalSuccess({currentWalletWindow, windowForOpenModal, setCurrentWalletWindow, title, message} : WalletModalSuccessType) {
  return ( 
    <div className={`wallet__mdeposit ${currentWalletWindow === windowForOpenModal ? 'active' : ''} `}>

      <div className="wallet__mdeposit-top">
        <h2>{title}</h2>
        <button className="button-close" onClick={() => {setCurrentWalletWindow('none')}}></button>
      </div>

      <div className="wallet__mdeposit-wrapper">
        <h2>{message}</h2>
        <button
        className="button-common"
        onClick={() => {setCurrentWalletWindow('none')}}
        >
          Отлично!
        </button>
      </div>
    </div>
  );
}

export default WalletModalSuccess;