import { useEffect, useState } from "react";
import { actions as walletActions } from "../../../logic/Wallet";
import { useAppDispatch, useAppSelector } from "../../../redux-store";
import WalletAvatar from "../components/WalletAvatar";
import WalletRewards from "../components/WalletRewards";
import WalletTransactions from "../components/WalletTransactions";
import WalletQrCodeModal from "../modals/WalletQrCodeModal";
import WalletVideoModal from "../modals/WalletVideoModal";
import WalletAddressModal from "../modals/WalletAddressModal";
import WalletModalSuccess from "../modals/WalletModalSuccess";
import WalletThreeButtons from "../components/WalletThreeButtons";
import WalletUserBalanceBlock from "../components/WalletUserBalanceBlock";
import WalletAvatarControls from "../components/WalletAvatarControls";

function WalletScreen() {

  const dispatch = useAppDispatch();

  const { userBalance } = useAppSelector(state => state['wallet']);
  const { userTransactions } = useAppSelector(state => state['wallet']);
  const { createWithdrawRequestStatus } = useAppSelector(state => state['wallet']);
  const { getDepositAddressStatus } = useAppSelector(state => state['wallet']);

  const { currentUser } = useAppSelector(state => state['user'])

  /* получение баланса и транзакций */
  useEffect(() => {
    if (currentUser) {
      dispatch(walletActions.getUserBalance());
      dispatch(walletActions.getUserTransactions());
    };
  }, [currentUser])

  const [currentWalletWindow, setCurrentWalletWindow] = useState<'none' | 'deposit-qr' | 'transfer-adress' | 'message' | 'ton' | 'message-transfer'>('none');

  /* переход на экран поздравления transfer */
  useEffect(() => {
    if (createWithdrawRequestStatus === 'success') {
      setCurrentWalletWindow('message-transfer');
      dispatch(walletActions.getUserTransactions())
      dispatch(walletActions.resetCreateWithdrawRequestStatus());
    }
  }, [createWithdrawRequestStatus]);

  /* обработчик клика по депозиту */
  const handleDepositClick = () => {
    setCurrentWalletWindow('deposit-qr');
    dispatch(walletActions.getDepositAddress());
  }

  /* массив наград */
  const rewards = userTransactions.filter((trans) => trans?.operation === 'bonus');

  /* video modal */
  const [isVideoModal, setIsVideoModal] = useState(false);
  const openVideoModal = () => setIsVideoModal(true);
  const closeVideoModal = () => setIsVideoModal(false);
  

  return (
    <section className="wallet section-bg">


      <div className="wallet__left-grid">

        {/* <!--Аватар--> */}
        <WalletAvatar currentUser={currentUser} />

        {/* кнопки управления аватаром */}
        <WalletAvatarControls />
  
      </div>

      <div className="wallet__right-grid">
        {/* <!--Баланс--> */}
        <div className="wallet__balance">
  
          <h2 className="wallet__bottom-title">Баланс:</h2>
  
          {/* 3 кнопки управления */}
          <WalletThreeButtons handleDepositClick={handleDepositClick} openVideoModal={openVideoModal} setCurrentWalletWindow={setCurrentWalletWindow} />
  
          {/* блок баланса юзера */}
          <WalletUserBalanceBlock userBalance={userBalance} />
  
        </div>

        {/* <!--Награды--> */}
        <WalletRewards rewards={rewards}/>

        {/* <!--Транзакции--> */}
        <WalletTransactions userTransactions={userTransactions} />

      </div>

      {/* <!--Модалка QRкода--> */}
      <WalletQrCodeModal
        currentWalletWindow={currentWalletWindow}
        setCurrentWalletWindow={setCurrentWalletWindow}
        getDepositAddressStatus={getDepositAddressStatus}
      />

      {/* <!--Модалка адрес кошелька--> */}
      <WalletAddressModal
        currentWalletWindow={currentWalletWindow}
        setCurrentWalletWindow={setCurrentWalletWindow}
        createWithdrawRequestStatus={createWithdrawRequestStatus}
        userBalance={userBalance}
      />

      {/* <!--Модалка успешно!--> */}
      <WalletModalSuccess
        currentWalletWindow={currentWalletWindow}
        setCurrentWalletWindow={setCurrentWalletWindow}
        windowForOpenModal={'message'}
        title={'Вывод:'}
        message={'Перевод выполнен успешно!'}
      />

      {/* <!--Модалка успешно transfer!--> */}
      <WalletModalSuccess
        currentWalletWindow={currentWalletWindow}
        setCurrentWalletWindow={setCurrentWalletWindow}
        windowForOpenModal={'message-transfer'}
        title={'Transfer:'}
        message={'Заявка на transfer подана успешно!'}
      />

      {/* модалка с видео-обучением */}
      <WalletVideoModal closeVideoModal={closeVideoModal} isVideoModal={isVideoModal} />

    </section>
  )
}

export default WalletScreen;