import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { actions as nftActions } from "../../../logic/Nft";
import { SpaceshipsInMatrix } from "../../../logic/types/response/GetSpaceshipMatrix";
import { UserSpaceships } from "../../../logic/types/response/GetUserSpaceships";
import { actions as userActions } from "../../../logic/User";
import { useAppDispatch, useAppSelector } from "../../../redux-store";
import setDocumentBodyFixed from "../../../utils/set-document-body-fixed";
import setDocumentBodyInitial from "../../../utils/set-document-body-initial";
import InventorySlot from "../components/InventorySlot";
import SimpleLoader from "../components/SimpleLoader";

type InventoryScreenType = {
  setIsMobileHeader: Dispatch<SetStateAction<boolean>>
}

function InventoryScreen({setIsMobileHeader}: InventoryScreenType) {
  
  const dispatch = useAppDispatch();

  const { userSpaceships } = useAppSelector(state => state['nft']);
  const { nftAvatarUrl } = useAppSelector(state => state['nft']);
  const { getUserSpaseshipsStatus } = useAppSelector(state => state['nft']);
  const { races } = useAppSelector(state => state['nft']);
  const { currentUser } = useAppSelector(state => state['user']);
  const { isPremAvatar } = useAppSelector(state => state['nft']);

  const userRace = currentUser?.race?.name;

  /* если есть юзер но в нём нет расы, обновляем юзера */
  useEffect(() => {
    if (currentUser && !currentUser.race) {
      dispatch(userActions.getAuthenticatedUser())
    }
  }, [currentUser]);

  /* получение рас */
  useEffect(() => {
    if (races.length < 1) {
      dispatch(nftActions.getRaces());
    }
  }, [races])

  /* запрос кораблей для инвентаря */
  useEffect(() => {
    dispatch(nftActions.getUserSpaseships());
  }, []);

  /* create array filled numbers, for add empty inventory slots */
  const INVENTORY_MIN_SLOTS = 15;
  let emptySlots: number[] = [];
  let emptyMobileSlots: number[] = [];

  if (userSpaceships.length < INVENTORY_MIN_SLOTS) {
    emptySlots = [...Array(INVENTORY_MIN_SLOTS - userSpaceships.length)].map((i, index) => index);
    emptyMobileSlots = [...emptySlots];
  } 
  else if (userSpaceships.length % 5 !== 0) {
    emptySlots = [...Array(5 - userSpaceships.length % 5)].map((i, index) => index);
    emptyMobileSlots = [...Array(3 - userSpaceships.length % 3)].map((i, index) => index);
  }
  else if (userSpaceships.length % 5 === 0) {
    emptyMobileSlots = [...Array(3 - userSpaceships.length % 3)].map((i, index) => index);
  }

  /* попап дополнительноый информации о корабле */
  const [isInfoPopupOpened, setIsInfoPopupOpened] = useState(false);
  const [infoPopupData, setInfoPopupData] = useState<UserSpaceships | null>(null);

  const { lastLoadedMatrix } = useAppSelector(state => state['nft']);
  const { getSpaceshipMatrixStatus } = useAppSelector(state => state['nft']);

  const openInfoPopup = (ship: UserSpaceships) => {
    setInfoPopupData(ship);
    if (ship.status === 'closed') dispatch(nftActions.getSpaceshipMatrix(ship.id));
    setIsInfoPopupOpened(true);
  }

  useEffect(() => {
    if (isInfoPopupOpened) {
      setDocumentBodyFixed();
      setIsMobileHeader(false);
    } else {
      setDocumentBodyInitial();
      setIsMobileHeader(true);
    }
  }, [isInfoPopupOpened])

  /* открытие инфоблоков в попапе информации на мобилке */
  // const [isMobDescriptionOpened, setIsMobDescriptionOpened] = useState(false);
  // const [isMobListOpened, setIsMobListOpened] = useState(false);

  /* закрытие инфо попапа */
  const closeInfoPopup = () => {
    setIsInfoPopupOpened(false);
    // setIsMobDescriptionOpened(false);
    // setIsMobListOpened(false);
  }

  let parent,
  admiral: SpaceshipsInMatrix | undefined,
  lineTwo = [] as SpaceshipsInMatrix[], 
  lineThreeTop = [] as SpaceshipsInMatrix[], 
  lineThreeBottom = [] as SpaceshipsInMatrix[];
  if (lastLoadedMatrix.length > 0) {
    admiral = lastLoadedMatrix.find((item) => item.spaceshipId === infoPopupData?.id);
    parent = lastLoadedMatrix.find((item) => item.spaceshipId === admiral?.spaceshipParentId);
    lineTwo = lastLoadedMatrix.filter((item) => item.spaceshipParentId === admiral?.spaceshipId);
    lineThreeTop = lastLoadedMatrix.filter((item) => item.spaceshipParentId === lineTwo[0]?.spaceshipId);
    lineThreeBottom = lastLoadedMatrix.filter((item) => item.spaceshipParentId === lineTwo[1]?.spaceshipId);
  }

  /* race description */
  const raceDescription = races.find((race) => race.name === userRace);

  /* holocard */
  const holocardTimer = useRef<NodeJS.Timeout | undefined>(undefined);

  const handleHolocardMousemove = (evt: any) => {
     // normalise touch/mouse
     let pos = [evt.nativeEvent.offsetX, evt.nativeEvent.offsetY];
     evt.preventDefault();
     if ( evt.nativeEvent.type === "touchmove" ) {
       pos = [ evt.nativeEvent.touches[0].clientX, evt.nativeEvent.touches[0].clientY ];
     }
     let $card = evt.currentTarget;
     let $cardBefore = $card.querySelector('.holocardBefore');
     let $cardAfter = $card.querySelector('.holocardAfter');
     // math for mouse position
     let l = pos[0];
     let t = pos[1];
     let h = $card.getBoundingClientRect().height;
     let w = $card.getBoundingClientRect().width;
     let px = Math.abs(Math.floor(100 / w * l)-100);
     let py = Math.abs(Math.floor(100 / h * t)-100);
     let pa = (50-px)+(50-py);
     // math for gradient / background positions
     let lp = (50+(px - 50)/1.5);
     let tp = (50+(py - 50)/1.5);
     let px_spark = (50+(px - 50)/7);
     let py_spark = (50+(py - 50)/7);
     let p_opc = 20+(Math.abs(pa)*1.5);
     let ty = ((tp - 50)/2) * -1;
     let tx = ((lp - 50)/1.5) * .5;
     // css to apply for active card
     let grad_pos = `background-position: ${lp}% ${tp}%;`;
     let sprk_pos = `background-position: ${px_spark}% ${py_spark}%;`;
     let opc = `opacity: ${p_opc/100};`;
     let tf = `transform: rotateX(${ty}deg) rotateY(${tx}deg)`;
     // need to use a <style> tag for psuedo elements
     let bs = `${grad_pos}`;
     let as = `${sprk_pos} ${opc}`;
     // set / apply css class and style
     $card.classList.remove("active");
     $card.classList.remove("animated");
     $card.setAttribute( "style", tf );
     $cardBefore.setAttribute( "style", bs );
     $cardAfter.setAttribute( "style", as );
     if ( evt.type === "touchmove" ) {
       return false; 
     }
     clearTimeout(holocardTimer.current);
  }

  const handleHolocardMouseout = (evt: any) => {
    var $card = evt.currentTarget;
    var $cardBefore = $card.querySelector('.holocardBefore');
    var $cardAfter = $card.querySelector('.holocardAfter');
    $cardBefore.removeAttribute("style");
    $cardAfter.removeAttribute("style");
    $card.removeAttribute("style");
    holocardTimer.current = setTimeout(function() {
      $card.classList.add("animated");
    },2500);
  }

  return (

    <>
      <section className={`inv section-bg ${isInfoPopupOpened ? 'high-z' : ''}`}>

      { getUserSpaseshipsStatus === 'success' && (
        <header className="inv__header">
          <h1>Мои NFT:</h1>
        </header>
      ) }

      { getUserSpaseshipsStatus === 'success' && (
        <div className="inv__grid">

          {/* grid left column */}
          <div className="inv__grid-left">

            {/* user avatar */}
            <div className="inv__grid-left-hero hero-card">
              <div className={`hero-card__item-btn ${isPremAvatar ? "hero-card__item-btn--prem" : ""}`}>
              <div className="hero-card__item-picContainer">
                <picture className="hero-card__item-pic">
                  <img src={nftAvatarUrl} alt="player nft avatar" className="no-hover" />
                </picture>
                {isPremAvatar && (
                  <img 
                    src="img/prem-border-pc-main.avif"
                    alt="premium avatar border"
                    className="hero-card__item-picBorder no-hover"
                  />
                )}
              </div>
              {isPremAvatar && (
                  <span className="hero-card__item-premRace hero-card__item-premRace--small">
                    {currentUser?.race?.name} <br /> PREMIUM
                  </span>
                )}
              {!isPremAvatar && (<span className="hero-card__item-btnRace">
                {currentUser?.race?.name}
              </span>)}
              </div>

            </div>

            {/* race description */}
            <div className="inv__grid-left-text-wrapper">
              <div className="inv__grid-left-text custom-scroll">
                <h2>{currentUser?.race?.name}</h2>
                <p>{raceDescription?.description}</p>
              </div>
            </div>

          </div>

          {/* grid right column */}
          <div className="inv__grid-right">

            {/* inventory */}
            <div className="inv__container">
              <ul className="inv__list custom-scroll-alt">

                {/* slots with ships */}
                { userSpaceships.map((ship) => (
                  <InventorySlot ship={ship} openInfoPopup={openInfoPopup} />
                ))}

                {/* empty slots */}
                { emptySlots.length > 0 && (
                  <>
                  { emptySlots.map((item) => <li key={item}></li>) }
                  </>
                )}

                {/* empty slots for mobile */}
                { emptyMobileSlots.length > 0 && (
                  <>
                  { emptyMobileSlots.map((item) => <li className="inv__item-mob" key={item}></li>) }
                  </>
                )}

              </ul>
            </div>

          </div>

        </div>
      ) }

      {/* loader for user spaceships */}
      { getUserSpaseshipsStatus === 'loading' && (
        <SimpleLoader addClass={'store__loader'} />
      ) }

      {/* show error if failed get user spaceships */}
      { getUserSpaseshipsStatus === 'failed' && (
        <div className="inv__center-message center-message">
          <p>Что-то пошло не так, пожалуйста обновите страницу.</p>
          <button className="button-common" onClick={() => window.location.reload()}>обновить</button>
        </div>
      ) }

      </section>

      {/* info about item - modal */}
      {isInfoPopupOpened && (
        <div className={`inv__m-item`}>
          <div className="inv__m-item-container">

            {/* close popup */}
            <button
            onClick={closeInfoPopup}
            className="inv__m-item-close button-close"
            >
            </button>
            <h2>{infoPopupData?.spaceship.type}</h2>

            {/* spaceship picture */}
            <div className="inv__m-item-left">
              <div
              className={`inv__m-item-pic holocard 
              ${infoPopupData?.spaceship.type === 'Истребитель' ? 'uncommon' : ''}
              ${infoPopupData?.spaceship.type === 'Штурмовой' ? 'rare' : ''}
              ${infoPopupData?.spaceship.type === 'Штурмовой' ? 'rare' : ''}
              ${infoPopupData?.spaceship.type === 'Крейсер' ? 'epic' : ''}
              ${infoPopupData?.spaceship.type === 'Дредноут' ? 'legendary' : ''}
              ${infoPopupData?.spaceship.type === 'Разрушитель' ? 'artifact' : ''}
              `}
              onMouseMove={(evt) => {handleHolocardMousemove(evt)}} onMouseOut={(evt) => {handleHolocardMouseout(evt)}}
              >
                <div className="holocardBefore"></div>
                <img src={`${infoPopupData?.nft.url}`} alt={infoPopupData?.spaceship.type} />           
                <div className="holocardAfter"></div>
              </div>
            </div>

            <div className="inv__m-item-right">
              {/* описания разных рас */}
              <div className={`inv__m-item-hh active`}>
                <button onClick={() => {/*setIsMobDescriptionOpened(!isMobDescriptionOpened)}*/}} className="inv__m-item-hh-button">Описание</button>
                {userRace === infoPopupData?.spaceship.infoHuman.race.name && <p>{infoPopupData?.spaceship.infoHuman.description}</p> }
                {userRace === infoPopupData?.spaceship.infoCyborg.race.name && <p>{infoPopupData?.spaceship.infoCyborg.description}</p> }
                {userRace === infoPopupData?.spaceship.infoLizard.race.name && <p>{infoPopupData?.spaceship.infoLizard.description}</p> }
              </div>

              {/* вывод кораблей из отыгранной матрицы */}
              {infoPopupData?.status === 'closed' && (
                <>
                  {getSpaceshipMatrixStatus === 'loading'?
                    (<div className="lds-dual-ring"></div>)
                    :
                    (<div className="inv__m-item-members-container">
                      <div className={`inv__m-item-hh active`}>
                        <button onClick={() => {/*setIsMobListOpened(!isMobListOpened)*/}} className="inv__m-item-hh-button">Боевая флотилия</button>
                        <ul>
                        {/* parent, admiral: any, lineTwo: any, lineThreeTop, lineThreeBottom */}
                          {parent && (
                            <li key={parent.spaceshipId}>
                              <a href={`https://t.me/${parent.telegram}`} target="_blank" rel="noreferrer">
                                @{parent.telegram} - Корабль-родитель
                              </a>
                            </li>
                          )}
                          {admiral && (
                            <li key={admiral.spaceshipId}>
                              <span>
                                @{admiral.telegram} - Адмирал
                              </span>
                            </li>
                          )}
                          { lineTwo && lineTwo.map((ship) => (
                            <li key={ship.spaceshipId}>
                              <a href={`https://t.me/${ship.telegram}`} target="_blank" rel="noreferrer">
                                @{ship.telegram} - Корабль 1й линии
                              </a>
                            </li>
                          ))}
                          {lineThreeTop && lineThreeTop.map(ship => (
                            <li key={ship.spaceshipId}>
                              <a href={`https://t.me/${ship.telegram}`} target="_blank" rel="noreferrer">
                                @{ship.telegram} - Корабль 2й линии
                              </a>
                            </li>
                          ))}
                          {lineThreeBottom && lineThreeBottom.map(ship => (
                            <li key={ship.spaceshipId}>
                              <a href={`https://t.me/${ship.telegram}`} target="_blank" rel="noreferrer">
                                @{ship.telegram} - Корабль 2й линии
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>)
                  }
                </>
              )}
            </div>
            {/* статус корабля в углу попапа */}
            { infoPopupData?.status === 'active' && <span className="inv__m-item-right-span inv__m-item-span--new">STATUS: Новый</span>}
            { infoPopupData?.status === 'inactive' && <span className="inv__m-item-right-span inv__m-item-span--inactive">STATUS: Не активирован</span>}
            { infoPopupData?.status === 'closed' && <span className="inv__m-item-right-span">STATUS: Участвовал в бою</span>}
          </div>
        </div>
      )}
    </>
  )
}

export default InventoryScreen;