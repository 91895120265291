import { BASE_URL } from "../../../config";
import { Ships } from "../../../const";
import { Spaceship, UserSpaceships } from "../../../logic/types/response/GetUserSpaceships";

type ShipFilterType = {
  filter: keyof typeof Ships | null,
  selectedFilter: keyof typeof Ships | null,
  setSelectedFilter: (filter: keyof typeof Ships | null) => void,
  spaceshipsForShop: Spaceship[],
  userRace: string | undefined,
  spaseshipsForMatriсes: UserSpaceships[]
}

function ShipFilter({filter, selectedFilter, spaseshipsForMatriсes, setSelectedFilter, spaceshipsForShop, userRace}: ShipFilterType) {

  /* get available filters */
  const availableFilters = spaseshipsForMatriсes.map((ship) => ship.spaceship.type);

  /* images for filters */
  let filtersImages: any = {};
  if (spaceshipsForShop.length > 0) {
    spaceshipsForShop.forEach((ship: any) => {
      let imageKey: string = '';
      if (ship.infoHuman.race.name === userRace) imageKey = 'infoHuman';
      else if (ship.infoCyborg.race.name === userRace) imageKey = 'infoCyborg';
      else if (ship.infoLizard.race.name === userRace) imageKey = 'infoLizard';

      filtersImages[ship.type] = ship[imageKey]?.image.url;
    })
  }

  return ( 
    <li 
    key={filter} 
    className={`
      ships__item 
      ${selectedFilter === filter ? 'active' : ''}
      ${availableFilters.some(item => item === filter) ? '' : 'blocked'}  
    `}
    >
      <button onClick={() => {setSelectedFilter(filter)}}>
        {filter && <img src={`${BASE_URL}${filtersImages[filter]}`} alt="" />}
        {filter}
      </button>
    </li>
   );
}

export default ShipFilter;