export enum Ships {
  "Торговый" = 'Торговый',
  "Истребитель" = 'Истребитель',
  "Штурмовой" = 'Штурмовой',
  "Крейсер" = 'Крейсер',
  "Дредноут" = 'Дредноут',
  "Разрушитель" = 'Разрушитель'
}

export enum GradeColors {
  "Торговый" = '#c8c8c8',
  "Истребитель" = '#49aa3d',
  "Штурмовой" = '#0070dd',
  "Крейсер" = '#a335ee',
  "Дредноут" = '#ff8000',
  "Разрушитель" = '#e6cc80'
}