import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PersonalState = {
  currentScreen: 'guild' | 'inventory' | 'store' | 'home' | 'wallet' | 'game' | 'data' | 'team',
  isNavigationOpened: boolean
}

const initialState: PersonalState = {
  currentScreen: 'home',
  isNavigationOpened: false,
};

export const personalSlice = createSlice({
  name: 'personal',
  initialState,

  //sync
  reducers: {

    clearState: (state) => {
      return initialState
    },

    setCurrentScreen: (state, action: PayloadAction<PersonalState["currentScreen"]>) => {
      state.currentScreen = action.payload
    },

    setIsNavigationOpened: (state, action: PayloadAction<boolean>) => {
      state.isNavigationOpened = action.payload
    },

  },

  // async
  extraReducers: (builder) => {
  },
});

export const actions = personalSlice.actions

export default personalSlice.reducer
