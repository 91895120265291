import { SyntheticEvent, useState, useEffect } from "react";
import { WithdrawRequestData } from "../../../logic/types/response/CreateWithdrawRequest";
import { useAppDispatch } from "../../../redux-store";
import { actions as walletActions } from "../../../logic/Wallet";
import SimpleLoader from "../components/SimpleLoader";

type WalletAddressModalProps = {
  currentWalletWindow: "transfer-adress" | "none" | "deposit-qr" | "message" | "ton" | "message-transfer",
  setCurrentWalletWindow: (value: React.SetStateAction<"transfer-adress" | "none" | "deposit-qr" | "message" | "ton" | "message-transfer">) => void,
  createWithdrawRequestStatus: "success" | "initial" | "loading" | "failed",
  userBalance: string,
}

function WalletAddressModal({currentWalletWindow, setCurrentWalletWindow, createWithdrawRequestStatus, userBalance} : WalletAddressModalProps) {

  const dispatch = useAppDispatch();
 
  const [withdrawData, setWithdrawData] = useState<WithdrawRequestData>({amount: '', withdrawAddress: ''});
  const [isSendDataBtnDisabled, setIsSendDataBtnDisabled] = useState(true);

  /* обработчик инпутов transfer */
  const handleTransferInput = (evt: SyntheticEvent<HTMLInputElement>) => {
    if (evt.currentTarget.name === 'amount') {
      setWithdrawData({...withdrawData, amount: evt.currentTarget.value})
    } else if (evt.currentTarget.name === 'withdrawAddress') {
      setWithdrawData({...withdrawData, withdrawAddress: evt.currentTarget.value})
    }
  }

  /* блокировка кнопки при пустых инпутах */
  useEffect(() => {
    if (!withdrawData.amount || !withdrawData.withdrawAddress) setIsSendDataBtnDisabled(true);
    else setIsSendDataBtnDisabled(false);
  }, [withdrawData.amount, withdrawData.withdrawAddress]);

  /* обработчик клика отправки transfer */
  const handleDoTransferClick = () => {
    dispatch(walletActions.createWithdrawRequest({data: withdrawData}));
  }

  return ( 
    <div className={`wallet__mdeposit ${currentWalletWindow === 'transfer-adress' ? 'active' : ''} `}>

    <div className="wallet__mdeposit-top">
      <h2>Transfer:</h2>
      <button className="button-close" onClick={() => {setCurrentWalletWindow('none')}}></button>
    </div>

    <div className="wallet__mdeposit-wrapper">
    {createWithdrawRequestStatus === 'loading' ?
      (<SimpleLoader addClass="" />)
      :
      (
        <>
          <div className="wallet__mdeposit-btn">
            <img src="img/ton.svg" alt="" />
            <span>The Open Network - TON</span>
          </div>
          
          <p className="wallet__mdeposit-notwide-text">Убедитесь, что адрес вывода находятся в сети TON, в случае ошибки средства могут быть утеряны.</p>

          <div className="wallet__mdeposit-inputs">
            <h3>Адрес кошелька:</h3>
            <input type="text" value={withdrawData.withdrawAddress} required name="withdrawAddress" onInput={handleTransferInput} />
            <h3>Количество:</h3>
            <input type="number" value={withdrawData.amount} required name="amount" onInput={handleTransferInput} min="0" max={userBalance} />
            <span>Комиссия: 0.1 TON</span>
          </div>

          <button
          onClick={handleDoTransferClick}
          className="button-common"
          disabled={isSendDataBtnDisabled}
          >
            Transfer
          </button>
        </>
      )
    }
    </div>
  </div>
   );
}

export default WalletAddressModal;