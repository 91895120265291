import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { WalletRepository } from '../api/WalletRepository';
import { WithdrawRequestData } from './types/response/CreateWithdrawRequest';
import { UserTransaction } from './types/response/GetUserTransactions';

export type WalletState = {
  getUserBalanceStatus: 'success' | 'initial' | 'loading' | 'failed';
  getUserTransactionsStatus: 'success' | 'initial' | 'loading' | 'failed';
  createWithdrawRequestStatus: 'success' | 'initial' | 'loading' | 'failed';
  getDepositAddressStatus: 'success' | 'initial' | 'loading' | 'failed';

  userBalance: string;
  userTransactions: UserTransaction[] | [];
  userDepositAddress: string;
}

const initialState: WalletState = {
  getUserBalanceStatus: 'initial',
  getUserTransactionsStatus: 'initial',
  createWithdrawRequestStatus: 'initial',
  getDepositAddressStatus: 'initial',

  userBalance: '',
  userTransactions: [],
  userDepositAddress: ''
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,

  //sync
  reducers: {
    clearState: (state) => {
      return initialState
    },

    resetCreateWithdrawRequestStatus: (state) => {
      state.createWithdrawRequestStatus = 'initial';
    },

  },

  // async
  extraReducers: (builder) => {
    builder

      //get user balance
      .addCase(getUserBalance.pending, (state) => {
        state.getUserBalanceStatus = 'loading';
      })
      .addCase(getUserBalance.fulfilled, (state, action) => {
        state.getUserBalanceStatus = 'success';
        state.userBalance = action.payload;
      })
      .addCase(getUserBalance.rejected, (state) => {
        state.getUserBalanceStatus = 'failed';
        toast.error('Ошибка получения баланса');
      })


      //get user transactions
      .addCase(getUserTransactions.pending, (state) => {
        state.getUserTransactionsStatus = 'loading';
      })
      .addCase(getUserTransactions.fulfilled, (state, action) => {
        state.getUserTransactionsStatus = 'success';
        state.userTransactions = action.payload;
      })
      .addCase(getUserTransactions.rejected, (state) => {
        state.getUserTransactionsStatus = 'failed';
        toast.error('Ошибка получения транзакций');
      })


      //create Withdraw Request
      .addCase(createWithdrawRequest.pending, (state) => {
        state.createWithdrawRequestStatus = 'loading';
      })
      .addCase(createWithdrawRequest.fulfilled, (state, action) => {
        state.createWithdrawRequestStatus = 'success';
      })
      .addCase(createWithdrawRequest.rejected, (state) => {
        state.createWithdrawRequestStatus = 'failed';
        toast.error('Ошибка создания transfer');
      })


      //get deposit
      .addCase(getDepositAddress.pending, (state) => {
        state.getDepositAddressStatus = 'loading';
      })
      .addCase(getDepositAddress.fulfilled, (state, action) => {
        state.getDepositAddressStatus = 'success';
        state.userDepositAddress = action.payload;
        console.log(action.payload, 'wallet/getDepositAddress')
      })
      .addCase(getDepositAddress.rejected, (state) => {
        state.getDepositAddressStatus = 'failed';
        toast.error('Ошибка получения deposit address');
      })

  },
});

// async functions
const getUserBalance = createAsyncThunk('wallet/getUserBalance',
  async () => {
    const user = await WalletRepository.getUserBalance();
    return user
  }
);

const getUserTransactions = createAsyncThunk('wallet/getUserTransactions',
  async () => {
    const user = await WalletRepository.getUserTransactions();
    return user
  }
);

const createWithdrawRequest = createAsyncThunk('wallet/createWithdrawRequest',
  async (dataReq: {data: WithdrawRequestData}) => {
    const user = await WalletRepository.createWithdrawRequest(dataReq);
    return user
  }
);

const getDepositAddress = createAsyncThunk('wallet/getDepositAddress',
  async () => {
    const user = await WalletRepository.getDepositAddress();
    return user
  }
);


// export data to ui component
export const asyncActions = { getUserBalance, getUserTransactions, createWithdrawRequest, getDepositAddress }
export const actions = { ...walletSlice.actions, ...asyncActions }

// export to store
export default walletSlice.reducer
