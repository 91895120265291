import { UserTransaction } from "../../../logic/types/response/GetUserTransactions";
import { useAppSelector } from "../../../redux-store";
import TransactionTableRow from "./TransactionTableRow";

type WalletTransactionsType = {
  userTransactions: [] | UserTransaction[]
}

function WalletTransactions({userTransactions} : WalletTransactionsType) {

  const { getUserTransactionsStatus } = useAppSelector(state => state['wallet']);

  return (
    <div className="wallet__rewards wallet__rewards--wide">
      <h2 className="wallet__bottom-title">Транзакции:</h2>

      <div className="wallet__rewards-wrapper">
        {getUserTransactionsStatus === "loading" ? (
          <div className="lds-dual-ring"></div>
        ) : (
          <ul className="wallet__rewards-list custom-scroll">
            {userTransactions?.length > 0 &&
              userTransactions.map((trans) => (
                <TransactionTableRow trans={trans} />
              ))}
            {userTransactions?.length < 1 && <span>Транзакций нет</span>}
          </ul>
        )}
      </div>
    </div>
  );
}

export default WalletTransactions;
