import { UserSpaceships } from "../../../logic/types/response/GetUserSpaceships";

type InventorySlotType = {
  ship: UserSpaceships,
  openInfoPopup: (ship: UserSpaceships) => void
}

function InventorySlot({ship, openInfoPopup} : InventorySlotType) {
  return ( 
    <li key={ship.id}>
      <button
      onClick={() => {openInfoPopup(ship)}}
      className="inv__item-btn"
      title={ship.spaceship.type}
      >
        <img src={`${ship.nft?.url}`} alt={ship.spaceship.type} />
        {ship.status === 'active' && <span className="active"></span>}
        {ship.status === 'inactive' && <span className="inactive"></span>}
        {ship.status === 'closed' && <span></span>}
      </button>
    </li>
  );
}

export default InventorySlot;