import { UserTransaction } from "../../../logic/types/response/GetUserTransactions";
import { useAppSelector } from "../../../redux-store";
import transferDate from "../../../utils/transferDate";

type WalletRewardsType = {
  rewards: UserTransaction[],
}

function WalletRewards({rewards} : WalletRewardsType) {

  const { nftAvatarDate } = useAppSelector(state => state['nft']);

  return (
    <div className="wallet__rewards wallet__rewards--wide">
      <h2 className="wallet__bottom-title">Награды:</h2>

      <div className="wallet__rewards-wrapper">
        <ul className="wallet__rewards-list custom-scroll">
          {/*вывод наград-бонусов*/}
          {rewards.length > 0 &&
            rewards.map((reward) => (
              <li className="wallet__rewards-item">
                <span>
                  {reward.operation} {reward.amount} {reward.status} TON
                </span>
                <div className="dash-line"></div>
                <span>{transferDate(reward.createdAt)}</span>
              </li>
            ))}
          {/*вывод в наградах НФТ аватара */}
          <li className="wallet__rewards-item">
            <span>Получен NFT Avatar</span>
            {nftAvatarDate && (
              <>
                <div className="dash-line"></div>
                <span>{transferDate(nftAvatarDate)}</span>
              </>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default WalletRewards;
