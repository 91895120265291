import { TelegramAuthData } from "@telegram-auth/react";

export const buildUrl = (data: TelegramAuthData, baseUrl: string) => {
  let count = 1;
  const size = Object.keys(data).length;

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = (data as any)[key];
      const isLast = count === size;
      baseUrl += isLast ? `${key}=${value}` : `${key}=${value}&`
      count++
    }
  }

  return baseUrl;
}