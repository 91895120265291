import { BrowserRouter, useRoutes } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { actions as userActions } from './logic/User';
import { actions as nftActions } from './logic/Nft';
import { useAppDispatch, useAppSelector } from './redux-store';
import CreateNftPage from './ui/personal/CreateNft';

import LoginPage from "./ui/personal/Login"
import PersonalPage from "./ui/personal/Personal";
import { useEffect } from 'react';
import SimpleLoader from './ui/personal/components/SimpleLoader';

// * routes
const routes = [
  { path: '/createnft', element: <CreateNftPage /> },
  { path: '/login', element: <LoginPage /> },
  { path: '/:refcode', element: <LoginPage /> },
  { path: '/', element: <PersonalPage /> },
]

const App = () => useRoutes(routes)

const AppWrapper = () => {

  const dispatch = useAppDispatch();

  const { currentUser } = useAppSelector(state => state['user']);
  const { isRequestLoaderActive } = useAppSelector(state => state['user']);
  const { authStatus } = useAppSelector(state => state['user']);
  const { getUserNftAvatarStatus } = useAppSelector(state => state['nft']);

  useEffect(() => {
    dispatch(userActions.getAuthenticatedUser())
  }, [])

  useEffect(() => {
    if (currentUser) {
      dispatch(nftActions.getUserAvatar())
    }
  }, [currentUser])

//currentUser && currentUser.inviterCode && nftAvatarUrl

  useEffect(() => {
    if  (authStatus === 'failed' ||
        (authStatus === 'success' && !currentUser) ||
        (getUserNftAvatarStatus === 'failed' || getUserNftAvatarStatus === 'success')) {
      dispatch(userActions.setIsRequestLoaderActive(false))
    } else { userActions.setIsRequestLoaderActive(true) }
  }, [authStatus, getUserNftAvatarStatus]);

  return (
    <BrowserRouter>
        <App />
        <div className={`request-loader ${isRequestLoaderActive ? 'active' : ''}`}>
          <div className='request-loader__wrapper'>
            <span>T-PLANETS LOADING...</span>
            <div className="lds-dual-ring"></div>
          </div>
        </div>
        <ToastContainer
          toastStyle={{ backgroundColor: "#060606"}}
          position="bottom-center"
          autoClose={3000}
          theme="dark"
          newestOnTop={true}
          limit={3}
          transition={Slide}
          progressStyle={{height: '1px'}}
          closeOnClick={true}
          closeButton={false}
        />
    </BrowserRouter>
  )
}

export default AppWrapper;