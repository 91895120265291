import {useState} from 'react';

type WalletVideoModalType = {
  isVideoModal: boolean,
  closeVideoModal: () => void
}

function WalletVideoModal({isVideoModal, closeVideoModal} : WalletVideoModalType) {

  /* мобильное переключение видео */
  const [isMobVideoVisible, setIsMobVideoVisible] = useState(false);
  const switchToVideo = () => setIsMobVideoVisible(true);

  return ( 
    <div className={`wallet__videoModal ${isVideoModal ? 'active' : ''}`}>
    <button className="wallet__videoModalClose button-close" onClick={closeVideoModal}></button>
      <div className="wallet__videoModalContainer">
        <h2>Посмотрите видео-инструкцию:</h2>
        {!isMobVideoVisible && <button className="wallet__videoModalPlay" onClick={switchToVideo}></button> }
        <video className={`wallet__videoModalVideo ${isMobVideoVisible ? 'active' : ''}`} src="/img/buy-ton.mp4" controls={true}></video>
      </div>

      <div className="wallet__videoModalDecor"></div>

      <div className="wallet__videoModalContainer">
        <h2>Перейти к покупке:</h2>
        <a className="wallet__videoModalLink" href="https://t.me/wallet" target="_blank" rel="noreferrer">
          <img src="img/ton/wallet.jpg" alt="" />
        </a>
      </div>
  </div>
   );
}

export default WalletVideoModal;