const Share = (url) => {
  if (navigator.share !== undefined) {
    navigator.share({
      title: 'T-Planets',
      text: 'Привет! Приглашаю в закрытое комьюнити и дарю тебе NFT проекта "T-Planets"',
      url
    })
  .catch((error) => console.log(`Ошибка Share`))
  }
}

export default Share;