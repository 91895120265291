import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import personalReducer from './logic/Main';
import guildReducer from './logic/Guild';
import userReducer from './logic/User';
import nftReducer from './logic/Nft';
import walletReducer from './logic/Wallet';

export const store = configureStore({
  reducer: {
    "personal": personalReducer,
    "guild": guildReducer,
    "user": userReducer,
    "nft": nftReducer,
    "wallet": walletReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
