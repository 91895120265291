type StartAdventureButtonType = {
  handleStartGameBtnClick: () => void,
  spaceshipsForGame: any,
}

function StartAdventureButton({handleStartGameBtnClick, spaceshipsForGame} : StartAdventureButtonType) {
  return ( 
    <button
    className="ships__grid-left-start"
    onClick={handleStartGameBtnClick}
    disabled={spaceshipsForGame.length > 0 ? false : true}
    >
      <img src="img/start1.png" alt="start adventure" />
      <img src="img/start2.png" alt="start adventure" />
      {spaceshipsForGame.length > 0 && <span>играть!</span> }
    </button>
   );
}

export default StartAdventureButton;