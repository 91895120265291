import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL } from "../../config";
import { actions as nftActions } from "../../logic/Nft";
import { actions as personalActions } from "../../logic/Main";
import { actions as userActions } from "../../logic/User";
import { useAppDispatch, useAppSelector } from "../../redux-store";
import { sleep } from "../../utils/sleep";
import HexLoader from "./components/HexLoader";
import SimpleLoader from "./components/SimpleLoader";
import SvgRoundBorder from "./components/SvgRoundBorder";

function CreateNftPage() {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { currentUser } = useAppSelector(state => state['user']);
  const { nftAvatarUrl } = useAppSelector(state => state['nft']);

  const { isSubscribedToChannelStatus } = useAppSelector(state => state['user']);
  const { isUserSubscribed } = useAppSelector(state => state['user']);
  
  const [ currentRace, setCurrentRace ] = useState<'cyborg' | 'lizard' | 'human'>('human');
  const [ currentScreen, setCurrentScreen ] = useState<'first' | 'checkSub' | 'race-select' | 'user-img-load' | 'loading' | 'succes'>('first');
  const [ isUserAcceptNftReady, setIsUserAcceptNftReady ] = useState<boolean>(true);
  const [ currentRaceId, setCurrentRaceId ] = useState<string | null>(null);


  /* redirect */
  useEffect(() => { 
    if (!currentUser || (currentUser && !currentUser.inviterCode)) navigate('/login');
    else if (isUserAcceptNftReady && currentUser && currentUser.inviterCode && nftAvatarUrl) { 
      navigate('/');
      dispatch(userActions.getAuthenticatedUser());
      toast(`🚀 Добро пожаловать, ${currentUser.telegram}!`);
    }
  }, [currentUser, nftAvatarUrl, isUserAcceptNftReady]);


  /* Creating user Avatar */
  const handleCreateNftClick = async () => {
    if (currentRaceId) {
      setCurrentScreen('loading');
      try {
        const responseCreateAvatar: any = await dispatch(nftActions.createInitAvatar(currentRaceId));
        dispatch(userActions.setReferrerCode(responseCreateAvatar.payload.data?.createInitAvatar.referralCode));
        await sleep(5000);
        setCurrentScreen('succes');
      } catch (error: any) {
        toast.error(error.message);
        setCurrentScreen('race-select');
      }
    }
    else {
      toast.error('Что-то пошло не так...')
    }
  }

  const goToHomeScreenWallet = () => {
    dispatch(personalActions.setCurrentScreen("wallet"));
    setIsUserAcceptNftReady(true);
  }

  /* get races */
  const { races } = useAppSelector(state => state['nft']); 
  const { getRacesStatus } = useAppSelector(state => state['nft']);

  useEffect(() => {
    dispatch(nftActions.getRaces());
    dispatch(userActions.isSubscribedToChannel());
  }, []);

  const firstTimeSetRace = useRef(true);

  useEffect(() => {
    if (races.length > 0) {
      if (firstTimeSetRace.current) {
        const cyborg = races.find((race) => race.name === 'Space cyborg');
        if (cyborg) {
          setCurrentRaceId(cyborg.id);
          firstTimeSetRace.current = false;
        }
      }
    };
  }, [races]);
  
  let sortedRaces: any = [];
  const racesNames = [ 'Human race', 'Space cyborg', 'Space lizzard' ]
  if (races.length > 0) {
    racesNames.forEach((name) => sortedRaces.push( races.find((race) => race.name === name) ))
  }
   
  /* select Human */
  const handleHumanClick = () => {
    setCurrentRace('human');
    setCurrentRaceId(sortedRaces[0].id);
  } 

  /* select Cyborg */
  const handleCyborgClick = () => {
    setCurrentRace('cyborg');
    setCurrentRaceId(sortedRaces[1].id);
  }

  /* select Lizard */
  const handleLizardClick = () => {
    setCurrentRace('lizard');
    setCurrentRaceId(sortedRaces[2].id);
  }

  /* Referral username */
  const referralName = currentUser?.referralName.replace(/undefined/ig, '');

  /* Click to create NFT at first screen */
  const handleCreateNext = () => {
    if (isUserSubscribed) setCurrentScreen('race-select');
    else setCurrentScreen('checkSub');
    setIsUserAcceptNftReady(false);
  }

  useEffect(() => {
    if (currentScreen === 'checkSub' && isUserSubscribed) {
      setCurrentScreen('race-select');
    }
  }, [currentScreen, isUserSubscribed]);


  return (
    <section className="createnft section-bg--create">

      {/* вам подарили коробку */}
      { currentScreen === 'first' && (
        <div className="createnft__friend">
          <img src="img/box.png" alt="nft box" />
          <div className="createnft__friend-content">
            <h2>T-PLANETS</h2>
            <p>
              ВАШ ДРУГ 
              <span className="createnft__friend-nickname">{ referralName }</span>
              <br className="createnft__friend-br" />
              ПОДАРИЛ ВАМ NFT
            </p>
            <button className="button-common" onClick={handleCreateNext}>СОЗДАТЬ NFT</button>
          </div>
        </div>
      ) }

      {/* проверка подписки */}
      { currentScreen === 'checkSub' && (
        <div className="createnft__sub">
          {isSubscribedToChannelStatus === 'loading' ?
            (<SimpleLoader addClass={""} />)
            :
            (<>
              <h2>Subscribe check</h2>
              <p>Вы не подписаны на <br/> <a href="https://t.me/tplanets_games" target="_blank" rel="noopener noreferrer">группу игры в Telegram</a></p>
              <button onClick={() => dispatch(userActions.isSubscribedToChannel())} className="button-common button-common--wide">Проверить еще раз</button> 
            </>)
          }
        </div>
      ) }

      {/* выбор расы */}
      { getRacesStatus === 'success' && currentScreen === 'race-select' && sortedRaces.length > 0 && (
        <div className="createnft__race">

          <div className="createnft__title-top">
            <h2>SELECT YOUR RACE</h2>
            <span>T-PLANETS</span>
          </div>

          <ul className={`createnft__race-imgs ${currentRace === 'lizard' ? 'transf200' : ''} ${currentRace === 'cyborg' ? 'transf100' : ''}`}>
            <li className={`${currentRace === 'human' ? 'active' : ''}`}>
              <img src={`${BASE_URL}${sortedRaces[0].image?.url}`} alt={sortedRaces[0].name} className="createnft__race-img img3" />
            </li>
            <li className={`${currentRace === 'cyborg' ? 'active' : ''}`}>
              <img src={`${BASE_URL}${sortedRaces[1].image?.url}`} alt={sortedRaces[1].name} className="createnft__race-img img1" />
            </li>
            <li className={`${currentRace === 'lizard' ? 'active' : ''}`}>
              <img src={`${BASE_URL}${sortedRaces[2].image?.url}`} alt={sortedRaces[2].name} className="createnft__race-img img2" />
            </li>
          </ul>

          <button className="createnft__get-nft-mobile" onClick={handleCreateNftClick}>Получить NFT</button>

          { sortedRaces.length > 0 && (
            <div className="createnft__text-info">
              { currentRace === 'human' && (
                <div className="createnft__text-info-wrapper">
                  <h2>{ sortedRaces[0].name }</h2>
                  <p>{ sortedRaces[0].description }</p>
                  <button 
                  className="createnft__get-nft button-common button-common--wide" 
                  onClick={handleCreateNftClick}
                  >
                    Получить NFT
                  </button>
                </div>
              ) }

              { currentRace === 'cyborg' && (
                <div className="createnft__text-info-wrapper">
                  <h2>{ sortedRaces[1].name }</h2>
                  <p>{ sortedRaces[1].description }</p>
                  <button 
                  className="createnft__get-nft button-common button-common--wide"
                  onClick={handleCreateNftClick}
                  >
                    Получить NFT
                  </button>
                </div>
              ) }

              { currentRace === 'lizard' && (
                <div className="createnft__text-info-wrapper">
                  <h2>{ sortedRaces[2].name }</h2>
                  <p>{ sortedRaces[2].description }</p>
                  <button 
                  className="createnft__get-nft button-common button-common--wide" 
                  onClick={handleCreateNftClick}
                  >
                    Получить NFT
                  </button>
                </div>
              ) }
            </div>
          ) }

          <div className="createnft__race-buttons">
            <button onClick={handleHumanClick}>
              <SvgRoundBorder svgColor={currentRace === 'human' ? '#fff' : '#2BBFB7'} />
              <img src="img/mini-hum.png" alt="" />
            </button>
            <button onClick={handleCyborgClick}>
              <SvgRoundBorder svgColor={currentRace === 'cyborg' ? '#fff' : '#2BBFB7'} />
              <img src="img/mini-cyb.png" alt="" />
            </button>
            <button onClick={handleLizardClick}>
              <SvgRoundBorder svgColor={currentRace === 'lizard' ? '#fff' : '#2BBFB7'} />
              <img src="img/ava.png" alt="" />
            </button>
          </div>

        </div>
      ) }

      {/* ошибка загрузки рас */}
      { getRacesStatus === 'failed' && (
        <div className="createnft__center-message center-message">
          <p>Что-то пошло не так, пожалуйста обновите страницу.</p>
          <button className="button-common" onClick={() => window.location.reload()}>обновить</button>
        </div>
      ) }

      {/* загрузка рас */}
      { getRacesStatus === 'loading' && (
        <SimpleLoader addClass={'createnft__simp-loader'} />
      ) }

      {/* ждём ответ сервера */}
      { currentScreen === 'loading' && (
        <div className="createnft__loader">
          <div className="createnft__title-top">
            <h2>NFT GENERATION</h2>
            <span>T-PLANETS</span>
          </div>
          <div className="createnft__loader-container">
            <HexLoader />
          </div>
          <p>Создание NFT может занять несколько минут, пожалуйста не закрывайте и не обновляйте страницу.</p>
        </div>
      ) }

      {/* поздравление с получением Nft аватара */}
      { currentScreen === 'succes' && (
        <div className="congrat-popup ava">
          <picture>
            <img src={`${nftAvatarUrl}`} alt="" />
          </picture>
          <p>Поздравляем <br/> с получением NFT!</p>
          <button className="button-common button-common--100" onClick={goToHomeScreenWallet}>Перейти в кабинет</button>
        </div>
      ) }

    </section>
  );
}

export default CreateNftPage;