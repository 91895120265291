function DataScreen() {
  return ( 
    <section className="data">
      <ul className="data__list">
        <li>
          <a className="data__button" href={`https://tplanets.games/docs/wp-tplanets.pdf`} target="_blank" rel="noopener noreferrer">
            Whitepaper
          </a>
        </li>
        <li>
          <a className="data__button" href={`https://tplanets.games/docs/airdrop.pdf`} target="_blank" rel="noopener noreferrer">
            Airdrop
          </a>
        </li>
        <li>
          <a className="data__button" href={`https://tplanets.games/docs/mining.pdf`} target="_blank" rel="noopener noreferrer">
            Mining info
          </a>
        </li>
        <li>
          <a className="data__button" href={`https://tplanets.games/docs/rarity.pdf`} target="_blank" rel="noopener noreferrer">
            Avatar rarity
          </a>
        </li>
      </ul>
    </section>
  );
}

export default DataScreen;