import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { WhiteListRepository } from '../api/WhiteListRepository';
import { GradeColors } from '../const';
import { SpaceshipsInMatrix } from './types/response/GetSpaceshipMatrix';
import { UserChildStat } from './types/response/getUserChildStats';
import { Spaceship } from './types/response/GetUserSpaceships';
import { UserSpaceships } from './types/response/GetUserSpaceships';
import { UserSpaceshipsWaitingChild } from './types/response/GetUserSpaceshipsWaitingChilds';
import { Race } from './types/response/Races';

export type NftState = {
  getUserNftAvatarStatus: 'success' | 'initial' | 'loading' | 'failed';
  createInitAvatarStatus: 'success' | 'initial' | 'loading' | 'failed';
  getSpaceshipsForShopStatus: 'success' | 'initial' | 'loading' | 'failed';
  getUserSpaseshipsStatus: 'success' | 'initial' | 'loading' | 'failed';
  getSpaceshipsForMatriсesStatus: 'success' | 'initial' | 'loading' | 'failed';
  buySpaceshipStatus: 'success' | 'initial' | 'loading' | 'failed';
  buySpaceshipsStatus: 'success' | 'initial' | 'loading' | 'failed';
  getSpaceshipMatrixStatus: 'success' | 'initial' | 'loading' | 'failed';
  getRacesStatus: 'success' | 'initial' | 'loading' | 'failed';
  getUserSpaceshipsUnplayedStatus: 'success' | 'initial' | 'loading' | 'failed';
  getRewardsSummStatus: 'success' | 'initial' | 'loading' | 'failed';
  getAdventureDoneStatus: 'success' | 'initial' | 'loading' | 'failed';
  getUserSpaceshipsCountStatus: 'success' | 'initial' | 'loading' | 'failed';
  getUserRatingStatus: 'success' | 'initial' | 'loading' | 'failed';
  getGuildMembersNumberStatus: 'success' | 'initial' | 'loading' | 'failed';
  accrueProfitStatus: 'success' | 'initial' | 'loading' | 'failed';
  activateSpaseshipForMatrixStatus: 'success' | 'initial' | 'loading' | 'failed';
  getUserSpaceshipsWaitingChildsStatus: 'success' | 'initial' | 'loading' | 'failed';
  getUserChildStatsStatus: 'success' | 'initial' | 'loading' | 'failed';
  

  nftAvatarUrl: string,
  isPremAvatar: boolean,
  nftAvatarDate: string,

  races: Race[],

  spaceshipsForShop: Spaceship[],
  currentNumberOfSpaceships: number,
  spaceshipsForGame: any,
  currentSpaceshipsInGame: (keyof typeof GradeColors)[],
  userSpaceships: UserSpaceships[],
  spaseshipsForMatriсes: UserSpaceships[],
  currentShipForMatrix: null | any,
  lastLoadedMatrix: SpaceshipsInMatrix[],

  lastPurchasedSpaceship: {name: string, img: string} | null,
  userSpaceshipsWaitingChilds: UserSpaceshipsWaitingChild[],

  userChildStats: UserChildStat[],

  rewardsSumm: string,
  adventuresDone: number,
  userRating: number,
  guildMembersNumber: number
}

const initialState: NftState = {
  getUserNftAvatarStatus: 'initial',
  createInitAvatarStatus: 'initial',
  getSpaceshipsForShopStatus: 'initial',
  getUserSpaseshipsStatus: 'initial',
  getSpaceshipsForMatriсesStatus: 'initial',
  buySpaceshipStatus: 'initial',
  buySpaceshipsStatus: 'initial',
  getSpaceshipMatrixStatus: 'initial',
  getRacesStatus: 'initial',
  getUserSpaceshipsUnplayedStatus: 'initial',
  getRewardsSummStatus: 'initial',
  getAdventureDoneStatus: 'initial',
  getUserSpaceshipsCountStatus: 'initial',
  getUserRatingStatus: 'initial',
  getGuildMembersNumberStatus: 'initial',
  accrueProfitStatus: 'initial',
  activateSpaseshipForMatrixStatus: 'initial',
  getUserSpaceshipsWaitingChildsStatus: 'initial',
  getUserChildStatsStatus: 'initial',

  lastPurchasedSpaceship: null,
  userSpaceshipsWaitingChilds: [],

  nftAvatarUrl: '',
  isPremAvatar: false,
  nftAvatarDate: '',

  races: [],

  spaceshipsForShop: [],
  currentNumberOfSpaceships: 0,
  spaceshipsForGame: [],
  currentSpaceshipsInGame: [],
  userSpaceships: [],
  spaseshipsForMatriсes: [],
  currentShipForMatrix: null,
  lastLoadedMatrix: [],

  userChildStats: [],

  rewardsSumm: '0',
  adventuresDone: 0,
  userRating: 0,
  guildMembersNumber: 0
};

export const nftSlice = createSlice({
  name: 'nft',
  initialState,

  //sync
  reducers: {

    clearState: (state) => {
      return initialState
    },

    setAvatarUrl: (state, action: PayloadAction<string>) => {
      state.nftAvatarUrl = action.payload;
    },

    setCurrentShipForMatrix: (state, action: PayloadAction<any>) => {
      state.currentShipForMatrix = action.payload;
    },
    
    setCurrentSpaceshipsInGame: (state, action: PayloadAction<(keyof typeof GradeColors)[]>) => {
      state.currentSpaceshipsInGame = action.payload;
    },

    resetAccrueProfitStatus: (state) => {
      state.accrueProfitStatus = 'initial';
    },

    setLastPurchasedSpaceship: (state, action: PayloadAction<{name: string, img: string}>) => {
      state.lastPurchasedSpaceship =  action.payload;
    },

    resetBuySpaceshipStatus: (state) => {
      state.buySpaceshipStatus = 'initial';
    },

    resetActivateSpaseshipForMatrixStatus: (state) => {
      state.activateSpaseshipForMatrixStatus = 'initial';
    },

    resetCreateInitAvatarStatus: (state) => {
      state.createInitAvatarStatus = 'initial';
    },
  },

  // async
  extraReducers: (builder) => {
    builder

    // get User Nft Avatar
    .addCase(getUserAvatar.pending, (state) => {
      state.getUserNftAvatarStatus = 'loading';
    })
    .addCase(getUserAvatar.fulfilled, (state, action) => {
      if (action.payload?.length > 1) {
        const arrToSort = [...action.payload];
        arrToSort.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
        state.nftAvatarUrl = arrToSort[0].url;
        state.nftAvatarDate = arrToSort[0].createdAt;
        state.isPremAvatar = true;
        state.getUserNftAvatarStatus = 'success';
      } else if (action.payload?.length === 1) {
        state.nftAvatarUrl = action.payload[0].url;
        state.nftAvatarDate = action.payload[0].createdAt;
        state.getUserNftAvatarStatus = 'success';
      } else {
        state.getUserNftAvatarStatus = 'failed';
      }
    })
    .addCase(getUserAvatar.rejected, (state) => {
      state.getUserNftAvatarStatus = 'failed';
      toast.error('Ошибка загрузки аватара. Попробуйте позже')
    })


    // Create and init user Avatar
    .addCase(createInitAvatar.pending, (state) => {
      state.createInitAvatarStatus = 'loading';
    })
    .addCase(createInitAvatar.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.nftAvatarUrl = action.payload.data?.createInitAvatar.url;
        state.createInitAvatarStatus = 'success';
      } else if (action.payload.errors) {
        state.createInitAvatarStatus = 'failed';
        toast.error(`Ошибка создания аватара. ${action.payload.errors[0].message}`)
      }
    })
    .addCase(createInitAvatar.rejected, (state) => {
      state.createInitAvatarStatus = 'failed';
      toast.error('Ошибка загрузки аватара. Попробуйте позже')
    })


    // get Spase Ships For Shop
    .addCase(getSpaceshipsForShop.pending, (state) => {
      state.getSpaceshipsForShopStatus = 'loading';
    })
    .addCase(getSpaceshipsForShop.fulfilled, (state, action) => {
      if (!action.payload || action.payload.length < 1) {
        state.getSpaceshipsForShopStatus = 'failed';
      } else {
        state.spaceshipsForShop = action.payload;
        state.getSpaceshipsForShopStatus = 'success';
      }
    })
    .addCase(getSpaceshipsForShop.rejected, (state) => {
      state.getSpaceshipsForShopStatus = 'failed';
    })


    // get User Spase Ships
    .addCase(getUserSpaseships.pending, (state) => {
      state.getUserSpaseshipsStatus = 'loading';
    })
    .addCase(getUserSpaseships.fulfilled, (state, action) => {
      if (!action.payload) {
        state.getUserSpaseshipsStatus = 'failed';
      } else {
        state.userSpaceships = action.payload;
        state.getUserSpaseshipsStatus = 'success';
      }
    })
    .addCase(getUserSpaseships.rejected, (state) => {
      state.getUserSpaseshipsStatus = 'failed';
      toast.error('Ошибка загрузки инвентаря. Попробуйте позже')
    })


    // get User Spase Ships Available for matrix
    .addCase(getSpaceshipsForMatriсes.pending, (state) => {
      state.getSpaceshipsForMatriсesStatus = 'loading';
    })
    .addCase(getSpaceshipsForMatriсes.fulfilled, (state, action) => {
      state.spaseshipsForMatriсes = action.payload;
      state.getSpaceshipsForMatriсesStatus = 'success';
    })
    .addCase(getSpaceshipsForMatriсes.rejected, (state) => {
      state.getSpaceshipsForMatriсesStatus = 'failed';
      toast.error('Ошибка загрузки кораблей. Попробуйте позже')
    })


    // buy Spase Ship
    .addCase(buySpaceship.pending, (state) => {
      state.buySpaceshipStatus = 'loading';
    })
    .addCase(buySpaceship.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.buySpaceshipStatus = 'failed';
        toast.error(`Ошибка покупки корабля: ${action.payload.error}`)
      } else {
        state.buySpaceshipStatus = 'success';
      }
    })
    .addCase(buySpaceship.rejected, (state) => {
      state.buySpaceshipStatus = 'failed';
      toast.error('Ошибка покупки корабля. Попробуйте позже')
    })


    // buy Spase Ships
    .addCase(buySpaceships.pending, (state) => {
      state.buySpaceshipsStatus = 'loading';
    })
    .addCase(buySpaceships.fulfilled, (state, action) => {
      if (action.payload.error) {
        state.buySpaceshipsStatus = 'failed';
        toast.error(`Ошибка покупки: ${action.payload.error}`)
      } else {
        state.buySpaceshipsStatus = 'success';
      }
    })
    .addCase(buySpaceships.rejected, (state) => {
      state.buySpaceshipsStatus = 'failed';
      toast.error('Ошибка покупки. Попробуйте позже')
    })


    // get Spase Ship Matrix
    .addCase(getSpaceshipMatrix.pending, (state) => {
      state.getSpaceshipMatrixStatus = 'loading';
    })
    .addCase(getSpaceshipMatrix.fulfilled, (state, action) => {
      state.getSpaceshipMatrixStatus = 'success';
      state.lastLoadedMatrix = action.payload;
    })
    .addCase(getSpaceshipMatrix.rejected, (state) => {
      state.getSpaceshipMatrixStatus = 'failed';
      toast.error('Ошибка загрузки флотилий. Попробуйте позже')
    })


    // get races
    .addCase(getRaces.pending, (state) => {
      state.getRacesStatus = 'loading';
    })
    .addCase(getRaces.fulfilled, (state, action) => {
      state.getRacesStatus = 'success';
      state.races = action.payload;
    })
    .addCase(getRaces.rejected, (state) => {
      state.getRacesStatus = 'failed';
      toast.error('Ошибка загрузки рас. Попробуйте позже')
    })


    // get User Spaceships Unplayed
    .addCase(getUserSpaceshipsUnplayed.pending, (state) => {
      state.getUserSpaceshipsUnplayedStatus = 'loading';
    })
    .addCase(getUserSpaceshipsUnplayed.fulfilled, (state, action) => {
      state.getUserSpaceshipsUnplayedStatus = 'success';
      state.spaceshipsForGame = action.payload;
    })
    .addCase(getUserSpaceshipsUnplayed.rejected, (state) => {
      state.getUserSpaceshipsUnplayedStatus = 'failed';
      toast.error('Ошибка загрузки кораблей для игры. Попробуйте позже')
    })


    // get rewards summ
    .addCase(getRewardsSumm.pending, (state) => {
      state.getRewardsSummStatus = 'loading';
    })
    .addCase(getRewardsSumm.fulfilled, (state, action) => {
      state.getRewardsSummStatus = 'success';
      state.rewardsSumm = action.payload;
    })
    .addCase(getRewardsSumm.rejected, (state) => {
      state.getRewardsSummStatus = 'failed';
      toast.error('Ошибка загрузки наград. Попробуйте позже')
    })


    // get done adventures
    .addCase(getAdventureDone.pending, (state) => {
      state.getAdventureDoneStatus = 'loading';
    })
    .addCase(getAdventureDone.fulfilled, (state, action) => {
      state.getAdventureDoneStatus = 'success';
      state.adventuresDone = action.payload;
    })
    .addCase(getAdventureDone.rejected, (state) => {
      state.getAdventureDoneStatus = 'failed';
      toast.error('Ошибка загрузки приключений. Попробуйте позже')
    })


    // get user Spaceships Count
    .addCase(getUserSpaceshipsCount.pending, (state) => {
      state.getUserSpaceshipsCountStatus = 'loading';
    })
    .addCase(getUserSpaceshipsCount.fulfilled, (state, action) => {
      state.getUserSpaceshipsCountStatus = 'success';
      state.currentNumberOfSpaceships = action.payload;
    })
    .addCase(getUserSpaceshipsCount.rejected, (state) => {
      state.getUserSpaceshipsCountStatus = 'failed';
      toast.error('Ошибка загрузки приключений. Попробуйте позже')
    })


    // get user rating
    .addCase(getUserRating.pending, (state) => {
      state.getUserRatingStatus = 'loading';
    })
    .addCase(getUserRating.fulfilled, (state, action) => {
      state.getUserRatingStatus = 'success';
      state.userRating = action.payload;
    })
    .addCase(getUserRating.rejected, (state) => {
      state.getUserRatingStatus = 'failed';
      toast.error('Ошибка загрузки рейтинга. Попробуйте позже')
    })


    // get number of Guild members
    .addCase(getGuildMembersNumber.pending, (state) => {
      state.getGuildMembersNumberStatus = 'loading';
    })
    .addCase(getGuildMembersNumber.fulfilled, (state, action) => {
      state.getGuildMembersNumberStatus = 'success';
      state.guildMembersNumber = action.payload;
    })
    .addCase(getGuildMembersNumber.rejected, (state) => {
      state.getGuildMembersNumberStatus = 'failed';
      toast.error('Ошибка загрузки гильдии. Попробуйте позже')
    })


    // accrue Profit
    .addCase(accrueProfit.pending, (state) => {
      state.accrueProfitStatus = 'loading';
    })
    .addCase(accrueProfit.fulfilled, (state, action) => {
      state.accrueProfitStatus = 'success';
      toast.success('Награда начислена!')
    })
    .addCase(accrueProfit.rejected, (state) => {
      state.accrueProfitStatus = 'failed';
      toast.error('Ошибка начиследния наград. Попробуйте позже')
    })


    // activate Admiral Spaseship
    .addCase(activateSpaseshipForMatrix.pending, (state) => {
      state.activateSpaseshipForMatrixStatus = 'loading';
    })
    .addCase(activateSpaseshipForMatrix.fulfilled, (state, action) => {
      if (action.payload) {
        state.activateSpaseshipForMatrixStatus = 'success';
        toast.success('В бой! Корабль активирован')
      } else {
        state.activateSpaseshipForMatrixStatus = 'failed';
        toast.error('Похоже что что-то пошло не так, ошибка активации корабля :(')
      }
    })
    .addCase(activateSpaseshipForMatrix.rejected, (state) => {
      state.activateSpaseshipForMatrixStatus = 'failed';
      toast.error('Ошибка активации корабля. Попробуйте позже')
    })


    // getUserSpaceshipsWaitingChilds
    .addCase(getUserSpaceshipsWaitingChilds.pending, (state) => {
      state.getUserSpaceshipsWaitingChildsStatus = 'loading';
    })
    .addCase(getUserSpaceshipsWaitingChilds.fulfilled, (state, action) => {
      if (action.payload) {
        state.userSpaceshipsWaitingChilds = action.payload;
        state.getUserSpaceshipsWaitingChildsStatus = 'success';
      }
    })
    .addCase(getUserSpaceshipsWaitingChilds.rejected, (state) => {
      state.getUserSpaceshipsWaitingChildsStatus = 'failed';
      toast.error('Ошибка загрузки счётчиков. Попробуйте позже')
    })


    // get User Child Stats
    .addCase(getUserChildStats.pending, (state) => {
      state.getUserChildStatsStatus = 'loading';
    })
    .addCase(getUserChildStats.fulfilled, (state, action) => {
      if (action.payload) {
        state.userChildStats = action.payload;
        state.getUserChildStatsStatus = 'success';
      }
    })
    .addCase(getUserChildStats.rejected, (state) => {
      state.getUserChildStatsStatus = 'failed';
      toast.error('Ошибка загрузки счётчиков. Попробуйте позже')
    })

  }
  ,
});

const getUserAvatar = createAsyncThunk('nft/getUserNftAvatar',
  async () => {
    const data = await WhiteListRepository.getUserAvatar();
    return data;
  }
);

const createInitAvatar = createAsyncThunk('nft/createInitAvatar',
  async (raceId: string) => {
    const data = await WhiteListRepository.createInitAvatar(raceId);
    return data;
  }
);

const getSpaceshipsForShop = createAsyncThunk('nft/getSpaceshipsForShop',
  async () => {
    const data = await WhiteListRepository.getSpaceshipsForShop();
    return data;
  }
);

const getUserSpaseships = createAsyncThunk('nft/getUserSpaseships',
  async () => {
    const data = await WhiteListRepository.getUserSpaceships();
    return data;
  }
);

const getSpaceshipsForMatriсes = createAsyncThunk('nft/getSpaceshipsForMatriсes',
  async () => {
    const data = await WhiteListRepository.getSpaceshipsForMatriсes();
    return data;
  }
);

const buySpaceship = createAsyncThunk('nft/buySpaceship',
  async (type: string) => {
    const data = await WhiteListRepository.buySpaceship(type);
    return data;
  }
);

const buySpaceships = createAsyncThunk('nft/buySpaceships',
  async (type: string[]) => {
    const data = await WhiteListRepository.buySpaceships(type);
    return data;
  }
);

const getSpaceshipMatrix = createAsyncThunk('nft/getSpaceshipMatrix',
  async (shipId: string) => {
    const data = await WhiteListRepository.getSpaceshipMatrix(shipId);
    return data;
  }
);

const getRaces = createAsyncThunk('nft/getRaces',
  async () => {
    const data = await WhiteListRepository.getRaces();
    return data;
  }
);

const getUserSpaceshipsUnplayed = createAsyncThunk('nft/getUserSpaceshipsUnplayed',
  async () => {
    const data = await WhiteListRepository.getUserSpaceshipsUnplayed();
    return data;
  }
);

const getRewardsSumm = createAsyncThunk('nft/getRewardsSumm',
  async () => {
    const data = await WhiteListRepository.getRewardsSumm();
    return data;
  }
);

const getAdventureDone = createAsyncThunk('nft/getAdventureDone',
  async () => {
    const data = await WhiteListRepository.getAdventureDone();
    return data;
  }
);

const getUserRating = createAsyncThunk('nft/getUserRating',
  async () => {
    const data = await WhiteListRepository.getUserRating();
    return data;
  }
);

const getUserSpaceshipsCount = createAsyncThunk('nft/userSpaceshipsCount',
  async () => {
    const data = await WhiteListRepository.userSpaceshipsCount();
    return data;
  }
);

const getGuildMembersNumber = createAsyncThunk('nft/getGuildMembersNumber',
  async (guildName: string) => {
    const data = await WhiteListRepository.getGuildMembersNumber(guildName);
    return data;
  }
);

const accrueProfit = createAsyncThunk('nft/accrueProfit',
  async (ships: string[]) => {
    const data = await WhiteListRepository.accrueProfit(ships);
    return data;
  }
);

const activateSpaseshipForMatrix = createAsyncThunk('nft/activateSpaseshipForMatrix',
  async (shipId: string) => {
    const data = await WhiteListRepository.activateSpaseshipForMatrix(shipId);
    return data;
  }
);

const getUserSpaceshipsWaitingChilds = createAsyncThunk('nft/getUserSpaceshipsWaitingChilds',
  async (shipType: string) => {
    const data = await WhiteListRepository.getUserSpaceshipsWaitingChilds(shipType);
    return data;
  }
);

const getUserChildStats = createAsyncThunk('nft/getUserChildStats',
  async () => {
    const data = await WhiteListRepository.getUserChildStats();
    return data;
  }
);

const asyncActions = { getUserAvatar, createInitAvatar, getSpaceshipsForShop, getUserSpaseships, buySpaceship, buySpaceships, getSpaceshipsForMatriсes, getSpaceshipMatrix, getRaces, getRewardsSumm, getAdventureDone, getUserSpaceshipsCount, getUserRating, getGuildMembersNumber, getUserSpaceshipsUnplayed, accrueProfit, activateSpaseshipForMatrix, getUserSpaceshipsWaitingChilds, getUserChildStats };
export const actions = { ...nftSlice.actions, ...asyncActions };

export default nftSlice.reducer
