import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { UserRepository } from '../api/UserRepository';
import { User } from './types/types';

export type UserState = {
  authStatus: 'success' | 'initial' | 'loading' | 'failed';
  logoutStatus: 'success' | 'initial' | 'loading' | 'failed';
  updateInviterCodeStatus: 'success' | 'initial' | 'loading' | 'failed';
  isSubscribedToChannelStatus: 'success' | 'initial' | 'loading' | 'failed';
  getMiningRatesStatus: 'success' | 'initial' | 'loading' | 'failed';
  getTokenBalanceStatus: 'success' | 'initial' | 'loading' | 'failed';
  getMiningInfoStatus: 'success' | 'initial' | 'loading' | 'failed';

  currentUser: User | null;
  isUserSubscribed: boolean;

  miningRates: string,
  tokenBalance: string,
  miningCurrentRate: string,

  isRequestLoaderActive: boolean;
}

const initialState: UserState = {
  authStatus: 'initial',
  logoutStatus: 'initial',
  updateInviterCodeStatus: 'initial',
  isSubscribedToChannelStatus: 'initial',
  getMiningRatesStatus: 'initial',
  getTokenBalanceStatus: 'initial',
  getMiningInfoStatus: 'initial',

  currentUser: null,
  isUserSubscribed: false,

  miningRates: '0',
  tokenBalance: '0',
  miningCurrentRate: '0',

  isRequestLoaderActive: true
};

export const mainSlice = createSlice({
  name: 'user',
  initialState,

  //sync
  reducers: {
    clearState: (state) => {
      return initialState
    },

    setAuthStatus: (state, action: PayloadAction<'success' | 'initial' | 'loading' | 'failed'>) => {
      state.authStatus = action.payload
    },

    setReferrerCode: (state, action: PayloadAction<string>) => {
      if (state.currentUser) state.currentUser.referrerCode = action.payload;
    },

    setIsRequestLoaderActive: (state, action: PayloadAction<boolean>) => {
      state.isRequestLoaderActive = action.payload;
    },

  },

  // async
  extraReducers: (builder) => {
    builder

      //logout user
      .addCase(logoutUser.pending, (state) => {
        state.logoutStatus = 'loading';
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.logoutStatus = 'success';
        if (action.payload) state.currentUser = null;
        else toast.error('Ошибка Logout');
      })
      .addCase(logoutUser.rejected, (state) => {
        state.logoutStatus = 'failed';
        toast.error('Ошибка Logout');
      })


      // get current user
      .addCase(getAuthenticatedUser.pending, (state) => {
        state.authStatus = 'loading';
      })
      .addCase(getAuthenticatedUser.fulfilled, (state, action) => {
        if (!action.payload) {
          state.authStatus = 'failed';
          state.currentUser = action.payload;
          state.isRequestLoaderActive = false;
        } else {
          state.authStatus = 'success';
          state.currentUser = action.payload;
        }
      })
      .addCase(getAuthenticatedUser.rejected, (state) => {
        state.authStatus = 'failed';
        state.isRequestLoaderActive = false;
        toast.error('Ошибка авторизации. Попробуйте позже');
      })


      // get update user inviter code
      .addCase(connectUserToReferral.pending, (state) => {
        state.updateInviterCodeStatus = 'loading';
      })
      .addCase(connectUserToReferral.fulfilled, (state, action) => {
        if (!action.payload) {
          state.updateInviterCodeStatus = 'failed';
          toast.error('Неверный промо-код');
        } else {
          if (state.currentUser) {
            state.currentUser.inviterCode = action.payload;
            state.updateInviterCodeStatus = 'success';
          } else {
            state.updateInviterCodeStatus = 'failed';
            toast.error('Ошибка отправки промо-кода. Попробуйте позже');
          }
        }
      })
      .addCase(connectUserToReferral.rejected, (state) => {
        state.updateInviterCodeStatus = 'failed';
        toast.error('Ошибка отправки промо-кода. Попробуйте позже');
      })


      // get is user Subscribed To Channel
      .addCase(isSubscribedToChannel.pending, (state) => {
        state.isSubscribedToChannelStatus = 'loading';
      })
      .addCase(isSubscribedToChannel.fulfilled, (state, action) => {
        state.isUserSubscribed = action.payload;
        state.isSubscribedToChannelStatus = 'success';
      })
      .addCase(isSubscribedToChannel.rejected, (state) => {
        state.isSubscribedToChannelStatus = 'failed';
        toast.error('Ошибка получения информации о подписке');
      })


      // get Mining Rates
      .addCase(getMiningRates.pending, (state) => {
        state.getMiningRatesStatus = 'loading';
      })
      .addCase(getMiningRates.fulfilled, (state, action) => {
        if (action.payload[0].value) state.miningRates = action.payload[0].value;
        else state.miningRates = '0';
        state.getMiningRatesStatus = 'success';
      })
      .addCase(getMiningRates.rejected, (state) => {
        state.getMiningRatesStatus = 'failed';
        toast.error('Ошибка получения скорости добычи');
      })


      // get Mining INFO
      .addCase(getMiningInfo.pending, (state) => {
        state.getMiningInfoStatus = 'loading';
      })
      .addCase(getMiningInfo.fulfilled, (state, action) => {
        if (action.payload?.V) state.miningCurrentRate = action.payload?.V;
        else state.miningCurrentRate = '0';
        state.getMiningInfoStatus = 'success';
      })
      .addCase(getMiningInfo.rejected, (state) => {
        state.getMiningInfoStatus = 'failed';
        toast.error('Ошибка получения текущей скорости добычи');
      })


      // get Token balance
      .addCase(getTokenBalance.pending, (state) => {
        state.getTokenBalanceStatus = 'loading';
      })
      .addCase(getTokenBalance.fulfilled, (state, action) => {
        state.tokenBalance = action.payload;
        state.getTokenBalanceStatus = 'success';
      })
      .addCase(getTokenBalance.rejected, (state) => {
        state.getTokenBalanceStatus = 'failed';
        toast.error('Ошибка получения баланса REDTON');
      })


  },
});

// async functions
const logoutUser = createAsyncThunk('user/logoutUser',
  async () => {
    const user = await UserRepository.logoutUser();
    return user
  }
);

const getAuthenticatedUser = createAsyncThunk('user/getAuthenticatedUser',
  async () => {
    const user = await UserRepository.getAuthenticatedUser();
    return user
  }
);

const connectUserToReferral = createAsyncThunk('user/сonnectUserToReferral',
  async (inviterCode: string) => {
    const code = await UserRepository.connectUserToReferral(inviterCode);
    return code;
  }
)

const isSubscribedToChannel = createAsyncThunk('user/isSubscribedToChannel',
  async () => {
    const code = await UserRepository.isSubscribedToChannel();
    return code;
  }
)

const getMiningRates = createAsyncThunk('user/getMiningRates',
  async () => {
    const code = await UserRepository.getMiningRates();
    return code;
  }
)

const getTokenBalance = createAsyncThunk('user/getTokenBalance',
  async () => {
    const code = await UserRepository.getTokenBalance();
    return code;
  }
)

const getMiningInfo = createAsyncThunk('user/getMiningInfo',
  async () => {
    const code = await UserRepository.getMiningInfo();
    return code;
  }
)

// export data to ui component
export const asyncActions = { logoutUser, getAuthenticatedUser, connectUserToReferral, isSubscribedToChannel, getMiningRates, getTokenBalance, getMiningInfo }
export const actions = { ...mainSlice.actions, ...asyncActions }

// export to main store
export default mainSlice.reducer
