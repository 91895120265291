export class Gql {

  static logoutUser = (): string => {
    return `
      mutation LogoutUser{
        endSession
      }
    `
  }

  static getAuthenticatedUser = (): string => {
    return `
      query GetAuthenticatedUser {
        authenticatedItem {
          ... on User {
            id
            name
            email
            race {
              name
            }
            referrerCode
            inviterCode
            telegram
            telegramId
            referralName
            guild
          }
        }
      }
    `
  }

  static updateUserPromo = (): string => {
    return `
      mutation UpdateUserPromo($promocode:String!) {
        setPromo(promocode:$promocode) {
          isSuccess
          error
        }
      }
    `
  }

  static connectUserToReferral = (): string => {
    return `
    mutation connectUserToReferral($inviterCode: String!) {
      connectUserToReferral(inviterCode: $inviterCode) {
        isConnected
      }
    }
    `
  }

  static getUserAvatar = (): string => {
    return `
      query Avatars {
        avatars {
        id
        name
        url
        createdAt
        }
      }
    `
  }

  static getSpaceshipsForShop = (): string => {
    return `
      query GetSpaseShipsForShop {
        spaceships {
          id
          name
          type
          price
          infoHuman {
            race {
              name
            }
            description
            image {
              url
            }
          }
          infoCyborg {
            race {
              name
            }
            image {
              url
            }
            description
          }
          infoLizard {
            race {
              name
            }
            description
            image {
              url
            }
          }
        }
      }
    `
  }

  static getUserSpaceships = (): string => {
    return `
      query GetUserSpaseShips {
        userSpaceships{
          id
          status
          nft {
            url
          }
          spaceship {
            id
            name
            type
            price
            createdAt
            infoHuman {
              race {
                name
              }
              description
              image {
                url
              }
            }
            infoCyborg {
              race {
                name
              }
              image {
                url
              }
              description
            }
            infoLizard {
              race {
                name
              }
              description
              image {
                url
              }
            }
          }
        }
      }
    `
  }

  static getSpaceshipsForMatriсes = (): string => {
    return `
      query GetUserSpaseShips {
        userSpaceships( where: { OR: [
          { status : { contains: "active" } },
          { status: { contains: "inactive" } }
        ]}) {
          id
          status
          closingDeadline
          waitingDeadline
          nft {
            url
          }
          spaceship {
            id
            name
            type
            price
            createdAt
            infoHuman {
              race {
                name
              }
              description
              image {
                url
              }
            }
            infoCyborg {
              race {
                name
              }
              image {
                url
              }
              description
            }
            infoLizard {
              race {
                name
              }
              description
              image {
                url
              }
            }
          }
        }
      }
    `
  }

  static buySpaceship = (): string => {
    return `
      mutation BuySpaceship($type: String!) {
        buySpaceship(type: $type) {
          error
          result
        }
      }
    `
  }

  static buySpaceships = (): string => {
    return `
    mutation BuySpaceships($types: [String!]) {
      buySpaceships(types: $types) {
        error
        result
        userSpaceshipIds
      }
    }
    `
  }

  static getSpaceshipMatrix = (): string => {
    return `
      query GetSpaceshipMatrix($shipId: ID!) {
        userSpaceshipTree (id: $shipId) {
          level
          userId
          closedAt
          userName
          userAvatar
          telegram
          telegramId
          spaceshipId
          spaceshipParentId
        }
      }
    `
  }

  static activateSpaseshipForMatrix = (): string => {
    return `
      mutation ActivateSpaseshipForMatrix($shipId: ID!) {
        activateUserSpaceship(id: $shipId) {
          isActivated
        }
      }
    `
  }

  static getRaces = (): string => {
    return `
      query GetRaces {
        races {
          id
          name
          description
          type
          image{
            url
          }
        }
      }
    `
  }

  static getUserSpaceshipsUnplayed = (): string => {
    return `
    query GetUserSpaceshipsUnplayed {
      userSpaceshipsUnplayed{
        type
        status
        count
        isPlayed
      }
    }
    `
  }

  static accrueProfit = (): string => {
    return `
    mutation AccrueProfit($ships: [String!]) {
      accrueProfit(types: $ships){
        isAccrue
      }
    }    
    `
  }

  static getRewardsSumm = (): string => {
    return `
    query GetRewardsSumm {
      rewards
    }   
    `
  }

  static getAdventureDone = (): string => {
    return `
    query GetAdventureDone {
      userSpaceshipsCount(
        where: { status: { equals: "closed" }, isPlayed: { equals: true } }
      )
    }    
    `
  }

  static userSpaceshipsCount = (): string => {
    return `
    query UserSpaceshipsCount {
      userSpaceshipsCount
    }    
    `
  }

  static getUserRating = (): string => {
    return `
    query GetUserRating {
      rating{
        value
      }
    }    
    `
  }

  static getGuildMembersNumber = (): string => {
    return `
    query GetGuildMembersNumber($guildName: String!) {
      userCountByGuild (guild: $guildName) {
        count
      }
    }     
    `
  }

  static createInitAvatar = (): string => {
    return `
    mutation CreateInitAvatar($raceId: String!) {
      createInitAvatar(raceId: $raceId) {
        ...on createAvatarResultSuccess {
          url
          name
          referralCode
        }
        ...on createAvatarResultFailure {
          message
        }
      }
    }    
    `
  }

  static getUserBalance = (): string => {
    return `
    query Balance {
      balance
    }
    `
  }

  static getUserTransactions = (): string => {
    return `
    query Transactions($orderBy: [TransactionOrderByInput!]!) {
      transactions(orderBy: $orderBy) {
       amount
       operation
       referenceId
       status
       transactionId
       txHash
       createdAt
      }
    }
    `
  }

  static сreateWithdrawRequest = (): string => {
    return `
    mutation CreateWithdrawRequest($data: WithdrawRequestCreateInput!) {
      createWithdrawRequest(data: $data) {
        id  
      }
    }
    `
  }

  static getDepositAddress = (): string => {
    return `
    query GetDepositAddress {
      depositAddresses {
        address
      }
    }
    `
  }

  static isSubscribedToChannel = (): string => {
    return `
    query IsSubscribedToChannel {
      isSubscribedToChannel
    }
    `
  }

  static getUserSpaceshipsWaitingChilds = (): string => {
    return `
    query GetUserSpaceshipsWaitingChilds($type: String!) {
      userSpaceshipsWaitingChilds(type: $type){
        id
        telegram
        waitingDeadline
      }
    }
    `
  }

  static getMiningRates = (): string => {
    return `
    query MiningRates($orderBy: [MiningRateOrderByInput!]!) {
      miningRates(orderBy: $orderBy) {
       value
       createdAt  
      }
    }
    `
  }

  static getTokenBalance = (): string => {
    return `
    query TokenBalance {
      getTokenBalance
    }
    `
  }

  static getMiningInfo = (): string => {
    return `
    query GetMiningInfo {
      getMiningInfo {
        K
        V  
      }
    }
    `
  }

  static getUserChildStats = (): string => {
    return `
    query UserChildStats {
      userChildStats {
          userId
          userName
          userTelegram
          invited
          gamesPlayed
          freeSpaces {
              name
              first
              second
          }
      }
  }
    `
  }
  
}