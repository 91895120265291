import { SpaceshipsInMatrix } from "../../../logic/types/response/GetSpaceshipMatrix";
import { useAppSelector } from "../../../redux-store";
import getRemainingTime from "../../../utils/get-remaining-time";
import SvgRoundBorder from "./SvgRoundBorder";

type SvgShipMatrix = {
  svgColor: string;
  matrix: SpaceshipsInMatrix[];
};

function ShipMatrix({ svgColor, matrix }: SvgShipMatrix) {

  const { currentShipForMatrix } = useAppSelector((state) => state["nft"]);

  const admiral = matrix.find(
    (item) => item.spaceshipId === currentShipForMatrix.id
  );

  const parent = matrix.find(
    (item) => item.spaceshipId === admiral?.spaceshipParentId
  );

  let lineTwo = matrix.filter((item) => item.spaceshipParentId === admiral?.spaceshipId);

  let lineThreeTop = matrix.filter((item) => item.spaceshipParentId === lineTwo[0]?.spaceshipId);
  let lineThreeBottom = matrix.filter((item) => item.spaceshipParentId === lineTwo[1]?.spaceshipId);

  /* join admiral to matrix */
  // const handleAdmiralJoinToMatrixClick = () => {
  //   if (currentShipForMatrix.id)
  //     dispatch(actions.activateSpaseshipForMatrix(currentShipForMatrix.id));
  //   else
  //     toast.error("Корабль Адмирала не найден. попробуйте обновить страницу");
  // };

  /*  */
  // const handleCopyCodeClick = () => {
  //   navigator.clipboard.writeText(currentUser?.referrerCode ?? "");
  //   toast.success("Промокод для приглашения скопирован!");
  // };

  return (
    <ul className="ships__grid-left-list">
      {/* PARENT SLOT */}
      <li className="ships__grid-left-item red">
        <SvgRoundBorder svgColor={svgColor} />
        {parent && <img src={`${parent.userAvatar}`} alt={parent.telegram} />}
        {parent && (
          <a
            href={`https://t.me/${parent.telegram}`}
            className="ships__grid-left-item-nick"
            target="_blank"
            rel="noreferrer"
          >
            <span className="ships__grid-left-item-nick-icon"></span>
            <span>@{parent.telegram}</span>
          </a>
        )}
      </li>

      {/* ADMIRAL SLOT */}
      <li className="ships__grid-left-item">
        <SvgRoundBorder svgColor={svgColor} />
        {admiral && currentShipForMatrix.status !== "inactive" ? (
          <>
            <img src={`${admiral.userAvatar}`} alt={admiral.telegram} />
            { currentShipForMatrix?.closingDeadline && (
              <span className="ships__grid-timer" title="Оставшееся время на закрытие вашей матрицы">
                {getRemainingTime(currentShipForMatrix?.closingDeadline)} H
              </span>
            )}
            <a
              href={`https://t.me/${admiral.telegram}`}
              className="ships__grid-left-item-nick"
              target="_blank"
              rel="noreferrer"
            >
              <span className="ships__grid-left-item-nick-icon"></span>
              <span>@{admiral.telegram}</span>
            </a>
          </>
        ) : (
          <div className="ships__grid-left-accent"></div>
        )}
      </li>

      {/* TOP LINE 3 SLOT */}
      <li className="ships__grid-left-item">
        <SvgRoundBorder svgColor={svgColor} />
        {lineThreeTop[0] ? (
          <>
            <img
              src={lineThreeTop[0].userAvatar}
              alt={lineThreeTop[0].telegram}
            />
            <a
              href={`https://t.me/${lineThreeTop[0].telegram}`}
              className="ships__grid-left-item-nick"
              target="_blank"
              rel="noreferrer"
            >
              <span className="ships__grid-left-item-nick-icon"></span>
              <span>@{lineThreeTop[0].telegram}</span>
            </a>
          </>
        ) : (
          <div className="ships__grid-left-accent"></div>
        )}
      </li>

      {/* BOTTOM LINE 3 SLOT */}
      <li className="ships__grid-left-item">
        <SvgRoundBorder svgColor={svgColor} />
        {lineThreeBottom[0] ? (
          <>
            <img
              src={`${lineThreeBottom[0].userAvatar}`}
              alt={lineThreeBottom[0].telegram}
            />
            <a
              href={`https://t.me/${lineThreeBottom[0].telegram}`}
              className="ships__grid-left-item-nick"
              target="_blank"
              rel="noreferrer"
            >
              <span className="ships__grid-left-item-nick-icon"></span>
              <span>@{lineThreeBottom[0].telegram}</span>
            </a>
          </>
        ) : (
          <div className="ships__grid-left-accent"></div>
        )}
      </li>

      {/* TOP LINE 2 SLOT */}
      <li className="ships__grid-left-item">
        <SvgRoundBorder svgColor={svgColor} />
        {lineTwo[0] ? (
          <>
            <img src={`${lineTwo[0].userAvatar}`} alt={lineTwo[0].telegram} />
            <a
              href={`https://t.me/${lineTwo[0].telegram}`}
              className="ships__grid-left-item-nick"
              target="_blank"
              rel="noreferrer"
            >
              <span className="ships__grid-left-item-nick-icon"></span>
              <span>@{lineTwo[0].telegram}</span>
            </a>
          </>
        ) : (
          <div className="ships__grid-left-accent"></div>
        )}
      </li>

      {/* BOTTOM LINE 2 SLOT */}
      <li className="ships__grid-left-item">
        <SvgRoundBorder svgColor={svgColor} />
        {lineTwo[1] ? (
          <>
            <img src={`${lineTwo[1].userAvatar}`} alt={lineTwo[1].telegram} />
            <a
              href={`https://t.me/${lineTwo[1].telegram}`}
              className="ships__grid-left-item-nick"
              target="_blank"
              rel="noreferrer"
            >
              <span className="ships__grid-left-item-nick-icon"></span>
              <span>@{lineTwo[1].telegram}</span>
            </a>
          </>
        ) : (
          <div className="ships__grid-left-accent"></div>
        )}
      </li>

      {/* TOP LINE 3 SLOT */}
      <li className="ships__grid-left-item">
        <SvgRoundBorder svgColor={svgColor} />
        {lineThreeTop[1] ? (
          <>
            <img
              src={`${lineThreeTop[1].userAvatar}`}
              alt={lineThreeTop[1].telegram}
            />
            <a
              href={`https://t.me/${lineThreeTop[1].telegram}`}
              className="ships__grid-left-item-nick"
              target="_blank"
              rel="noreferrer"
            >
              <span className="ships__grid-left-item-nick-icon"></span>
              <span>@{lineThreeTop[1].telegram}</span>
            </a>
          </>
        ) : (
          <div className="ships__grid-left-accent"></div>
        )}
      </li>

      {/* BOTTOM LINE 3 SLOT */}
      <li className="ships__grid-left-item">
        <SvgRoundBorder svgColor={svgColor} />
        {lineThreeBottom[1] ? (
          <>
            <img
              src={`${lineThreeBottom[1].userAvatar}`}
              alt={lineThreeBottom[1].telegram}
            />
            <a
              href={`https://t.me/${lineThreeBottom[1].telegram}`}
              className="ships__grid-left-item-nick"
              target="_blank"
              rel="noreferrer"
            >
              <span className="ships__grid-left-item-nick-icon"></span>
              <span>@{lineThreeBottom[1].telegram}</span>
            </a>
          </>
        ) : (
          <div className="ships__grid-left-accent"></div>
        )}
      </li>
    </ul>
  );
}

export default ShipMatrix;
