import { GqlBody } from "../logic/types/request/RequestTypes";
import { BuySpaceship, BuySpaceshipAction, BuySpaceships, BuySpaceshipsResult, DataShips } from "../logic/types/response/BuySpaceship";
import { CreateAvatarResult } from "../logic/types/response/CreateAvatarResult";
import { GetSpaceshipMatrix, SpaceshipsInMatrix} from "../logic/types/response/GetSpaceshipMatrix";
import { GetSpaceshipsForShop  } from "../logic/types/response/GetSpaceshipsForShop";
import { UserChildStat, UserChildStats, UserChildStatsData } from "../logic/types/response/getUserChildStats";
import { Avatar, GetUserAvatar } from "../logic/types/response/GetUserNftAvatar";
import { GetUserSpaceships, UserSpaceships, Spaceship } from "../logic/types/response/GetUserSpaceships";
import { UserSpaceshipsWaiting, UserSpaceshipsWaitingChild } from "../logic/types/response/GetUserSpaceshipsWaitingChilds";
import { Race, Races } from "../logic/types/response/Races";
import { ApiKeystone } from "./api_keystone";
import { Gql } from "./api_queries";

export class WhiteListRepository {
  static keystone = ApiKeystone;

  static async getUserAvatar(): Promise<Avatar[]> {
    const query = Gql.getUserAvatar()
    const body: GqlBody = { operationName: "Avatars", query: query}
    const response: GetUserAvatar = await this.keystone.queryGraphql(body)
    return response.data.avatars ?? null;
  }

  static async getSpaceshipsForShop(): Promise<Spaceship[]> {
    const query = Gql.getSpaceshipsForShop()
    const body: GqlBody = { operationName: "GetSpaseShipsForShop", query: query}
    const response: GetSpaceshipsForShop = await this.keystone.queryGraphql(body)
    return response.data.spaceships ?? []
  }

  static async getUserSpaceships(): Promise<UserSpaceships[]> {
    const query = Gql.getUserSpaceships()
    const body: GqlBody = { operationName: "GetUserSpaseShips", query: query}
    const response: GetUserSpaceships = await this.keystone.queryGraphql(body)
    return response.data.userSpaceships ?? []
  }

  static async getSpaceshipsForMatriсes(): Promise<UserSpaceships[]> {
    const query = Gql.getSpaceshipsForMatriсes()
    const body: GqlBody = { operationName: "GetUserSpaseShips", query: query}
    const response: GetUserSpaceships = await this.keystone.queryGraphql(body)
    return response.data.userSpaceships ?? []
  }

  static async buySpaceship(type: string): Promise<BuySpaceshipAction> {
    const query = Gql.buySpaceship()
    const body: GqlBody = { operationName: "BuySpaceship", query: query, variables: {type}}
    const response: BuySpaceship = await this.keystone.queryGraphql(body)
    return response.data.buySpaceship ?? null
  }

  static async buySpaceships(types: string[]): Promise<BuySpaceshipsResult> {
    const query = Gql.buySpaceships()
    const body: GqlBody = { operationName: "BuySpaceships", query: query, variables: {types}}
    const response: BuySpaceships = await this.keystone.queryGraphql(body)
    return response.data.buySpaceships ?? null
  }

  static async getSpaceshipMatrix(shipId: string): Promise<SpaceshipsInMatrix[]> {
    const query = Gql.getSpaceshipMatrix()
    const body: GqlBody = { operationName: "GetSpaceshipMatrix", query: query, variables: {shipId}}
    const response: GetSpaceshipMatrix = await this.keystone.queryGraphql(body)
    return response.data.userSpaceshipTree ?? null
  }

  static async activateSpaseshipForMatrix(shipId: string): Promise<any> {
    const query = Gql.activateSpaseshipForMatrix()
    const body: GqlBody = { operationName: "ActivateSpaseshipForMatrix", query: query, variables: {shipId}}
    const response: any = await this.keystone.queryGraphql(body)
    return response.data.activateUserSpaceship.isActivated ?? null
  }

  static async getRaces(): Promise<Race[]> {
    const query = Gql.getRaces()
    const body: GqlBody = { operationName: "GetRaces", query: query}
    const response: Races = await this.keystone.queryGraphql(body)
    return response.data.races ?? [];
  }

  static async getUserSpaceshipsUnplayed(): Promise<any> {
    const query = Gql.getUserSpaceshipsUnplayed()
    const body: GqlBody = { operationName: "GetUserSpaceshipsUnplayed", query: query}
    const response: any = await this.keystone.queryGraphql(body)
    return response.data.userSpaceshipsUnplayed ?? [];
  }

  static async getRewardsSumm(): Promise<string> {
    const query = Gql.getRewardsSumm()
    const body: GqlBody = { operationName: "GetRewardsSumm", query: query}
    const response: {data: {rewards: string}} = await this.keystone.queryGraphql(body)
    return response.data.rewards;
  }

  static async getAdventureDone(): Promise<any> {
    const query = Gql.getAdventureDone()
    const body: GqlBody = { operationName: "GetAdventureDone", query: query}
    const response: any = await this.keystone.queryGraphql(body)
    return response.data.userSpaceshipsCount;
  }

  static async userSpaceshipsCount(): Promise<any> {
    const query = Gql.userSpaceshipsCount()
    const body: GqlBody = { operationName: "UserSpaceshipsCount", query: query}
    const response: any = await this.keystone.queryGraphql(body)
    return response.data.userSpaceshipsCount;
  }

  static async getUserRating(): Promise<any> {
    const query = Gql.getUserRating()
    const body: GqlBody = { operationName: "GetUserRating", query: query}
    const response: any = await this.keystone.queryGraphql(body)
    return response.data.rating.value;
  }

  static async getGuildMembersNumber(guildName: string): Promise<any> {
    const query = Gql.getGuildMembersNumber()
    const body: GqlBody = { operationName: "GetGuildMembersNumber", query: query, variables: {guildName}}
    const response: any = await this.keystone.queryGraphql(body)
    return response.data.userCountByGuild.count;
  }

  static async accrueProfit(ships: string[]): Promise<any> {
    const query = Gql.accrueProfit()
    const body: GqlBody = { operationName: "AccrueProfit", query: query, variables: {ships}}
    const response: any = await this.keystone.queryGraphql(body)
    return response.data.accrueProfit.isAccrue;
  }

  static async createInitAvatar(raceId: string): Promise<CreateAvatarResult> {
    const query = Gql.createInitAvatar()
    const body: GqlBody = { operationName: "CreateInitAvatar", query: query, variables: {raceId}}
    const response: CreateAvatarResult = await this.keystone.queryGraphql(body)
    return response;
  }

  static async getUserSpaceshipsWaitingChilds(type: string): Promise<UserSpaceshipsWaitingChild[]> {
    const query = Gql.getUserSpaceshipsWaitingChilds();
    const body: GqlBody = { operationName: "GetUserSpaceshipsWaitingChilds", query: query, variables: {type}}
    const response: UserSpaceshipsWaiting = await this.keystone.queryGraphql(body)
    return response.data.userSpaceshipsWaitingChilds ?? [];
  }

  static async getUserChildStats(): Promise<UserChildStat[]> {
    const query = Gql.getUserChildStats();
    const body: GqlBody = { operationName: "UserChildStats", query: query}
    const response: UserChildStatsData = await this.keystone.queryGraphql(body)
    return response.data.userChildStats ?? [];
  }
}