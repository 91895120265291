import { useEffect } from "react";
import { GradeColors } from "../../../const";
import { actions } from "../../../logic/Nft";
import { useAppDispatch, useAppSelector } from "../../../redux-store";
import SimpleLoader from "../components/SimpleLoader";

function TeamScreen() {

  const dispatch = useAppDispatch();

  const { userChildStats } = useAppSelector(state => state['nft']);
  const { getUserChildStatsStatus } = useAppSelector(state => state['nft']);

  useEffect(() => {
    dispatch(actions.getUserChildStats())
  }, []);


  return ( 
    <section className="team">

      {getUserChildStatsStatus === 'loading' ?
        (<SimpleLoader addClass="ships__sloader" />)
        :
        (
          <>
            {userChildStats.length < 1 ?
              (
                <div className="team__container">
                  <p>Данных пока нет, <br /> приглашайте больше игроков в свою команду!</p>
                </div>
              )
              :
              (
                <div className="team__container">
                  <table className="team__table">
                    <tbody>
                      <tr className="team__trPC">
                        <td>Имя пользователя</td>
                        <td>Приглашенные игроки</td>
                        <td>Сыграно в T-HEX</td>
                        <td>Корабль Торговый</td>
                        <td>Корабль Истребитель</td>
                        <td>Корабль Штурмовой</td>
                        <td>Корабль Крейсер</td>
                        <td>Корабль Дредноут</td>
                        <td>Корабль Разрушитель</td>
                      </tr>
                      <tr className="team__trMob">
                        <td>Имя игрока</td>
                        <td>Пригл. игроки</td>
                        <td>Игр</td>
                        <td><span className="team__square" style={{backgroundColor: GradeColors['Торговый'] , color: "#121212"}}>Т</span></td>
                        <td><span className="team__square" style={{backgroundColor: GradeColors['Истребитель'], color: "#FFF"}}>И</span></td>
                        <td><span className="team__square" style={{backgroundColor: GradeColors['Штурмовой'], color: "#FFF"}}>Ш</span></td>
                        <td><span className="team__square" style={{backgroundColor: GradeColors['Крейсер'], color: "#FFF"}}>К</span></td>
                        <td><span className="team__square" style={{backgroundColor: GradeColors['Дредноут'], color: "#FFF"}}>Д</span></td>
                        <td><span className="team__square" style={{backgroundColor: GradeColors['Разрушитель'], color: "#121212"}}>Р</span></td>
                      </tr>
                      {userChildStats.map((child) => (
                        <tr>
                          <td>{child.userTelegram === 'TPLANET***' || child.userTelegram === 'TPLANET****' ?
                            <span>{child.userName.replace(/undefined/ig, '')}</span>
                            :
                            <a href={`https://t.me/${child.userTelegram}`} target='_blank' rel="noreferrer">@{child.userTelegram}</a>}
                          </td>
                          <td>{child.invited}</td>
                          <td>{child.gamesPlayed}</td>
                          <td>
                            {(`${child.freeSpaces.find(item => item.name === 'Корабль Торговый')?.first}|${child.freeSpaces.find(item => item.name === 'Корабль Торговый')?.second}`).replaceAll(/null/ig, '-').replace('-|-', '-')}
                          </td>
                          <td>
                            {(`${child.freeSpaces.find(item => item.name === 'Корабль Истребитель')?.first}|${child.freeSpaces.find(item => item.name === 'Корабль Истребитель')?.second}`).replaceAll(/null/ig, '-').replace('-|-', '-')}
                          </td>
                          <td>
                            {(`${child.freeSpaces.find(item => item.name === 'Корабль Штурмовой')?.first}|${child.freeSpaces.find(item => item.name === 'Корабль Штурмовой')?.second}`).replaceAll(/null/ig, '-').replace('-|-', '-')}
                          </td>
                          <td>
                            {(`${child.freeSpaces.find(item => item.name === 'Корабль Крейсер')?.first}|${child.freeSpaces.find(item => item.name === 'Корабль Крейсер')?.second}`).replaceAll(/null/ig, '-').replace('-|-', '-')}
                          </td>
                          <td>
                            {(`${child.freeSpaces.find(item => item.name === 'Корабль Дредноут')?.first}|${child.freeSpaces.find(item => item.name === 'Корабль Дредноут')?.second}`).replaceAll(/null/ig, '-').replace('-|-', '-')}
                          </td>
                          <td>
                            {(`${child.freeSpaces.find(item => item.name === 'Корабль Разрушитель')?.first}|${child.freeSpaces.find(item => item.name === 'Корабль Разрушитель')?.second}`).replaceAll(/null/ig, '-').replace('-|-', '-')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            }
          </>
        )}
    </section>
  );
}

export default TeamScreen;