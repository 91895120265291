import { useEffect } from "react";
import { actions } from "../../../logic/Main";
import { actions as userActions } from "../../../logic/User";
import { useAppDispatch, useAppSelector } from "../../../redux-store";

function Navigation() {

	const dispatch = useAppDispatch();

  const { currentScreen } = useAppSelector(state => state['personal'])
  const { isNavigationOpened } = useAppSelector(state => state['personal'])
	const { logoutStatus } = useAppSelector(state => state['user']);

	const closeNavigation = () => {dispatch(actions.setIsNavigationOpened(false))}

	const handleNavigationItemClick = (screen: 'guild' | 'inventory' | 'store' | 'home' | 'wallet' | 'game' | 'data' | 'team') => {
    localStorage.setItem('lastCurrentScreen', screen);
		dispatch(actions.setCurrentScreen(screen));
		closeNavigation();
	}

	useEffect(() => {
		if (isNavigationOpened) {
			document.body.style.position = 'fixed';
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.position = '';
			document.body.style.overflow = '';
		}
	}, [isNavigationOpened])
	

	const handleLogoutClick = () => {
		dispatch(userActions.logoutUser());
	}

	useEffect(() => {
		if (logoutStatus === 'success') {
			window.location.reload();
		}
	}, [logoutStatus])

  return(
		<div className={`nav mobile-custom-scroll ${isNavigationOpened ? 'active' : ''}`}>

			<button className="nav__mobile-close button-close" onClick={closeNavigation}></button>

			<header className="nav__header">
				<picture className="nav__header-logo">
					<img src="img/logo2.png" alt="LOGO" />
				</picture>
				<img src="img/tplanet.svg" alt="T-PLANET" />
			</header>

			<h2 className="nav__title">Меню</h2>

			<nav className="nav__nav">
				<ul className="nav__nav-list">

					<li className="nav__nav-item">
						<button className={`nav__nav-btn ${currentScreen === 'home' ? 'active' : ''}`} onClick={() => {handleNavigationItemClick('home')}}>
							<svg width="22" height="23" className="svg-home" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M8.15722 20.2714V17.2047C8.1572 16.4246 8.79312 15.7908 9.58101 15.7856H12.4671C13.2587 15.7856 13.9005 16.4209 13.9005 17.2047V20.2809C13.9003 20.9432 14.4343 21.4845 15.103 21.5H17.0271C18.9451 21.5 20.5 19.9607 20.5 18.0618V9.33784C20.4898 8.59083 20.1355 7.88935 19.538 7.43303L12.9577 2.1853C11.8049 1.27157 10.1662 1.27157 9.01342 2.1853L2.46203 7.44256C1.86226 7.89702 1.50739 8.59967 1.5 9.34736V18.0618C1.5 19.9607 3.05488 21.5 4.97291 21.5H6.89696C7.58235 21.5 8.13797 20.9499 8.13797 20.2714" fill="white"/>
								<path d="M8.15722 20.2714V17.2047C8.1572 16.4246 8.79312 15.7908 9.58101 15.7856H12.4671C13.2587 15.7856 13.9005 16.4209 13.9005 17.2047V17.2047V20.2809C13.9003 20.9432 14.4343 21.4845 15.103 21.5H17.0271C18.9451 21.5 20.5 19.9607 20.5 18.0618V18.0618V9.33784C20.4898 8.59083 20.1355 7.88935 19.538 7.43303L12.9577 2.1853C11.8049 1.27157 10.1662 1.27157 9.01342 2.1853L2.46203 7.44256C1.86226 7.89702 1.50739 8.59967 1.5 9.34736V18.0618C1.5 19.9607 3.05488 21.5 4.97291 21.5H6.89696C7.58235 21.5 8.13797 20.9499 8.13797 20.2714V20.2714" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
							<span>Обзор</span>
						</button>
					</li>

					<li className="nav__nav-item">
						<button className={`nav__nav-btn ${currentScreen === 'wallet' ? 'active' : ''}`} onClick={() => {handleNavigationItemClick('wallet')}}>
							<svg width="22" height="23" className="svg-wallet" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7.84819 11.814V15.559" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M9.75912 13.6868H5.93799" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M14.3661 11.928H14.259" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M16.1795 15.5027H16.0725" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M7.07227 1.5C7.07227 2.24048 7.68475 2.84076 8.44029 2.84076H9.49676C10.6624 2.84492 11.6065 3.77026 11.6118 4.91266V5.58771" stroke="#2ea3ab" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path fillRule="evenodd" clipRule="evenodd" d="M15.4283 21.4625C12.4231 21.5134 9.47303 21.5113 6.57275 21.4625C3.3535 21.4625 1 19.1663 1 16.0112V11.3616C1 8.20651 3.3535 5.91029 6.57275 5.91029C9.48893 5.86044 12.4411 5.86148 15.4283 5.91029C18.6476 5.91029 21 8.20755 21 11.3616V16.0112C21 19.1663 18.6476 21.4625 15.4283 21.4625Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
							<span>Мой кошелёк</span>
						</button>
					</li>

					<li className="nav__nav-item">
						<button className={`nav__nav-btn ${currentScreen === 'inventory' ? 'active' : ''}`} onClick={() => {handleNavigationItemClick('inventory')}}>
							<svg clipRule="evenodd" className="svg-inventory" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
								<path fill="#ffffff" d="m8 16h-5v4c0 .621.52 1 1 1h4zm6.6 5v-5h-5.2v5zm6.4-5h-5v5h4c.478 0 1-.379 1-1zm0-1.4v-5.2h-5v5.2zm-18-5.2v5.2h5v-5.2zm11.6 0h-5.2v5.2h5.2zm1.4-6.4v5h5v-4c0-.478-.379-1-1-1zm-8 5v-5h-4c-.62 0-1 .519-1 1v4zm6.6-5h-5.2v5h5.2z" fillRule="nonzero"/>
							</svg>
							<span>Инвентарь</span>
						</button>
					</li>

					<li className="nav__nav-item">
						<button className={`nav__nav-btn ${currentScreen === 'store' ? 'active' : ''}`} onClick={() => {handleNavigationItemClick('store')}}>
							<svg width="21" height="22" className="svg-store" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M14.5139 21H6.16604C3.09968 21 0.747274 19.8925 1.41547 15.4348L2.1935 9.39363C2.6054 7.16937 4.02416 6.31812 5.26901 6.31812H15.4475C16.7107 6.31812 18.047 7.23345 18.523 9.39363L19.3011 15.4348C19.8686 19.3891 17.5802 21 14.5139 21Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M14.6512 6.09848C14.6512 3.71241 12.7169 1.77812 10.3309 1.77812V1.77812C9.18186 1.77325 8.07825 2.22628 7.26406 3.03703C6.44987 3.84778 5.99218 4.94947 5.99219 6.09848H5.99219" stroke="#2ea3ab" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M13.2965 10.602H13.2507" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M7.4659 10.602H7.42013" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>								
							<span>Магазин</span>
						</button>
					</li>

					<li className="nav__nav-item">
						<button className={`nav__nav-btn ${currentScreen === 'team' ? 'active' : ''}`} onClick={() => {handleNavigationItemClick('team')}}>
							<svg className="svg-team" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" clip-rule="evenodd" d="M6.72 20.492C8.252 21.448 10.062 22 12 22C13.934 22 15.741 21.45 17.272 20.497L18.512 22.079C16.636 23.294 14.4 24 12 24C9.597 24 7.358 23.292 5.48 22.074L6.72 20.492ZM1.403 19.162H0C0 18.058 0.895 17.162 2 17.162H4C4.53 17.162 5.039 17.373 5.414 17.748C5.789 18.123 6 18.632 6 19.162H4.597C4.401 18.556 3.842 18.436 3 18.436C2.158 18.436 1.6 18.551 1.403 19.162ZM19.403 19H18C18 17.895 18.895 17 20 17H22C22.53 17 23.039 17.211 23.414 17.586C23.789 17.961 24 18.469 24 19H22.597C22.401 18.394 21.842 18.274 21 18.274C20.158 18.274 19.6 18.389 19.403 19ZM3 12.162C4.242 12.162 5.25 13.17 5.25 14.412C5.25 15.654 4.242 16.662 3 16.662C1.758 16.662 0.75 15.654 0.75 14.412C0.75 13.17 1.758 12.162 3 12.162ZM21 12C22.242 12 23.25 13.008 23.25 14.25C23.25 15.492 22.242 16.5 21 16.5C19.758 16.5 18.75 15.492 18.75 14.25C18.75 13.008 19.758 12 21 12ZM3 13.512C3.497 13.512 3.9 13.915 3.9 14.412C3.9 14.909 3.497 15.312 3 15.312C2.503 15.312 2.1 14.909 2.1 14.412C2.1 13.915 2.503 13.512 3 13.512ZM21 13.35C21.497 13.35 21.9 13.753 21.9 14.25C21.9 14.747 21.497 15.15 21 15.15C20.503 15.15 20.1 14.747 20.1 14.25C20.1 13.753 20.503 13.35 21 13.35ZM8 0.684V2.833C5.062 4.118 2.859 6.775 2.202 9.991L0.168 9.988C0.9 5.66 3.953 2.116 8 0.684ZM16 0.684C20.047 2.116 23.1 5.66 23.832 9.988L21.798 9.991C21.141 6.775 18.938 4.118 16 2.833V0.684ZM10.403 7H9C9 5.895 9.895 5 11 5H13C13.53 5 14.039 5.211 14.414 5.586C14.789 5.961 15 6.469 15 7H13.597C13.401 6.394 12.842 6.274 12 6.274C11.158 6.274 10.6 6.389 10.403 7ZM12 0C13.242 0 14.25 1.008 14.25 2.25C14.25 3.492 13.242 4.5 12 4.5C10.758 4.5 9.75 3.492 9.75 2.25C9.75 1.008 10.758 0 12 0ZM12 1.35C12.497 1.35 12.9 1.753 12.9 2.25C12.9 2.747 12.497 3.15 12 3.15C11.503 3.15 11.1 2.747 11.1 2.25C11.1 1.753 11.503 1.35 12 1.35Z" fill="white"/>
							</svg>
							<span>Моя команда</span>
						</button>
					</li>

					<li className="nav__nav-item">
						<button className={`nav__nav-btn ${currentScreen === 'guild' ? 'active' : ''}`} onClick={() => {handleNavigationItemClick('guild')}}>
							<svg width="24" height="19" className="svg-guild" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M17.5947 8.43189C19.1971 8.43189 20.4969 7.13298 20.4969 5.53064C20.4969 3.92831 19.1971 2.62939 17.5947 2.62939" stroke="#2ea3ab" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M18.9287 11.5847C19.4072 11.6177 19.883 11.6856 20.3514 11.791C21.0022 11.9184 21.785 12.1851 22.0637 12.7691C22.2415 13.1431 22.2415 13.5785 22.0637 13.9534C21.786 14.5373 21.0022 14.8031 20.3514 14.937" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M6.28986 8.43189C4.68753 8.43189 3.3877 7.13298 3.3877 5.53064C3.3877 3.92831 4.68753 2.62939 6.28986 2.62939" stroke="#2ea3ab" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M4.95565 11.5847C4.47715 11.6177 4.0014 11.6856 3.53298 11.791C2.88215 11.9184 2.09931 12.1851 1.82156 12.7691C1.64281 13.1431 1.64281 13.5785 1.82156 13.9534C2.0984 14.5373 2.88215 14.8031 3.53298 14.937" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path fillRule="evenodd" clipRule="evenodd" d="M11.9377 12.2095C15.1845 12.2095 17.9583 12.7008 17.9583 14.6671C17.9583 16.6324 15.2028 17.1421 11.9377 17.1421C8.68991 17.1421 5.91699 16.6507 5.91699 14.6845C5.91699 12.7182 8.67249 12.2095 11.9377 12.2095Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path fillRule="evenodd" clipRule="evenodd" d="M11.9378 9.40492C9.79651 9.40492 8.07959 7.688 8.07959 5.54575C8.07959 3.40442 9.79651 1.6875 11.9378 1.6875C14.0792 1.6875 15.7961 3.40442 15.7961 5.54575C15.7961 7.688 14.0792 9.40492 11.9378 9.40492Z" stroke="#2ea3ab" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
							<span>Гильдия</span>
						</button>
					</li>

					<li className="nav__nav-item">
						<button className={`nav__nav-btn ${currentScreen === 'data' ? 'active' : ''}`} onClick={() => {handleNavigationItemClick('data')}}>
							<svg className="svg-whitepaper" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff"><path d="M21.312 12.644c-.972-1.189-3.646-4.212-4.596-5.284l-1.785 1.018 4.658 5.35c.622.692.458 1.704-.376 2.239-.773.497-5.341 3.376-6.386 4.035-.074-.721-.358-1.391-.826-1.948s-6.114-7.376-7.523-9.178c-.471-.601-.576-1.246-.297-1.817.268-.549.842-.918 1.431-.918.918 0 1.408.655 1.548 1.215.161.641-.035 1.231-.623 1.685l1.329 1.624 7.795-4.446c1.423-1.051 1.822-2.991.93-4.513-.616-1.053-1.757-1.706-2.975-1.706-1.188 0-.793-.016-9.566 4.475-1.233.591-2.05 1.787-2.05 3.202 0 .87.308 1.756.889 2.487 1.426 1.794 7.56 9.185 7.616 9.257.371.493.427 1.119.15 1.673-.277.555-.812.886-1.429.886-.919 0-1.409-.655-1.549-1.216-.157-.629.011-1.208.604-1.654l-1.277-1.545c-.823.665-1.278 1.496-1.377 2.442-.233 2.205 1.525 3.993 3.614 3.993.595 0 1.311-.177 1.84-.51l9.427-5.946c.957-.664 1.492-1.781 1.492-2.897 0-.744-.24-1.454-.688-2.003zm-8.293-10.492c.188-.087.399-.134.609-.134.533 0 .998.281 1.244.752.312.596.225 1.469-.547 1.912l-5.099 2.888c-.05-1.089-.578-2.081-1.454-2.732l5.247-2.686z"/></svg>
							<span>База знаний</span>
						</button>
					</li>

				</ul>
			</nav>

			{ /* Топ гильдий пока спрятан */ }
			{/* <h2 className="nav__title">
				Top guilds
				<button>See all</button>
			</h2>

			<ul className="nav__nav-gi-list">
				<li className="nav__nav-gi-item">
					<span>1</span>
					Чемпионсы
				</li>
				<li className="nav__nav-gi-item">
					<span>2</span>
					Hakuna matata
				</li>
				<li className="nav__nav-gi-item">
					<span>3</span>
					Мы у мамы воены
				</li>
				<li className="nav__nav-gi-item">
					<span>4</span>
					Позавчера
				</li>
				<li className="nav__nav-gi-item">
					<span>5</span>
					I'll be back
				</li>
			</ul> */}

			<div className="nav__nav-bottom">
				{/* кнопка настроек - пока спрятана */}
				{/* <button className="nav__nav-bottom-btn">
					<svg width="21" height="22" className="svg-settings" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path fillRule="evenodd" clipRule="evenodd" d="M18.8064 6.62361L18.184 5.54352C17.6574 4.6296 16.4905 4.31432 15.5753 4.83872V4.83872C15.1397 5.09534 14.6198 5.16815 14.1305 5.04109C13.6411 4.91402 13.2224 4.59752 12.9666 4.16137C12.8021 3.88415 12.7137 3.56839 12.7103 3.24604V3.24604C12.7251 2.72922 12.5302 2.2284 12.1698 1.85767C11.8094 1.48694 11.3143 1.27786 10.7973 1.27808H9.54326C9.03672 1.27807 8.55107 1.47991 8.19376 1.83895C7.83644 2.19798 7.63693 2.68459 7.63937 3.19112V3.19112C7.62435 4.23693 6.77224 5.07681 5.72632 5.0767C5.40397 5.07336 5.08821 4.98494 4.81099 4.82041V4.82041C3.89582 4.29601 2.72887 4.61129 2.20229 5.52522L1.5341 6.62361C1.00817 7.53639 1.31916 8.70261 2.22975 9.23231V9.23231C2.82166 9.57404 3.18629 10.2056 3.18629 10.8891C3.18629 11.5725 2.82166 12.2041 2.22975 12.5458V12.5458C1.32031 13.0719 1.00898 14.2353 1.5341 15.1454V15.1454L2.16568 16.2346C2.4124 16.6798 2.82636 17.0083 3.31595 17.1474C3.80554 17.2866 4.3304 17.2249 4.77438 16.976V16.976C5.21084 16.7213 5.73094 16.6516 6.2191 16.7822C6.70725 16.9128 7.12299 17.233 7.37392 17.6717C7.53845 17.9489 7.62686 18.2646 7.63021 18.587V18.587C7.63021 19.6435 8.48671 20.5 9.54326 20.5H10.7973C11.8502 20.5001 12.7053 19.6491 12.7103 18.5962V18.5962C12.7079 18.088 12.9086 17.6 13.2679 17.2407C13.6272 16.8814 14.1152 16.6807 14.6233 16.6831C14.9449 16.6917 15.2594 16.7798 15.5387 16.9394V16.9394C16.4515 17.4653 17.6177 17.1544 18.1474 16.2438V16.2438L18.8064 15.1454C19.0615 14.7075 19.1315 14.186 19.001 13.6964C18.8704 13.2067 18.55 12.7894 18.1108 12.5367V12.5367C17.6715 12.284 17.3511 11.8666 17.2206 11.3769C17.09 10.8873 17.16 10.3658 17.4151 9.92796C17.581 9.63834 17.8211 9.3982 18.1108 9.23231V9.23231C19.0159 8.70289 19.3262 7.54349 18.8064 6.63277V6.63277V6.62361Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<ellipse cx="10.1747" cy="10.8891" rx="2.63616" ry="2.63616" stroke="#2ea3ab" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
					<span>Settings</span>
				</button> */}
				<button className="nav__nav-bottom-btn" onClick={handleLogoutClick}>
					<svg width="22" height="22" className="svg-logout" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M14.016 6.38948V5.45648C14.016 3.42148 12.366 1.77148 10.331 1.77148H5.45597C3.42197 1.77148 1.77197 3.42148 1.77197 5.45648V16.5865C1.77197 18.6215 3.42197 20.2715 5.45597 20.2715H10.341C12.37 20.2715 14.016 18.6265 14.016 16.5975V15.6545" stroke="#2ea3ab" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M20.8096 11.0215H8.76855" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M17.8813 8.1062L20.8093 11.0212L17.8813 13.9372" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
					<span>{ logoutStatus === 'loading' ? 'В процессе...' : 'Выход' }</span>
				</button>
			</div>
		</div>
  )
}

export default Navigation;