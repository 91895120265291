import { UserTransaction } from "../../../logic/types/response/GetUserTransactions";
import transferDate from "../../../utils/transferDate";

type TransactionTableRowProps = {
  trans: UserTransaction
}

function TransactionTableRow({trans} :TransactionTableRowProps) {
  return ( 
    <li className="wallet__rewards-item">
      <span>{trans.operation === 'bonus' ? 'T-HEX game +' : `${trans.operation} ${trans.status}:`} {trans.amount} TON</span>
      <div className="dash-line"></div>
      <span>{transferDate(trans.createdAt)}</span>
  </li>
   );
}

export default TransactionTableRow;