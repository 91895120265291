import { UserSpaceshipsWaitingChild } from "../../../logic/types/response/GetUserSpaceshipsWaitingChilds";
import getRemainingTime from "../../../utils/get-remaining-time";

type TimersModalType = {
  isOpened: boolean,
  onClose: () => void,
  childs: UserSpaceshipsWaitingChild[];
}

function TimersModal({isOpened, onClose, childs}: TimersModalType) {
  return ( 
    <div className={`ships__timersModal ${isOpened ? 'active' : ''}`}>
    <button
      onClick={onClose}
      className="ships__timersClose button-close"
      >
    </button>
    <h2>Игроки, ожидающие закрытие вашей матрицы:</h2>
    <ul className="ships__timersList custom-scroll">
      {childs.map((child) => (
        <li>
          <a href={`https://t.me/${child.telegram}`} target="_blank" rel="noopener noreferrer" className="ships__timersLink">
            <span className="ships__grid-left-item-nick-icon"></span>
            {child.telegram}
          </a>
          <div className="dash-line"></div>
          {getRemainingTime(child.waitingDeadline) ? <span>{`осталось ${getRemainingTime(child.waitingDeadline)}`}</span> : <span style={{color: 'tomato'}}>время вышло</span>}
        </li>
      ))}
    </ul>
  </div>
   );
}

export default TimersModal;