import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Ships } from '../const';


export type GuildState = {
  selectedFilter: keyof typeof Ships | null
  isFirstFiltersLoad: boolean,
}

const initialState: GuildState = {
  selectedFilter: null,
  isFirstFiltersLoad: true,
};

export const guildSlice = createSlice({
  name: 'guild',
  initialState,

  //sync
  reducers: {

    clearState: (state) => {
      return initialState
    },

    setSelectedFilter: (state, action: PayloadAction<keyof typeof Ships | null>) => {
      state.selectedFilter = action.payload
    },

    setIsFirstFiltersLoad: (state, action: PayloadAction<boolean>) => {
      state.isFirstFiltersLoad = action.payload
    },

  },

  // async
  extraReducers: (builder) => {
  },
});

export const actions = guildSlice.actions

export default guildSlice.reducer
