import { QRCodeSVG } from "qrcode.react";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../redux-store";
import SimpleLoader from "../components/SimpleLoader";

type WalletQrCodeModalType = {
  currentWalletWindow: "none" | "deposit-qr" | "transfer-adress" | "message" | "ton" | "message-transfer",
  setCurrentWalletWindow: (value: React.SetStateAction<"none" | "deposit-qr" | "transfer-adress" | "message" | "ton" | "message-transfer">) => void,
  getDepositAddressStatus: "initial" | "success" | "loading" | "failed",
}

function WalletQrCodeModal({currentWalletWindow, setCurrentWalletWindow, getDepositAddressStatus} : WalletQrCodeModalType) {

  const { userDepositAddress } = useAppSelector(state => state['wallet']);

  /* копирование адреса */
  const handleUserDepositAddressClick = () => {
    navigator.clipboard.writeText(userDepositAddress ?? "");
    toast.success('TON адрес для получения платежа скопирован!')
  }

  return ( 
    <div className={`wallet__mdeposit ${currentWalletWindow === 'deposit-qr' ? 'active' : ''} `}>

    <div className="wallet__mdeposit-top">
      <h2>Deposit:</h2>
      <button className="button-close" onClick={() => {setCurrentWalletWindow('none')}}></button>
    </div>

    <div className="wallet__mdeposit-wrapper">
      
      {getDepositAddressStatus === 'loading' ?
       (<SimpleLoader addClass={""} />)
       :
       (<>
          {userDepositAddress &&
            (<div className="wallet__mdeposit-svg-container">
              <QRCodeSVG value={userDepositAddress || ""}
                style={{ background: 'white', padding: '16px'}}
              />
              <p>Отсканируйте адрес для получения платежа</p>
            </div>)
          }

          <h3 className="wallet__mdeposit-colored-title">TON адрес для получения платежа</h3>
          <p className="wallet__mdeposit-adress-key">{userDepositAddress}</p>

          <h3 className="wallet__mdeposit-colored-title">Network</h3>
          <p>The Open Network - TON</p>

          <button
          onClick={handleUserDepositAddressClick}
          className="button-common"
          >
            Копировать адрес
          </button>
        </>)
      }
    </div>
  </div>
   );
}

export default WalletQrCodeModal;