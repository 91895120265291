import { useAppSelector } from "../../../redux-store";

type WalletUserBalanceBlockType = {
  userBalance: string
}

function WalletUserBalanceBlock({userBalance}: WalletUserBalanceBlockType) {

  const { getUserBalanceStatus } = useAppSelector(state => state['wallet']);

  return ( 
    <div className="wallet__balance-bott">
    <ul className="wallet__balance-bott-list">
      <li className="wallet__balance-bott-item">
        <img src="img/ton.svg" alt="" />
        <span>TON</span>
        <div className="dash-line"></div>
        {getUserBalanceStatus === 'loading' ? 
          (<div className="lds-dual-ring-sm"></div>)
          :
          (<span>{Number(userBalance).toFixed(2)}</span>)
        }
      </li>
    </ul>
  </div>
   );
}

export default WalletUserBalanceBlock;