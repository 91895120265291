import { User } from "../../../logic/types/types";
import { useAppSelector } from "../../../redux-store";

type WalletAvatarProps = {
  currentUser: User | null
}

function WalletAvatar({currentUser} : WalletAvatarProps) {

  const { isPremAvatar } = useAppSelector(state => state['nft']);
  const { nftAvatarUrl } = useAppSelector(state => state['nft']);

  return (
    <div className="wallet__ava-container">
      
      <h1 className="wallet__title">NFT Avatar:</h1>

      {/* nft avatar */}
      <div className={`wallet__ava hero-card__item-btn hero-card__item-btn--big decorBorder ${isPremAvatar ? "hero-card__item-btn--prem" : ""}`}>
        <div className="hero-card__item-picContainer">
          <picture className="hero-card__item-pic">
            <img src={nftAvatarUrl} alt="player nft avatar" className="no-hover" />
          </picture>
          {isPremAvatar && (
            <img 
              src="img/prem-border-pc-main.avif"
              alt="premium avatar border"
              className="hero-card__item-picBorder no-hover"
            />
          )}
        </div>
        {isPremAvatar && (
          <span className="hero-card__item-premRace">
            {currentUser?.race?.name} <br /> PREMIUM
          </span>
        )}
        {!isPremAvatar && (
          <>
            <span className="hero-card__item-btnRace">
              {currentUser?.race?.name}
            </span>
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </>
        )}
      </div>
    </div>
  );
}

export default WalletAvatar;
