import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux-store';
import './assets/css/style.css';

import AppWrapper from './App';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  //<React.StrictMode>
    <Provider store={store}>
      <AppWrapper />
    </Provider>
  //</React.StrictMode>
);
