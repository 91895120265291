function WalletAvatarControls() {
  return ( 
    <div className="wallet__balance-btns">
      <button className="wallet__balance-btn disabled">
        <svg xmlns="http://www.w3.org/2000/svg" className="svg-creditcard wallet__svg2" viewBox="0 0 512 512"><title>payment website</title>
          <path strokeWidth={1.2} d="M455.65,83.75H56.35a35.16,35.16,0,0,0-35.11,35.12V393.13a35.16,35.16,0,0,0,35.11,35.12h399.3a35.16,35.16,0,0,0,35.11-35.12V180.8a5.74,5.74,0,0,0-11.47,0V393.13a23.68,23.68,0,0,1-23.64,23.65H56.35a23.68,23.68,0,0,1-23.64-23.65V118.87A23.68,23.68,0,0,1,56.35,95.22h399.3a23.68,23.68,0,0,1,23.64,23.65v30.54H53.16a5.74,5.74,0,1,0,0,11.47H485a5.73,5.73,0,0,0,5.74-5.73V118.87A35.16,35.16,0,0,0,455.65,83.75Z" fill="#38C7D0"/>
          <path strokeWidth={1.2} d="M432.06,127.61a5.68,5.68,0,0,0,2.2.44,6,6,0,0,0,1.11-.11,5.85,5.85,0,0,0,1.08-.33,5.27,5.27,0,0,0,1-.53,5.73,5.73,0,0,0,.87-8.82,6.58,6.58,0,0,0-.87-.71,5.86,5.86,0,0,0-1-.52,5.42,5.42,0,0,0-1.08-.33,5.74,5.74,0,0,0-5.17,1.56,5.69,5.69,0,0,0,.87,8.82A5.27,5.27,0,0,0,432.06,127.61Z" fill="#38C7D0"/>
          <path strokeWidth={1.2} d="M402.4,127.94a5.53,5.53,0,0,0,1.07-.33,5.19,5.19,0,0,0,1-.52,5.58,5.58,0,0,0,.88-.71,5.84,5.84,0,0,0,1.68-4.06,4.74,4.74,0,0,0-.12-1.12,5.53,5.53,0,0,0-.33-1.07,5.78,5.78,0,0,0-.51-1,6.89,6.89,0,0,0-.72-.88,5.58,5.58,0,0,0-.88-.71,5.19,5.19,0,0,0-1-.52,5.53,5.53,0,0,0-1.07-.33,5.29,5.29,0,0,0-2.24,0,5.53,5.53,0,0,0-1.07.33,5.19,5.19,0,0,0-1,.52,6.07,6.07,0,0,0-.88.71,5.73,5.73,0,0,0,4.06,9.79A6.07,6.07,0,0,0,402.4,127.94Z" fill="#38C7D0"/>
          <path strokeWidth={1.2} d="M366.11,127.61a5.63,5.63,0,0,0,1.08.33,6,6,0,0,0,1.11.11,5.73,5.73,0,0,0,4.06-9.79,6,6,0,0,0-.87-.71,5.86,5.86,0,0,0-1-.52,4.5,4.5,0,0,0-1.08-.33,5.24,5.24,0,0,0-2.23,0,4.5,4.5,0,0,0-1.08.33,5.86,5.86,0,0,0-1,.52,6.58,6.58,0,0,0-.87.71,5.75,5.75,0,0,0,0,8.12,6.58,6.58,0,0,0,.87.71A5.86,5.86,0,0,0,366.11,127.61Z" fill="#38C7D0"/>
          <path strokeWidth={1.2} d="M144.27,217.47a5.73,5.73,0,0,0-5.73,5.73v6.2a21.22,21.22,0,0,0,1.25,42.4h9a9.77,9.77,0,1,1,0,19.53H131.37a5.74,5.74,0,0,0,0,11.47h7.17v6.14a5.74,5.74,0,0,0,11.47,0v-6.2a21.22,21.22,0,0,0-1.26-42.41h-9a9.76,9.76,0,0,1,0-19.52h17.38a5.74,5.74,0,1,0,0-11.47H150V223.2A5.73,5.73,0,0,0,144.27,217.47Z" fill="#38C7D0"/>
          <path strokeWidth={1.2} d="M105.38,349.92a5.74,5.74,0,0,0,5.74,5.74H168a5.74,5.74,0,1,0,0-11.47H111.12A5.73,5.73,0,0,0,105.38,349.92Z" fill="#38C7D0"/>
          <path strokeWidth={1.2} d="M199.27,346.74a5.4,5.4,0,0,0-.71-.87,6.05,6.05,0,0,0-.87-.72,6.75,6.75,0,0,0-1-.53,5.39,5.39,0,0,0-1.08-.31,5.72,5.72,0,0,0-6.85,5.62,4.66,4.66,0,0,0,.12,1.11,6.27,6.27,0,0,0,.31,1.08,7.86,7.86,0,0,0,.53,1,6.69,6.69,0,0,0,.72.86,5.71,5.71,0,0,0,8.11,0,5.32,5.32,0,0,0,.71-.86,6.75,6.75,0,0,0,.53-1,7.42,7.42,0,0,0,.33-1.08,6,6,0,0,0,.11-1.11,6.29,6.29,0,0,0-.11-1.14,7.18,7.18,0,0,0-.33-1.06A5.19,5.19,0,0,0,199.27,346.74Z" fill="#38C7D0"/>
          <path strokeWidth={1.2} d="M417.87,315.65a5.74,5.74,0,0,0-3.75-7.19l-34.73-10.93a5.73,5.73,0,0,0-7.19,7.19l10.92,34.73a5.74,5.74,0,0,0,9.53,2.34l7.84-7.85,6.51,6.51a5.73,5.73,0,0,0,8.11-8.11l-10.56-10.57a5.74,5.74,0,0,0-8.11,0L391.23,327l-4.79-15.21,24.23,7.62A5.75,5.75,0,0,0,417.87,315.65Z" fill="#38C7D0"/>
          <path strokeWidth={1.2} d="M258.52,218.9a5.74,5.74,0,0,0-5.74,5.73v32.8a5.74,5.74,0,0,0,5.74,5.74h60.8a5.73,5.73,0,0,0,5.73-5.74v-32.8a5.73,5.73,0,0,0-5.73-5.73Zm55.06,32.8H264.25V230.37h49.33Z" fill="#38C7D0"/>
          <path strokeWidth={1.2} d="M424.82,257.43v-32.8a5.74,5.74,0,0,0-5.73-5.73H358.28a5.73,5.73,0,0,0-5.73,5.73v32.8a5.73,5.73,0,0,0,5.73,5.74h60.81A5.74,5.74,0,0,0,424.82,257.43Zm-11.47-5.73H364V230.37h49.33Z" fill="#38C7D0"/>
        </svg>
        <span>Sell</span>                    
      </button>

      <button className="wallet__balance-btn disabled">
        <svg width="20" height="21" className="svg-cornerarr wallet__svg3" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.5636 0.938935C18.5299 0.38768 18.0557 -0.0318675 17.5045 0.00184845L8.52124 0.551286C7.96998 0.585002 7.55044 1.05922 7.58415 1.61047C7.61787 2.16172 8.09208 2.58127 8.64334 2.54756L16.6284 2.05917L17.1168 10.0442C17.1505 10.5955 17.6247 11.015 18.176 10.9813C18.7272 10.9476 19.1468 10.4734 19.1131 9.92215L18.5636 0.938935ZM1.74896 20.3865L18.3145 1.6626L16.8165 0.337364L0.251044 19.0613L1.74896 20.3865Z" fill="#38C7D0"/>
        </svg>
        <span>Transfer</span>                    
      </button>

      <button className="wallet__balance-btn disabled">
        <svg width="29" height="26" className="svg-arrtwo wallet__svg1"  viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.29284 25.7071C7.68337 26.0976 8.31653 26.0976 8.70706 25.7071L15.071 19.3431C15.4615 18.9526 15.4615 18.3195 15.071 17.9289C14.6804 17.5384 14.0473 17.5384 13.6568 17.9289L7.99995 23.5858L2.3431 17.9289C1.95257 17.5384 1.31941 17.5384 0.928879 17.9289C0.538355 18.3195 0.538355 18.9526 0.928879 19.3431L7.29284 25.7071ZM6.99995 0V25H8.99995V0H6.99995Z" fill="#38C7D0"/>
          <path d="M21.7072 0.2929C21.3166 -0.0976005 20.6835 -0.0976006 20.2929 0.292899L13.929 6.6569C13.5385 7.0474 13.5385 7.6805 13.929 8.0711C14.3196 8.4616 14.9527 8.4616 15.3432 8.0711L21.0001 2.4142L26.6569 8.0711C27.0474 8.4616 27.6806 8.4616 28.0711 8.0711C28.4616 7.6805 28.4616 7.0474 28.0711 6.6569L21.7072 0.2929ZM22.0001 26L22.0001 0.999999L20.0001 0.999999L20.0001 26L22.0001 26Z" fill="#38C7D0"/>
        </svg>
        <span>Change</span>                    
      </button>
    </div>
  );
}

export default WalletAvatarControls;