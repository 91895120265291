import ChainCircles from "./ChainCircles";

function ChainsDecor() {
  return (
    <>
      <div className="chain1">
        <ChainCircles />
      </div>
  
      <div className="chain2">
        <ChainCircles />
      </div>
  
      <div className="chain3">
        <ChainCircles />
      </div>
  
      <div className="chain4">
        <ChainCircles />
      </div>
  
      <div className="chain5">
        <ChainCircles />
      </div>
  
      <div className="chain6">
        <ChainCircles />
      </div>
  
      <div className="chain7">
        <ChainCircles />
      </div>
  
      <div className="chain8">
        <ChainCircles />
      </div>
  
      <div className="chain9">
        <ChainCircles />
      </div>
    </>
  )
}

export default ChainsDecor;