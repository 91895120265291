import { useEffect } from "react";
import { BASE_URL } from "../../../config";
import { actions as walletActions } from "../../../logic/Wallet";
import { useAppDispatch, useAppSelector } from "../../../redux-store";

function Header() {

  const dispatch = useAppDispatch();

  const { userBalance } = useAppSelector(state => state['wallet']);
  const { getUserBalanceStatus } = useAppSelector(state => state['wallet']);

  const { currentUser } = useAppSelector(state => state['user'])
  const { nftAvatarUrl } = useAppSelector(state => state['nft']);
  const { isPremAvatar } = useAppSelector(state => state['nft']);


  useEffect(() => {
    if (currentUser) dispatch(walletActions.getUserBalance());
  }, [currentUser])
  
  return (
    <header className="main__header">
      {/* <!--соц ссылки--> */}
      <ul className="main__header-soc social-list">
        <li className="social-list__item">
          <a href="https://t.me/tplanets_games" target="_blank" className="social-list__link" rel="noreferrer">
            <svg width="23" height="23" className="svg-telegram" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.2111 22.0812C12.9357 21.7983 11.9656 20.0753 11.0553 18.2522C10.145 16.4292 9.21586 14.6695 8.99048 14.3418C8.73358 13.9683 7.28043 13.0802 5.09531 11.9611C1.1644 9.94804 0.636181 9.51609 0.742541 8.40166C0.839722 7.38339 1.23818 7.17272 6.77169 5.21392C9.30267 4.31798 13.385 2.87325 15.8436 2.00341C18.3022 1.13357 20.5864 0.421875 20.9197 0.421875C21.6154 0.421875 22.2692 1.04771 22.2692 1.71382C22.2692 1.96456 21.4491 4.57958 20.4468 7.52498C19.4445 10.4704 18.294 13.8575 17.8902 15.0519C16.5252 19.0893 15.4815 21.7935 15.1329 22.1955C14.6549 22.7467 13.8101 22.6964 13.2111 22.0812Z"/>
            </svg>
          </a>
        </li>
        <li className="social-list__item disabled">
          <a href="##" target="_blank" className="social-list__link">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="svg-instagram" viewBox="0 0 24 24">
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
            </svg>
          </a>
        </li>
        <li className="social-list__item disabled">
          <a href="##" target="_blank" className="social-list__link">
            <svg width="32" height="23" className="svg-discord" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M27.0424 2.87504C27.0424 2.87504 23.8117 0.296824 19.997 0L19.6517 0.701032C23.1026 1.5628 24.6832 2.79704 26.338 4.31256C23.4873 2.82878 20.6736 1.43752 15.7695 1.43752C10.8655 1.43752 8.04949 2.82878 5.20139 4.31256C6.85153 2.79704 8.73569 1.42705 11.8853 0.701032L11.5424 0C7.5374 0.386648 4.49698 2.87504 4.49698 2.87504C4.49698 2.87504 0.888207 8.21146 0.269531 18.6874C3.90578 22.9666 9.42852 23 9.42852 23L10.5828 21.4301C8.62215 20.7348 6.40862 19.4915 4.49665 17.2503C6.77705 19.0093 10.221 20.8439 15.7695 20.8439C21.3181 20.8439 24.7594 19.0116 27.0424 17.2503C25.1281 19.4915 22.9146 20.7348 20.9563 21.4301L22.1105 23C22.1105 23 27.631 22.9666 31.2695 18.6874C30.6485 8.21146 27.0424 2.87504 27.0424 2.87504ZM11.1899 15.8121C9.82706 15.8121 8.72411 14.5258 8.72411 12.937C8.72411 11.3496 9.82739 10.0623 11.1899 10.0623C12.5527 10.0623 13.656 11.3496 13.656 12.937C13.656 14.5262 12.5527 15.8121 11.1899 15.8121ZM20.3492 15.8121C18.9864 15.8121 17.8831 14.5258 17.8831 12.937C17.8831 11.3496 18.9864 10.0623 20.3492 10.0623C21.7117 10.0623 22.815 11.3496 22.815 12.937C22.815 14.5262 21.7093 15.8121 20.3492 15.8121Z"/>
            </svg>								
          </a>
        </li>
        <li className="social-list__item disabled">
          <a href="##" target="_blank" className="social-list__link">
            <svg width="29" height="23" className="svg-twitter" viewBox="0 0 29 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.2695 2.72285C27.2396 3.18445 26.1318 3.49666 24.9699 3.63609C26.1562 2.91771 27.0666 1.78102 27.4962 0.424362C26.3862 1.08992 25.1563 1.57274 23.8483 1.83299C22.8005 0.704962 21.3074 0 19.6542 0C16.4823 0 13.9097 2.6003 13.9097 5.8064C13.9097 6.26151 13.9606 6.70449 14.0592 7.12972C9.28498 6.88766 5.05187 4.57618 2.21837 1.0622C1.72398 1.91959 1.44037 2.91771 1.44037 3.98164C1.44037 5.99563 2.45528 7.77362 3.99584 8.81461C3.05462 8.78473 2.16824 8.52362 1.39411 8.08757C1.39368 8.11225 1.39368 8.13693 1.39368 8.16118C1.39368 10.9745 3.37464 13.3211 6.00208 13.8541C5.52055 13.9875 5.01203 14.0581 4.48894 14.0581C4.11793 14.0581 3.7585 14.0221 3.40806 13.9546C4.13893 16.2609 6.25998 17.9397 8.77432 17.9869C6.80792 19.5445 4.33128 20.4724 1.63916 20.4724C1.17647 20.4724 0.718076 20.4452 0.269531 20.391C2.81043 22.0391 5.83072 23 9.07463 23C19.6409 23 25.4193 14.1525 25.4193 6.47889C25.4193 6.2273 25.4137 5.97658 25.4026 5.72802C26.5259 4.90961 27.4997 3.88725 28.2695 2.72285Z"/>
            </svg>								
          </a>
        </li>
      </ul>

      {/* <!--блок аватарки игрока и баланс--> */}
      <div className="main__header-player">
        <span className="main__header-ton-text">{getUserBalanceStatus === 'loading' ? '...загрузка' : Number(userBalance).toFixed(2)}</span>
        <img src="img/ton.svg" alt="" className="main__header-ton" />
        <div className="main__header-avatarContainer">
          <img src={nftAvatarUrl} alt="avatar" className="main__header-avatar" />
          {isPremAvatar && <img src="img/prem-border1.avif" alt="premium avatar border" className="main__header-premBorder" />}
        </div>
        <span>{currentUser?.telegram}</span>
      </div>
    </header>
  )
}

export default Header;