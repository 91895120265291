function ChainCircles() {
  return (
    // <div className="chain">
    //   <div className="chain__container">
    //     <div className="circle"></div>
    //     <div className="circle"></div>
    //   </div>
    //   <div className="chain__container">
    //     <div className="circle"></div>
    //     <div className="circle"></div>
    //   </div>
    //   <div className="chain__container">
    //     <div className="circle"></div>
    //     <div className="circle"></div>
    //   </div>
    //   <div className="chain__container">
    //     <div className="circle"></div>
    //     <div className="circle"></div>
    //   </div>
    //   <div className="chain__container">
    //     <div className="circle"></div>
    //     <div className="circle"></div>
    //   </div>
    //   <div className="chain__container">
    //     <div className="circle"></div>
    //     <div className="circle"></div>
    //   </div>
    //   <div className="chain__container">
    //     <div className="circle"></div>
    //     <div className="circle"></div>
    //   </div>
    //   <div className="chain__container">
    //     <div className="circle"></div>
    //     <div className="circle"></div>
    //   </div>
    //   <div className="chain__container">
    //     <div className="circle"></div>
    //     <div className="circle"></div>
    //   </div>
    //   <div className="chain__container">
    //     <div className="circle"></div>
    //     <div className="circle"></div>
    //   </div>
    //   <div className="chain__container">
    //     <div className="circle"></div>
    //     <div className="circle"></div>
    //   </div>
    //   <div className="chain__container">
    //     <div className="circle"></div>
    //     <div className="circle"></div>
    //   </div>
    // </div>

    <div className="energyLine">
      <div></div><div></div><div></div>
    </div>
  )
}

export default ChainCircles;