function MainingLoader() {
  return ( 
    <div className="mainingLoader">
      <ul className="mainingHexagonContainer">
        <li className="mainingHexagon hex_1"></li>
        <li className="mainingHexagon hex_2"></li>
        <li className="mainingHexagon hex_3"></li>
        <li className="mainingHexagon hex_4"></li>
        <li className="mainingHexagon hex_5"></li>
        <li className="mainingHexagon hex_6"></li>
        <li className="mainingHexagon hex_7"></li>
      </ul>
    </div>
   );
}

export default MainingLoader;