type ChestAwardProps = {
  whatTypeShip: string,
  typeShip: string
}

function ChestAward({whatTypeShip, typeShip}: ChestAwardProps) {
  return ( 
    <div className="ships__chest">

      <div className="ships__grid-chest-wrapper">
        <picture className="ships__grid-right-pic">
          <img src="img/chest.png" alt="" />
        </picture>

        <div className="ships__grid-chest-text">
          <h2 className="ships__grid-right-title">Награда за победу</h2>

          <ul className="ships__grid-right-list">
            <li>2х ” Стоимость {whatTypeShip}”</li>
            <li>1х {typeShip}</li>
          </ul>
        </div>
      </div>

    </div>
   );
}

export default ChestAward;