import { useEffect, useState } from "react";
import { GradeColors, Ships } from "../../../const";
import { actions as guildActions } from "../../../logic/Guild";
import { actions as nftActions } from "../../../logic/Nft";
import { actions as personalActions } from "../../../logic/Main";
import { actions as userActions } from "../../../logic/User";
import { useAppDispatch, useAppSelector } from "../../../redux-store";
import AssembleTeam from "../components/AssembleTeam";
import ChainsDecor from "../components/ChainsDecor";
import ChestAward from "../components/ChestAward";
import CopyPromo from "../components/CopyPromo";
import MobileCopyPromo from "../components/CopyPromoMobile";
import ShipMatrix from "../components/ShipsMatrix";
import SimpleLoader from "../components/SimpleLoader";
import TimersModal from "../modals/TimersModal";
import ShipFilter from "../components/ShipFilter";
import StartAdventureButton from "../components/StartAdventureButton";

function GuildScreen() {

  const dispatch = useAppDispatch();

  const { isFirstFiltersLoad } = useAppSelector(state => state['guild']);
  const { selectedFilter } = useAppSelector(state => state['guild']);
  const setSelectedFilter = (filter: keyof typeof Ships | null) => { 
    dispatch(guildActions.setSelectedFilter(filter))
  };

  const { currentUser } = useAppSelector(state => state['user']);
  const userRace = currentUser?.race?.name;

  /* если есть юзер но в нём нет расы, обновляем юзера */
  useEffect(() => {
    if (currentUser && !currentUser.race) {
      dispatch(userActions.getAuthenticatedUser())
    }
  }, [currentUser]);

  const { spaceshipsForShop } = useAppSelector(state => state['nft']);
  const { spaseshipsForMatriсes } = useAppSelector(state => state['nft']);
  const { lastLoadedMatrix } = useAppSelector(state => state['nft']);
  const { getSpaceshipsForMatriсesStatus } = useAppSelector(state => state['nft']);
  const { getSpaceshipMatrixStatus } = useAppSelector(state => state['nft']);
  const { spaceshipsForGame } = useAppSelector(state => state['nft']);
  const { currentShipForMatrix } = useAppSelector(state => state['nft']);
  const { activateSpaseshipForMatrixStatus } = useAppSelector(state => state['nft']);
  const { userSpaceshipsWaitingChilds } = useAppSelector(state => state['nft']);
  const { getUserSpaceshipsWaitingChildsStatus } = useAppSelector(state => state['nft']);

  /* запрос кораблей для покупки */
  useEffect(() => {
    if (spaceshipsForShop.length < 1) {
      dispatch(nftActions.getSpaceshipsForShop())
    }
  }, [spaceshipsForShop]);

  const handleStartGameBtnClick = () => {
    const spaceshipsSliceForGame = spaceshipsForGame.slice(0, 5).map((el: any) => el.type);
    dispatch(nftActions.setCurrentSpaceshipsInGame(spaceshipsSliceForGame));
    dispatch(personalActions.setCurrentScreen('game'));
  }

  /* get all ships with available matrix and spaceships for game */
  useEffect(() => {
    dispatch(nftActions.getSpaceshipsForMatriсes());
    dispatch(nftActions.getUserSpaceshipsUnplayed());

    /* temporarily disabled ⬇️ */

    const intervalId = setInterval(() => {
      dispatch(nftActions.getSpaceshipsForMatriсes());
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };

  }, []);

  /* set first available filter */
  useEffect(() => {
    if (spaseshipsForMatriсes.length > 0 && isFirstFiltersLoad) {
      dispatch(guildActions.setSelectedFilter(spaseshipsForMatriсes[0].spaceship.type));
      dispatch(guildActions.setIsFirstFiltersLoad(false));
    }
  }, [dispatch, isFirstFiltersLoad, spaseshipsForMatriсes])
  

  /* get one matrix for current ship */
  useEffect(() => {
    if (spaseshipsForMatriсes.length > 0 && selectedFilter) {
      const currentFilteredShip = spaseshipsForMatriсes.find((ship) => ship.spaceship.type === selectedFilter);
      
      if (currentFilteredShip) {
        dispatch(nftActions.setCurrentShipForMatrix(currentFilteredShip))
        dispatch(nftActions.getSpaceshipMatrix(currentFilteredShip.id))
        dispatch(nftActions.getUserSpaceshipsWaitingChilds(currentFilteredShip.spaceship.type));
      }
    }
  }, [dispatch, spaseshipsForMatriсes, selectedFilter, activateSpaseshipForMatrixStatus ]);


  /* Refresh matrix when admiral ship activated */
  useEffect(() => {
    if (activateSpaseshipForMatrixStatus === 'success') {
      dispatch(nftActions.getSpaceshipsForMatriсes());
      dispatch(nftActions.resetActivateSpaseshipForMatrixStatus());
    }
  }, [dispatch, activateSpaseshipForMatrixStatus]);
  
  const shipFilters = Object.values(Ships);

  /* open Timers Modal */
  const [isTimersModalOpened, setIsTimersModalOpened] = useState(false);
  const openTimersModal = () => setIsTimersModalOpened(true);
  const closeTimersModal = () => setIsTimersModalOpened(false);


  return (

    <section className="ships section-bg section-bg--gi">

    {/* копирование промо для мобилки */}
    <MobileCopyPromo additionalClass={'ships__mobile-promo'} />

    <div className="ships__top">
      <h1>Ваша гильдия: <span>{ currentUser?.guild }</span></h1>
    </div>

    <div className="ships__top-grid">
      <div className="ships__top-grid-left">
        {/* фильтры */}
        <ul className={`ships__list`}>
          {shipFilters.map((filter: keyof typeof Ships | null) => (
            <ShipFilter
              filter={filter}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
              spaceshipsForShop={spaceshipsForShop}
              userRace={userRace}
              spaseshipsForMatriсes={spaseshipsForMatriсes}
            />
          ))}
        </ul>

        <h2 className="ships__title-content">
          Для старта игры T-HEX необходимы 7 кораблей во флотилии.
        </h2>

        <p className="ships__info">
          Каждый корабль может cтать флагманом только 1 раз до официального релиза игры.
        </p>
      </div>

      {/* в приключение ships__top-grid-right */}
      <AssembleTeam />
    </div>

    <div className="ships__grid">

      {/* контейнер матриц */}
      <div className={`ships__grid-left ${(getSpaceshipsForMatriсesStatus === 'success' && spaseshipsForMatriсes.length < 1) && lastLoadedMatrix.length < 1 ? 'noShips' : ''}`}>

        <picture className="ships__grid-left-pic">
          <img src="img/ship.png" alt="" />
        </picture>

        {/* MARTIX */}
        { currentShipForMatrix && spaseshipsForMatriсes.length > 0 && lastLoadedMatrix.length > 0 && selectedFilter && (
          <>

            {/* кнопка таймера */}
            {userSpaceshipsWaitingChilds.length > 0 &&
            (<button className="ships__gridTimers" onClick={openTimersModal}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.854 13.683L23.852 13.842C23.72 14.696 23.501 15.518 23.2 16.302L21.4 15.397C21.6 14.846 21.753 14.274 21.854 13.683ZM19.306 21.509L17.893 20.066C17.407 20.422 16.887 20.734 16.338 20.999L17.007 22.898C17.828 22.521 18.598 22.054 19.306 21.509ZM20.532 17.2C20.197 17.746 19.813 18.257 19.383 18.728L20.787 20.161C21.37 19.534 21.886 18.845 22.326 18.103L20.532 17.2ZM0 12C0 18.627 5.375 24 12.004 24C13.085 24 14.128 23.844 15.124 23.576L14.459 21.682C13.672 21.882 12.852 22 12.004 22C6.488 22 2.001 17.514 2.001 12C2.001 6.486 6.488 2 12.004 2C14.239 2 16.297 2.744 17.963 3.989L15.913 6.038L22.928 7.392L21.573 0.379L19.389 2.562C17.353 0.964 14.794 0 12.004 0C5.375 0 0 5.373 0 12ZM23.773 9.641H21.697C21.86 10.302 21.958 10.985 21.985 11.688L24 11.849C23.99 11.094 23.915 10.355 23.773 9.641Z" fill="#38C7D0"/>
              </svg>
              <span>48</span>
            </button>)}

            {/* декоративные цепи */}
            <ChainsDecor />

            {/* матрицa */}
            <ShipMatrix svgColor={GradeColors[selectedFilter]} matrix={lastLoadedMatrix} />
          </>
        )}

        {/* сообщение что нужен корабль для игры */}
        { (getSpaceshipsForMatriсesStatus === 'success' && spaseshipsForMatriсes.length < 1) && lastLoadedMatrix.length < 1 && !currentShipForMatrix &&  (
          <div className="ships__center-message center-message">
            <p>Для участия в космических сражениях нужен корабль.</p>
            <button className="button-common" onClick={ () => {dispatch(personalActions.setCurrentScreen('store'))} }>В магазин</button>
          </div>
        )}

        {/* сообщение что нужен корабль для игры */}
        { (getSpaceshipsForMatriсesStatus === 'success' && spaseshipsForMatriсes.length < 1) && currentShipForMatrix &&  (
          <div className="ships__center-message center-message">
            <p>Соединение потеряно, пожалуйста обновите страницу</p>
            <button className="button-common" onClick={() => window.location.reload()}>обновить</button>
          </div>
        )}

        {/* лоадер-крутяшка пока идёт запрос */}
        { (getSpaceshipsForMatriсesStatus === 'loading' || getSpaceshipMatrixStatus === 'loading' || activateSpaseshipForMatrixStatus === 'loading' || getUserSpaceshipsWaitingChildsStatus === 'loading' ) && <SimpleLoader addClass="ships__sloader" /> }
        
        {/* ошибка получения кораблей для матриц */}
        { (getSpaceshipsForMatriсesStatus === 'failed' || (getSpaceshipsForMatriсesStatus === 'success' && getSpaceshipMatrixStatus === 'failed')) && (
          <div className="ships__center-message center-message">
            <p>Что-то пошло не так, пожалуйста обновите страницу.</p>
            <button className="button-common" onClick={() => window.location.reload()}>обновить</button>
          </div>
        ) }

        {/* кнопка старт приключения */}
        <StartAdventureButton handleStartGameBtnClick={handleStartGameBtnClick} spaceshipsForGame={spaceshipsForGame} />

        { /* Копирование промокода */}
        <CopyPromo additionalClass="ships__grid-left-promo" />

        {/* сундук */}
        {lastLoadedMatrix.length > 0 && (
          <>
            {currentShipForMatrix?.spaceship?.type === 'Торговый' && <ChestAward whatTypeShip={'торгового корабля'} typeShip={'Торговый корабль'} />}
            {currentShipForMatrix?.spaceship?.type === 'Истребитель' && <ChestAward whatTypeShip={'истребителя'} typeShip={'Истребитель'} />}
            {currentShipForMatrix?.spaceship?.type === 'Штурмовой' && <ChestAward whatTypeShip={'штурмового корабля'} typeShip={'Штурмовой корабль'} />}
            {currentShipForMatrix?.spaceship?.type === 'Крейсер' && <ChestAward whatTypeShip={'крейсера'} typeShip={'Крейсер'} />}
            {currentShipForMatrix?.spaceship?.type === 'Дредноут' && <ChestAward whatTypeShip={'дредноута'} typeShip={'Дредноут'} />}
            {currentShipForMatrix?.spaceship?.type === 'Разрушитель' && <ChestAward whatTypeShip={'разрушителя'} typeShip={'Разрушитель'} />}
          </>
        )}
        
      </div>

    </div>

    {/* модальное окно таймера */}
    <TimersModal isOpened={isTimersModalOpened} onClose={closeTimersModal} childs={userSpaceshipsWaitingChilds} />

  </section>
  )
}

export default GuildScreen;