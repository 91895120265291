import { useEffect } from "react";
import { actions as walletActions } from "../../../logic/Wallet";
import { actions as mainActions } from "../../../logic/Main";
import { useAppDispatch, useAppSelector } from "../../../redux-store";

function MobileHeader() {

  const dispatch = useAppDispatch();

	const openNavigation = () => { dispatch(mainActions.setIsNavigationOpened(true)) }

  const { userBalance } = useAppSelector(state => state['wallet']);
  const { getUserBalanceStatus } = useAppSelector(state => state['wallet']);

  const { currentUser } = useAppSelector(state => state['user'])
  const { nftAvatarUrl } = useAppSelector(state => state['nft']);
  const { isPremAvatar } = useAppSelector(state => state['nft']);

  useEffect(() => {
    if (currentUser) dispatch(walletActions.getUserBalance());
  }, [currentUser])

  return(
    <header className="main__mobile-header">

      <img className="main__mobile-header-top-bg" src="img/mob-fon-top.png" alt="" />

      <div className="main__mobile-header-top">
        <img className="main__mobile-header-logo" src="img/tplanet.svg" alt="" />
        <div className="main__mobileBalance">
        {getUserBalanceStatus === 'loading' ?
          (<div className="lds-dual-ring-sm"></div>)
          :
          (<span className="main__mobileBalance-text">{Number(userBalance).toFixed(2)}</span>)
        }
        <img src="img/ton.svg" alt="" className="main__mobileTonIcon" />
        </div>

        {/* <!--кнопка открытия меню--> */}
        <button className="main__mobile-header-open" onClick={openNavigation}>
          <svg width="73" height="47" viewBox="0 0 73 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0H64L71.6564 22.5L72.655 26.9592C72.8843 27.9833 73 29.0295 73 30.079C73 32.6665 72.479 35.2275 71.4681 37.6094L71.3023 38L69 42C67.1388 45.102 63.7866 47 60.169 47H0V0Z" fill="#CFE6E8"/>
            <path d="M21.5576 15.0933H45.0562" stroke="#414042" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.5576 23.2845H37.6262" stroke="#414042" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21.5576 31.4758H30.2229" stroke="#414042" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>					
        </button>

        <div className="main__mobile-header-avatar">
          <img className="main__mobile-header-avatarPanel" src="img/ava-panel.png" alt="background panel for avatar" />
          <img className={`main__mobile-header-avatarImg ${isPremAvatar ? 'main__mobile-header-avatarImg--isPrem' : ''}`} src={nftAvatarUrl} alt="player avatar" />
          {isPremAvatar && <img src="img/prem-border1.avif" className="main__mobile-header-avatarBorder" alt="prem avatar border" />}
        </div>

        <a href="https://t.me/tplanets_games" target="_blank" className="main__mobile-header-tg" rel="noreferrer">
          <svg width="73" height="47" viewBox="0 0 73 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M73 0H9L1.34356 22.5L0.345039 26.9592C0.115715 27.9833 0 29.0295 0 30.079C0 32.6665 0.520981 35.2275 1.53188 37.6094L1.69767 38L4 42C5.86119 45.102 9.21345 47 12.831 47H73V0Z" fill="#CFE6E8"/>
            <path d="M25.0999 22.6C31.8999 20 51.4999 12 51.4999 12L47.5999 34.6C47.3999 35.7 46.0999 36.1 45.2999 35.4L39.1999 30.3L34.8999 34.3L35.5999 27.6L48.5999 15.3L32.5999 25.3L33.5999 31L30.2999 25.7L25.2999 24.1C24.4999 23.8 24.3999 22.8 25.0999 22.6Z" stroke="#414042" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>					
        </a>

        <p className="main__mobile-header-nickname">{currentUser?.telegram}</p>
      </div>

      {/* <!--сообщение--> */}
      {/* <div className="main__header-message">
        <span>Поздравляем, вы получили в подарок NFT, перейдите для получения</span>
        <button className="main__header-message-close button-close button-close--white"></button>
      </div> */}
    </header>
  )
}

export default MobileHeader;