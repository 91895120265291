import { useEffect } from "react";
import { actions } from "../../../logic/Nft";
import { actions as userActions } from "../../../logic/User";
import { useAppDispatch, useAppSelector } from "../../../redux-store";
import AssembleTeam from "../components/AssembleTeam";
import CopyPromo from "../components/CopyPromo";
import MobileCopyPromo from "../components/CopyPromoMobile";
import MainingLoader from "../components/MainingLoader";

function HomeScreen() {

  const dispatch = useAppDispatch();

  const { currentUser } = useAppSelector(state => state['user']);

  useEffect(() => {
    if (currentUser) {
      dispatch(actions.getRewardsSumm());
      dispatch(actions.getAdventureDone());
      dispatch(actions.getUserRating());
      dispatch(actions.getSpaceshipsForMatriсes());
      dispatch(userActions.getMiningInfo());
      dispatch(userActions.getTokenBalance());
    }
  }, [currentUser])
  
  useEffect(() => {
    if (currentUser?.guild) {
      dispatch(actions.getGuildMembersNumber(currentUser.guild))
    }
  }, [currentUser?.guild]);
  
  const { rewardsSumm } = useAppSelector(state => state['nft']);
  const { getRewardsSummStatus } = useAppSelector(state => state['nft']);
  const { adventuresDone } = useAppSelector(state => state['nft']);
  const { getAdventureDoneStatus } = useAppSelector(state => state['nft']);
  const { userRating } = useAppSelector(state => state['nft']);
  const { getUserRatingStatus } = useAppSelector(state => state['nft']);
  const { guildMembersNumber } = useAppSelector(state => state['nft']);
  const { getGuildMembersNumberStatus } = useAppSelector(state => state['nft']);
  const { spaseshipsForMatriсes } = useAppSelector(state => state['nft']);

  const { miningCurrentRate } = useAppSelector(state => state['user']);
  const { tokenBalance } = useAppSelector(state => state['user']);

  const stars = [...Array(10)].map((el, index) => index <= userRating - 1 ? {index, class: 'active'} : {index, class: ''});

  return (
    <section className="supp section-bg">

      <picture className="supp__decorPlanet">
        <img src="img/green-planet.png" alt="" />
      </picture>

      {/* в приключения для мобилки */}
      {/* <AssembleTeamMobile /> */}

      <div className="supp__top">
        {/* <!--список инфы--> */}
        <ul className="supp__list">
          {/* <li className="supp__item">
            <h3>Кораблей</h3>
            <span>19</span>
          </li> */}

          <li className="supp__item">
            <h3>Приключений пройдено</h3>
            { getAdventureDoneStatus === 'loading' ? (<div className="lds-dual-ring-sm"></div>) : (<span>{adventuresDone}</span>) }
          </li>

          <li className="supp__item">
            {/* заработано в приключениях */}
            <h3>Заработано в приключениях</h3>
            <div className="supp__item-ton">
              { getRewardsSummStatus === 'loading' ? (<div className="lds-dual-ring-sm"></div>) : (<span>{rewardsSumm}</span>) }
              <img src="img/ton.svg" alt="TON" />
            </div>
          </li>
        </ul>

        {/* в приключения */}
        <AssembleTeam />
      </div>

      <div className="supp__main">

        {/* инфа */}
        <div className="supp__main-info">

          {/* гильдия */}
          <div className="supp__main-gi">
            <h3>Ваша гильдия: <span>{ currentUser?.guild }</span></h3>
            <div className="supp__main-gi-p">
            { getGuildMembersNumberStatus === 'loading' ? (<div className="lds-dual-ring-sm"></div>) : (<span>{guildMembersNumber}</span>) }
              участников
            </div>
          </div>  

          {/* ранг игрока */}
          <div className="supp__main-rank">
            <h3>Ваш ранг</h3>
            { getUserRatingStatus === 'loading' ? (<div className="lds-dual-ring-sm"></div>) : (
              <ul className="supp__main-rank-list">
                {stars.map(el => <li key={el.index} className={`${el.class}`}></li>)}
              </ul>
            ) }
          </div>

        </div>

        {/* мобильная кнопка копировать код */}
        <MobileCopyPromo additionalClass={'supp__mobile-promo'} />

        {/* Добыча REDTON */}

        <h2 className="supp__jettonTitle">Добыча <i>RED<span>TON</span></i></h2>

        <ul className="supp__jettonList supp__list">
          <li className="supp__item">
            <h3>Всего добыто</h3>
            <div className="supp__item-ton">
              <span>{ Number(tokenBalance).toFixed(2) }</span>
              {spaseshipsForMatriсes.some((ship) => ship.spaceship.type === 'Торговый') && <MainingLoader />}
            </div>
            {/*{ getAdventureDoneStatus === 'loading' ? (<div className="lds-dual-ring-sm"></div>) : (<span>{adventuresDone}</span>) }*/}
          </li>

          <li className="supp__item">
            <h3>Скорость добычи</h3>
            <div className="supp__item-ton">
              <span>{ Number(miningCurrentRate).toFixed(2) }</span>
              {spaseshipsForMatriсes.some((ship) => ship.spaceship.type === 'Торговый') && <MainingLoader />}
            </div>
          </li>
        </ul>

        {/* поддержка */}
        <div className="supp__main-supp">
          <h2>Техническая поддержка в Telegram:</h2>
          <a href="https://t.me/tplanets_support" target="_blank" rel="noreferrer">@tplanets_support</a>
        </div>

        {/* копировать промокод */}
        <CopyPromo additionalClass={'supp__promo'} />

      </div>

    </section>
  )
}

export default HomeScreen;