import { GqlBody } from "../logic/types/request/RequestTypes";
import { GetDepositAdressResponse } from "../logic/types/response/GetDepositAdressResponse";
import { GetUserTransactions, UserTransaction } from "../logic/types/response/GetUserTransactions";
import { GetUserBalanceResponse } from "../logic/types/response/GetUserBalanceResponse";

import { ApiKeystone } from "./api_keystone";
import { Gql } from "./api_queries";
import { CreateWithdrawRequest, WithdrawRequest, WithdrawRequestData } from "../logic/types/response/CreateWithdrawRequest";

export class WalletRepository {
  static keystone = ApiKeystone;

  static async getUserBalance(): Promise<string> {
    const query = Gql.getUserBalance()
    const body: GqlBody = { operationName: "Balance", query: query }
    const response: GetUserBalanceResponse = await this.keystone.queryGraphql(body)
    return response.data.balance ?? null;
  }

  static async getUserTransactions(): Promise<UserTransaction[]> {
    const query = Gql.getUserTransactions()
    const body: GqlBody = { operationName: "Transactions", query: query, variables: {"orderBy": [{"createdAt": "desc"}]} }
    const response: GetUserTransactions = await this.keystone.queryGraphql(body)
    return response.data.transactions ?? [];
  }

  static async createWithdrawRequest(dataReq: {data: WithdrawRequestData} ): Promise<WithdrawRequest> {
    const query = Gql.сreateWithdrawRequest()
    const body: GqlBody = { operationName: "CreateWithdrawRequest", query: query, variables: dataReq }
    const response: CreateWithdrawRequest = await this.keystone.queryGraphql(body)
    return response.data.createWithdrawRequest ?? null;
  }

  static async getDepositAddress(): Promise<string> {
    const query = Gql.getDepositAddress()
    const body: GqlBody = { operationName: "GetDepositAddress", query: query }
    const response: GetDepositAdressResponse = await this.keystone.queryGraphql(body)
    return response.data.depositAddresses[0].address ?? '';
  }
}
